import React, { useEffect, useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import { getUserDataFromLocal } from "../../../shared/utility/helper";
import { WebService } from "../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../services/axios/endPoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Table } from "../../../Common/Table/Table2";
import { bidColumns } from "../../../Common/Data";
import { LandingPageCreators } from './store/index'
import toast, { Toaster } from "react-hot-toast";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import { styled } from "styled-components";
import ModalComponent from "../../../Common/Modal/Modal";
import { sagaCatchBlockHandling } from "../../../store/utility/sagaErrorHandling";
import { Rating } from 'react-simple-star-rating'
import bidIcon from "../../../assets/img/logo/bidding.png";
import copy from 'copy-to-clipboard';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { CoinsCreators } from "../Dashboard/Coins/store";

const Favjob = (props) => {
  const { data } = props;
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bidData, setBidData] = useState([]);
  const [jobTitle, setJobTitle] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [bidInput, setBidInput] = useState({
    job_id: "",
    amount: "",
    amount_sign: "",
    duration: "",
    duration_key: "",
    message: "",
  });
  const { favJob } = useSelector((state) => state.landingPageData);
  const { coinsList, coinsloading } = useSelector((state) => state.coinsData);
  const dispatch = useDispatch()
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBidInput({ ...bidInput, [name]: value });
  };

  const placeBidSuccess = async() => {
    try {
      const response = await WebService.post(
        apiEndPoints.jobbidding,
        JSON.stringify(bidInput),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        dispatch(CoinsCreators.getCoinsList());
        setBidInput([]);
        setIsModalVisible(false);
      }
      else if (response.response.status === 422) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        setErrorList(response.response.data.data);
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        setBidInput([]);
        setIsModalVisible(false);
      } else {
        toast.error("something went wrong.", {
          position: "top-right",
        });
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error("Something Went Wrong!", {
        position: "top-right",
      });
    }
  }

  const placeBid = async (e) => {
    e.preventDefault();
    confirmAlert({
      title: 'Confirm to submit',
      message: <ul>
                <strong>Are you sure you want to make a bid?</strong>
                <li><strong>Free Bids: </strong> Enjoy 3 free bids every day to participate in our platform.</li>
                <li><strong>Remaining Free Bids: </strong> {props?.today_bids >= 3 ? "you've used them all free bids." :  `You have ${3 - props?.today_bids} free bids remaining for today.`}</li>
                <li><strong>Additional Bids: </strong>After using your free bids, there's a small charge of 5 coins for each extra bid.</li>
                <li><strong>Your Coin Balance:</strong> You currently have {props?.coins} coins in your account.</li>
          </ul>,
      buttons: [
        {
          label: 'Yes',
          onClick: () => placeBidSuccess()
        },
        {
          label: 'No',
          onClick: () => alert('Please try again.')
        }
      ]
    });

    
  };
  const handleSetShowBid = (id) => {
    if (!userData) {
      navigate("/login");
    } else {
      handleShowBid(id);
    }

  }

  const handleShowBid = async (ele) => {
    const config = {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
      params: {
        job_id: ele,
      },
    };
    setLoading(true);
    setIsModal(true);
    try {
      const response = await WebService.get(apiEndPoints.jobBid, config);
      const { data } = response;
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        setJobTitle(data.data.title)
        setBidData(data.data.job_bid);
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        setIsModalVisible(false);
      } else {
        toast.error('something went wrong!', {
          position: "top-center",
        });
      }
    } catch (err) {
      toast.error(err, {
        position: "top-right",
      });
    }
  };

  const checkerFunction = (id, title) => {
    if (!userData) {
      navigate("/login");
    } else {
      setJobTitle(title)
      setIsModalVisible(true);
      setBidInput({ ...bidInput, job_id: id });
    }
  };


  const handleSetremoveToFav = (item) => {
    if (!userData) {
      navigate("/login");
    } else {
      removeToFav(item);
    }

  }

  const removeToFav = async (ele) => {
    try {
      const response = await WebService.post(apiEndPoints.favJobDelete, JSON.stringify({
        job_id: ele
      }),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          }
        }
      )
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        dispatch(LandingPageCreators.getFavJob());
        dispatch(LandingPageCreators.removeFavJob({ ele }))
        setBidData(response.data.data.job_bid);

      } else if (response.response.status === 404) {
        dispatch(LandingPageCreators.getFavJob());
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
      }

    } catch (error) {
      // console.log('error', error)
      dispatch(LandingPageCreators.getFavJob());
    }
  }

  useEffect(() => {
    dispatch(LandingPageCreators.getFavJob());

  }, [])

  const approveBidSuccess = async(job_id, bid_id) => {
    try {
      const response = await WebService.post(
        apiEndPoints.approvedBid,
        JSON.stringify({ job_id: job_id, bid_id: bid_id }),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          }
        }
      )
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        setIsModalVisible(false);
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        
        setIsModalVisible(false);
      } else if (response.response.status === 429) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        
        setIsModalVisible(false);
      } else {
        toast.error("something went wrong.", {
          position: "top-right",
        });
      }
    }
    catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }

  }

  const approveBid = async (job_id, bid_id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: <div>
                <strong>Are you sure you want to accept a bid?</strong>
          </div>,
      buttons: [
        {
          label: 'Yes',
          onClick: () => approveBidSuccess(job_id, bid_id)
        },
        {
          label: 'No',
          onClick: () => alert('Please try again.')
        }
      ]
    });
    
  }

  const getValueStatus = (value) => {
    if (value === 'Processing') {
      return (
        <>
          <span className="badge bg-primary-light">
            <i className="far fa-circle"></i> {value}
          </span>
        </>
      )
    } else if (value === 'Active') {
      return (
        <>
        <span className="badge bg-success-light">
          <i className="fas fa-check"></i> {value}
        </span >
        </>
      )
    } else if (value === 'Approved') {
      return (
        <>
          <span className="badge bg-info-light">
            <i className="far fa-circle"></i> {value}
          </span>
        </>
      )
    } else if (value === 'Cancel') {
      return (
        <>
          <span className="badge bg-warning-light">
            <i className="fas fa-times"></i> {value}
          </span>
        </>
      )
    } else if (value === 'Completed') {
      return (
        <>
          <span className="badge bg-danger-light">
            <i className="fas fa-ban"></i> {value}
          </span>
        </>
      )
    }
    return value;
  }

  const handleCopyJob = async(job_code) => {
    await copy(`${rootConfig?.app_url}/jobs?code=${job_code}`);
    
        toast.success('The job copied successfully', {
          position: "top-center",
        });
      // if ("clipboard" in navigator) {
      //   await navigator.clipboard.writeText(`${rootConfig?.app_url}/jobs?code=${job_code}`);
      //   toast.success('The job url copied successfully', {
      //     position: "top-center",
      //   });
      // } else {
      //   document.execCommand("copy", true, `${rootConfig?.app_url}/jobs?code=${job_code}`);
      //   toast.success('The job url copied successfully', {
      //     position: "top-center",
      //   });
      // }
  }

  return (
    <>
      <Toaster />
      <ModalComponent
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        title={jobTitle}
        isForm={true}
        setBidInput={setBidInput}
        bidInput={bidInput}
        handleChange={handleChange}
        handlerFunction={placeBid}
        error={isEmpty}
        setErrorList={setErrorList}
        errorList={errorList}
      />
      {isModal && (
        <Modal
          show={true}
          size="lg"
          onHide={() => setIsModal(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}>{jobTitle}</span> View Bids</Modal.Title>
          </Modal.Header>
          <Table columns={bidColumns} data={bidData} userData={userData} approveBid={approveBid}/>
        </Modal>
      )}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-8 col-xl-12">
              {favJob.length < 1 ? <>
                <div key="2" className="card">
                  <div className="card-body">
                    <div className="mentor-widget">
                      <div className="user-info-left">
                        <div className="user-info-cont">
                          <h4 className="usr-name capitalize">
                            <Link to="#">
                              No Favourite Job Found!
                            </Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </> : favJob?.map((item, index) => {
                // console.log('term', item)
                return (
                  <>
                  {
                    item?.job?.publish == 1 && item?.job?.approved_by_admin == 1 ? 
                    <div className="card recent-job" key={index}>
                    <div className="card-body">
                      <div className="mentor-widget">
                        <div className="user-info-left">
                          <div className="avatar avatar-xl mentor-img" >
                              <img className="avatar-img rounded-circle" style={{height:"100px"}} alt={item?.job?.job_user?.name} src={rootConfig.base_url + item?.job?.job_user?.profile} />                                                
                          </div>
                          <div className="user-info-cont">
                            <h4 className="usr-name capitalize">
                              <Link to={`/jobs/${item?.job?.job_code}`}>
                                {item?.job?.title} {getValueStatus(item?.job?.status)}
                              </Link>
                            </h4>
                            <Link to={`${userData ? `/business/${item?.job?.job_user?.user_name}` : "/login"}`}><span className="capitalize" style={{ color: "#22A699" }}>{item?.job?.job_user?.name} </span></Link> 
                            {/* {item?.job?.job_user?.type !== 'Customer' ? '('+item?.job?.job_user?.user_role+')' : '(Customer)'} */}
                            <span className="badge badge-info">{item?.job_user?.type !== 'Customer' ? 'Service Provider' : 'Customer'}</span>
                            {/* <div className="rating">
                              <Rating readonly initialValue={3} size={18} />
                              <span className="d-inline-block average-rating">
                                (17)
                              </span>
                            </div> */}
                            <p className="mentor-type ">
                              {item?.job?.description?.substring(0, 130)}... <Link to={`/jobs/${item?.job?.job_code}`}>Read More</Link>
                            </p>
                            <div className="avatar-group">
                              {
                                item?.job?.job_image?.map((imgitem, index) => {
                                  return (
                                    <div key={index} className="avatar">
                                      <img className="avatar-img rounded-circle border border-white" alt={imgitem?.image} src={rootConfig.base_url + imgitem?.image} />
                                    </div>
                                  )
                                })
                              }
                            </div>
                            <ul className="tags">
                              <li className="capitalize" style={{ marginLeft: "10px" }}><Link to="#" className="tag"><img src={rootConfig.base_url + item?.job?.job_sub_category?.image} height="20px" width="20px" /> {item?.job?.job_sub_category?.name}</Link></li>
                              <li className="capitalize"><Link to="#" className="tag" ><img src={rootConfig.base_url + item?.job?.job_category?.image} height="20px" width="20px" /> {item?.job?.job_category?.name}</Link></li>
                            </ul>

                          </div>
                        </div>
                        <div className="user-info-right">
                          <div className="user-infos">
                            <ul>
                            <li>
                              <i className="far fa-clock"></i>
                              <span style={{color:'rgb(34, 166, 153)'}}>Published</span> {moment(item?.job?.created_at).fromNow()}
                            </li>
                              <li>
                                <i className="fa fa-clock"></i>
                                <span style={{color:'red'}}>Valid till</span>  {moment(item?.job?.end_date).format(" Do MMMM, YYYY")}
                              </li>
                              <li className="capitalize">
                              <i
                                    className="fas fa-map-marker-alt"
                                    aria-hidden="true"
                                  ></i>
                                {item?.job?.job_country?.emoji} {item?.job?.job_city?.name}, {item?.job?.job_state?.name}, {item?.job?.job_country?.iso2} 
                              </li>
                              {/* <li>
                                <i className="far fa-money-bill-alt" aria-hidden="true"></i>
                                <span>{item?.job?.job_bid_count}</span>
                              </li> */}
                            </ul>
                          </div>
                          <div className="mentor-booking">
                            <IconSpan onClick={() => checkerFunction(item?.job_id, item?.job?.title)}>
                            <span className="badge bg-success">
                                <i className="fa fa-stamp"></i> Bid Now ({item?.job?.job_bid_count})
                              </span>
                            </IconSpan>
                            <IconSpan
                              className="iconSpan"
                              onClick={() => handleSetShowBid(item?.job_id)}
                            >
                              <span className="badge bg-warning">
                                <i className="fa fa-eye"></i> View Bid
                              </span>
                            </IconSpan>
                            <IconSpan onClick={() => handleSetremoveToFav(item?.job_id)}>
                                <span className="badge bg-secondary">
                                  <i className="fa fa-trash"></i>
                                </span>
                            </IconSpan>
                            <IconSpan onClick={() => handleCopyJob(item?.job?.job_code)}>
                                <span className="badge bg-info" title="Copy job url" >
                                  <i className="fa fa-copy"></i> Copy
                                </span>
                              </IconSpan>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    : 'No Favourite Job Found!'
                  }
                  </>
                  
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Favjob;

export const IconSpan = styled.span`
padding: 2px;

// margin: 0px 5px 0px 5px;
// background: #eaeaea;
cursor: pointer;
border: 1px solid white;
// border-radius: 5px;
// height: 30px;
// width: 50px;
// display: inline-block;
text-align: center;
`;
