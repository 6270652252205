import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { CoinsCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { ProfileCreators } from "../../Dashboard/Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import Loader from "../../../../Common/Loader/Loader";
import { SideBar } from "../SideBar";
import moment from "moment";
import './style.css'
import AppHelmet from "../../Helmet/Helmet";
import { ArrowRight, CCircle, ArrowDownLeft, ArrowUpRight } from 'react-bootstrap-icons';

export const Coins = () => {
    const userData = getUserDataFromLocal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { profileData } = useSelector((state) => state.profileData);
    const { coinsList, coinsloading } = useSelector((state) => state.coinsData);

    useEffect(() => {
        dispatch(CoinsCreators.getCoinsList());
    }, []);
    // console.log('CoinsList', CoinsList)

    return (
        <>
            <Toaster />
            <AppHelmet title="Earn Coins and Use Them for Bidding and Services on Serviceora" description="Earn coins through Serviceora's rewards system and use them for bidding on services and accessing premium features. Start collecting coins and enhance your experience today!" keywords="earn coins Serviceora, use coins for bidding, Serviceora rewards, coins for services, bidding with coins, earn and spend coins, Serviceora premium services" />
            {/* <ModalComponent /> */}
            {coinsloading ? (
                <Loader containerHeight={70} width="4rem" />
            ) : (
                <div className="main-wrapper">
                    <div className="breadcrumb-bar">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-12">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                <Link to="/user/coins">Coins</Link>
                                            </li>
                                        </ol>
                                    </nav>
                                    <h2 className="breadcrumb-title">Coins</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <SideBar profileData={profileData} coins={coinsList?.coin_count} />
                                <div className="col-md-7 col-lg-8 col-xl-9">
                                   <section className="section-50">
                                        <div className="container">
                                            <h3 className="m-b-50 heading-line">Coins <CCircle style={{backgroundColor:'#ff9c27',borderRadius:'50%', color:'white', fontSize:'18px'}}/> <span className="badge badge-pill" style={{color: 'white',position: 'absolute', background: '#009DA6', borderRadius: '50%'}}></span> {coinsList?.coin_count}</h3>

                                            <div className="coin-ui_dd-content">
                                                {coinsloading && <Loader width="1.4rem" />}
                                                {coinsList?.data?.length === 0 ? 'No coins found!' : coinsList?.data?.map((coin, index) => {
                                                    return (
                                                        <div className={`coin-list text-muted'}`} key={index}>
                                                        <div className="coin-list_content">
                                                            <div className="coin-list_img">
                                                                {
                                                                    coin?.type === 'debit' ? 
                                                                        <ArrowDownLeft style={{color:'red', fontSize:'18px', fontWeight:'700'}} /> : <ArrowUpRight style={{color:'green', fontSize:'18px'}} />
                                                                    
                                                                }
                                                                
                                                                
                                                            </div>
                                                            <div className="notification-list_detail">
                                                                
                                                                <p className="text-primary" style={{fontSize:'15px'}}> &nbsp;&nbsp;{coin?.coin} </p>
                                                                <p className="text-secondary capitalize" style={{color:'#000000'}}>{coin?.detail}</p>
                                                            </div>
                                                        </div>
                                                        <div className="notification-list_feature-img">
                                                            <p className="text-muted"><small>{moment(coin?.created_at).fromNow()}</small></p>
                                                        </div>
                                                    </div>
                                                    )
                                                    
                                                })
                                                }
                                            </div>

                                            {/* <div className="text-center">
                                                <a href="#!" className="dark-link">Load more activity</a>
                                            </div> */}

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
} 