import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../Footer";
import { Creators } from "../../../auth/store";
import { Toaster, toast } from "react-hot-toast";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { LandingPageCreators } from "../store";
import { ProfileCreators } from "../../Dashboard/store";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { WebService } from "../../../../services/axios/webServices";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { CategoriesCreators } from "../Categories/store";
import { NationCreators } from "../Nation/store";
import { ErrorP } from "../../../../Common/Modal/Modal";
import './contactStyle.css'
import Header from "../Header/Header";
import Loader from "../../../../Common/Loader/Loader";
import { SocialCreators } from "../Social/store";
import AppHelmet from "../../Helmet/Helmet";
import Button from 'react-bootstrap-button-loader';


const Contact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorList, seterrorList] = useState([]);
  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);


  const { socialList, socialloading } = useSelector((state) => state.socialData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    handelContactSubmit();
  };
  const handelContactSubmit = async () => {
    try {
      setLoading(true)
      const res = await WebService.post(
        apiEndPoints.contactUs,
        JSON.stringify(input),
        // {
        //   headers: {
        //     'Content-Type': 'application/json',    
        //     'Authorization': 'my-auth-token',    
        //     'Access-Control-Allow-Origin': '*',    
        //     'Access-Control-Allow-Credentials': 'true',    
        //     'Access- Control - Allow - Headers' : 'Origin, Content - Type, Accept'    
        //  },
        // }
      );
      if (res.status === 200) {
        toast.success(res.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        setLoading(false)
        seterrorList([])
        setInput({
          full_name:'',
          email:'',
          subject:'',
          message:''
        })
      } else if (res.response.status === 422) {
        toast.error(res.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setLoading(false)
        seterrorList(res.response.data.data);
      } else if (res.response.status === 404) {
        toast.error(res.response.data.msg, {
          ToastWarning,
          position: "top-right",
        });
        setLoading(false)
      } else {
        toast.error("something went wrong.", {
          ToastWarning,
          position: "top-right",
        });
        setLoading(false)
      }
    } catch (err) {
      toast?.error(err, { position: "top-right" });
    }
  };

  

  useEffect(() => {
    
  }, [])
  const test = () => {
    return "hello"
  }

  return (
    <>
      <Toaster />
      <Header/>
      <AppHelmet title="Contact Us - Get in Touch with the Serviceora Team" description="Reach out to the Serviceora team for any inquiries, support, or feedback. Use our contact form, email, or phone to connect with us. We're here to help and answer your questions." keywords="contact Serviceora, get in touch, Serviceora support, contact form, customer service, Serviceora inquiries, reach out, Serviceora team, feedback and support" />
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-3 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Contact us
                  </li>
                </ol>
              </nav>
            </div>

          </div>
        </div>
      </div>

      <div className="main-wrapper">

        <section className="instructor-section bg-grey ">
          <div className="container">
            <div className="section-heading d-flex align-items-center">
              <div className="heading-content">
                <h2>
                  <span className="text-weight">Get in Touch with</span> Us{" "}
                  <span className="header-right"></span>
                </h2>
                <p>Reach out to our dedicated team for any inquiries, support, or further information about our exceptional service/product.</p>
              </div>
            </div>
            <div className="row no-gutters bg-white">
              <div className="col-lg-7 col-md-7 order-md-last d-flex align-items-stretch">
                <div className="contact-wrap w-100 p-md-5 p-4">
                  <h3 className="mb-4">Get in touch</h3>
                  <form id="contactForm" name="contactForm" className="contactForm" noValidate="noValidate" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label" htmlFor="name">Full Name</label>
                          <input type="text" className="form-control" name="full_name" id="full_name" placeholder="Full name" onChange={handleChange}/>
                          <ErrorP>{errorList.full_name}</ErrorP>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="label" htmlFor="email">Email Address</label>
                          <input type="email" className="form-control" name="email" id="email" placeholder="Email" onChange={handleChange}/>
                          <ErrorP>{errorList.email}</ErrorP>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="label" htmlFor="subject">Subject</label>
                          <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={handleChange}/>
                          <ErrorP>{errorList.subject}</ErrorP>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="label" htmlFor="#">Message</label>
                          <textarea name="message" className="form-control" id="message" cols="30" rows="4" placeholder="Message" onChange={handleChange}></textarea>
                          <ErrorP>{errorList.message}</ErrorP>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          {/* <button className="btn btn-primary login-btn " type="submit">
                              {loading ? <Loader /> : "Send Query"}</button> */}
                              <Button className="btn btn-primary login-btn" type="submit" loading={loading ? true : false}>Send Query!</Button>
                          <div className="submitting"></div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 d-flex align-items-stretch">
                <div className="info-wrap bg-primary w-100 p-md-5 p-4">
                  <h3>Let's get in touch</h3>
                  <p className="mb-4">We're open for any suggestion or just to have a message</p>
                  {/* <div className="dbox w-100 d-flex align-items-start">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-map-marker"></span>
                    </div>
                    <div className="text pl-3">
                      <p><span>Address:</span> 198 West 21th Street, Suite 721 New York NY 10016</p>
                    </div>
                  </div> */}
                  <div className="dbox w-100 d-flex align-items-center">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-phone"></span>
                    </div>
                    <div className="text pl-3">
                      <p><span>Phone: </span> 
                        
                        <Link to={`${`tel:${socialList?.contact}`}`}>{socialList?.contact}</Link>
                      </p>
                    </div>
                  </div>
                  <div className="dbox w-100 d-flex align-items-center">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-paper-plane"></span>
                    </div>
                    <div className="text pl-3">
                      <p><span>Email:</span> <Link to={`${`mail:${socialList?.email}`}`}>{socialList?.email}</Link></p>
                    </div>
                  </div>
                  {/* <div className="dbox w-100 d-flex align-items-center">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-globe"></span>
                    </div>
                    <div className="text pl-3">
                      <p><span>Website</span> <Link to="#">yoursite.com</Link></p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}



export default Contact