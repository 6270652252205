import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, Link, useNavigate } from "react-router-dom";
import { Creators } from "../view/auth/store";
import ModalComponent from "../Common/Modal/Modal";
import { matchPath, useLocation } from "react-router-dom";
import { getUserDataFromLocal } from "../shared/utility/helper";
import { rootConfig, apiEndPoints } from "../services/axios/endPoints";
import { WebService } from "../services/axios/webServices";
import { ProfileCreators } from "../view/Pages/Dashboard/store";
import logoservicesvg from "../assets/img/logo/serviceora.png";
import blankImg from "../assets/img/logo/blank.png";
import { UserNotificationCreators } from "../view/Pages/Dashboard/UserNotification/store";
import moment from "moment/moment";
import Loader from "../Common/Loader/Loader";
import { sagaCatchBlockHandling } from "../store/utility/sagaErrorHandling";
import { HeadNotification } from "../view/Pages/Landing/HeadNotification/HeadNotification";
import { ResponsiveNotification } from "../view/Pages/Landing/HeadNotification/ResponsiveNotification";
import { ArrowRight, CCircle } from 'react-bootstrap-icons';

const routes = [{ path: "/" }];

export const InnerContent = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [collapseMenu, setCollapsedMenu] = useState(false);
  const [isDown, setIsDown] = useState(false)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(Creators.onLogout());
    navigate("/");
  };

  const { loginmsg, userData } = useSelector((state) => state.loginData);
  const { loading, profileData } = useSelector((state) => state.profileData);
  const { usernotificationList, usernotificationloading } = useSelector((state) => state.userNotificationData);
   

  
  const toggleMenu = () => {
    // console.log(collapseMenu)
    // this.setState({
    //   showCollapsedMenu: !this.state.showCollapsedMenu
    // })
    document.body.classList.toggle('menu-opened');
    setCollapsedMenu(collapseMenu)
}
const handleClearAll = async() => {
  try {
    const response = await WebService.post(apiEndPoints.updateNotificationList,
      JSON.stringify(),
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData.token}`
        }
      }
    )
    if (response.status === 200) {
      dispatch(UserNotificationCreators.getUserNotificationList());

    } else if (response.response.status === 404) {
    }
  }
  catch (e) {
    const { error } = sagaCatchBlockHandling(e);
    console.log('error500', error.response.status)

  }
}

useEffect(() => {
})

useEffect(() => {
  dispatch(ProfileCreators.getProfile());
  dispatch(UserNotificationCreators.getUserNotificationList());
}, []);

const openNotificationPopup = () => {
  if(isDown == false)
  {
    setIsDown(true)
  }
  else{
    setIsDown(false)
  }
}

  return (
    <>
      <ModalComponent
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleLogout={handleLogout}
        title="Logout Confirmation"
      />
      {pathname !== "/" && (
        <header className="header">
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="container-fluid">
              <div className="navbar-header">
                <Link id="mobile_btn" to="#"   onClick={toggleMenu}>
                  <span className="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </Link>
                <Link to="/" className="navbar-brand logo">
                  <img src={logoservicesvg} className="img-fluid" alt="Serviceora" style={{maxHeight:"50px"}}/>
                </Link>
                <Link id="mobile_btn" onClick={handleShow} style={{position:'relative'}}>
                  <span className="user-img"><i className="fa fa-bell" style={{fontSize:'16px', marginTop:'20px', color:'#03b2cb'}}></i> <span className="badge badge-pill" style={{color: 'white',position: 'absolute', background: '#ff9c27', borderRadius: '50%'}}>{usernotificationList?.notifications?.filter(notify => {
                            return notify.read === 0;
                          })?.length}</span></span>
                  </Link>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img src={logoservicesvg} className="img-fluid" alt="Serviceora"/>
                  </Link>
                  <Link id="menu_close" className="menu-close" to="#"   onClick={toggleMenu}>
                    <i className="fas fa-times"></i>
                  </Link>
                </div>
                <ul className="main-nav">
                  <li className="">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="has-submenu ">
                    <Link to="/jobs">Jobs</Link>
                  </li>
                  {auth ? (
                  <>
                    <li className="login-link">
                      <Link to="/user/dashboard">My Dashboard</Link>
                    </li>
                    <li className="login-link">
                      <Link to="/user/service/list" style={{textDecoration:'underline'}}>Find Service</Link>
                    </li>
                    <li
                      className="login-link"
                      // onClick={() => setIsModalVisible(true)}
                      onClick={handleLogout}
                    >
                      <Link className="">Logout</Link>
                    </li>
                  </>
                ) :
                  <li className="login-link">
                    <Link to="/login">Login / Signup</Link>
                  </li>
                }
                  <li className="has-submenu ">
                    <Link to="/contact-us">Contact us</Link>
                  </li>
                  <li className="has-submenu ">
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li className="has-submenu ">
                    <Link to="/user/service/list" style={{textDecoration:'underline', color:'#009da6'}}>Find Service</Link>
                  </li>
                </ul>
              </div>
              <ul className="nav header-navbar-rht main-nav">
              <li className="nav-item dropdown logged-item has-submenu  ">
                <Link
                  to="#"
                  className="nav-link"
                  onClick={handleShow}
                >
                  <span className="user-img">
                    <i className="fa fa-bell"></i> <span className="badge badge-pill" style={{ color: 'white', position: 'absolute', background: 'rgb(255, 156, 39)', borderRadius: '50%', marginTop: '10px' }}>{usernotificationList?.notifications?.filter(notify => {
                      return notify.read === 0;
                    })?.length}</span>
                  </span>
                </Link>
              <HeadNotification handleClose={handleClose} show={show} setShow={setShow} handleShow={handleShow} usernotificationList={usernotificationList} usernotificationloading={usernotificationloading} openNotificationPopup={openNotificationPopup} handleClearAll={handleClearAll} isDown={isDown} userData={userData}/>
              </li>
                <li className="nav-item dropdown has-arrow logged-item has-submenu  ">
                  <Link
                    to="#"
                    className="dropdown-toggle nav-link"
                    data-bs-toggle="dropdown"
                  >
                    <span className="user-img">
                      {/* {loading} */}
                      <img
                        className="rounded-circle me-2"
                        src={
                          `${
                            profileData.length > 0
                              ? rootConfig.base_url + profileData?.[0]?.profile
                              : blankImg
                          }`
                        }
                        width="31"
                        alt={profileData?.[0]?.name?.toLocaleUpperCase()}
                      />
                      {profileData?.[0]?.name?.toLocaleUpperCase()}
                    </span>
                  </Link>
                  <ul className="submenu">
                    <li className="">
                      <Link to="/user/dashboard">My Dashboard</Link>
                    </li>
                    {/* <li className="">
                      <Link to="/user/profile">Profile</Link>
                    </li>
                    <li className="">
                      <Link to="/user/change-password">Change Password</Link>
                    </li> */}
                    <li
                      className="nav-item"
                      onClick={() => setIsModalVisible(true)}
                    >
                      <Link className="">Logout</Link>
                    </li>
                  </ul>
                </li>
              </ul>
              {/* <ul className="nav header-navbar-rht">
                <li
                  className="nav-item"
                  onClick={() => setIsModalVisible(true)}
                >
                  <Link className="nav-link header-login">Logout</Link>
                </li>
              </ul> */}
            </div>
          </nav>
        </header>
      )}
      <Outlet />
    </>
  );
};

const useAuth = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
};

export const OuterContent = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const PublicRoutes = () => {
  const auth = useAuth();
  // console.log(auth, "auth");

  return auth ? <InnerContent /> : <OuterContent />;
};

export default PublicRoutes;
