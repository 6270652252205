import { createReducer } from "reduxsauce";
import { SocialTypes } from "./action";


const INITIAL_STATE = {
    socialloading: false,
    socialList: [],
    error: ''
}


const getSocialListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        socialloading: true
    }
}

const getSocialListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        socialloading: false,
        socialList: data
    }
}

const getSocialListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        socialloading: false
     }
}


const HANDLERS = {
    [SocialTypes.GET_SOCIAL_LIST_START]: getSocialListStart,
    [SocialTypes.GET_SOCIAL_LIST_SUCCESS]: getSocialListSuccess,
    [SocialTypes.GET_SOCIAL_LIST_FAILURE]: getSocialListFailure
}

export const socialReducer = createReducer(INITIAL_STATE, HANDLERS)