import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProfileCreators } from "../store";
import "./style.css";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Loader from "../../../../Common/Loader/Loader";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { Modal } from "react-bootstrap";
import { bidColumns } from "../../../../Common/Data";
import { Table } from "../../../../Common/Table/Table2";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { UppdateJob } from "./UpdateJob";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faPersonCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useSearchParams, useNavigate, createSearchParams, useLocation } from "react-router-dom";
import ModalComponent from "../../../../Common/Modal/Modal";
import moment from "moment";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as HeartSolid } from "../../../../assets/icons/HeartSolid.svg";
import { ReactComponent as Heart } from "../../../../assets/icons/Heart.svg";
import { styled } from "styled-components";
import SearchableDropdown from "../../../../Common/SearchDropDown/SearchInput";
import { updateProfileCreators } from "../Profile/store";
import { LandingPageCreators } from "../../Landing/store";
import { Footer } from "../../Landing/Footer";
import bidIcon from "../../../../assets/img/logo/bidding.png";
import { createBrowserHistory } from "history";
import copy from 'copy-to-clipboard';
import AppHelmet from "../../Helmet/Helmet";
import Button from 'react-bootstrap-button-loader';
import callBack from '../../../../assets/img/callback.png'
import { CoinsCreators } from "../Coins/store";
import { confirmAlert } from 'react-confirm-alert'; // Import


export const Job = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const history = createBrowserHistory()
  const [allParams] = useSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isModal, setIsModal] = useState(null);
  const [bidData, setBidData] = useState([]);
  const [jobTitle, setJobTitle] = useState(false)
  const [seletedJob, setSelectedJob] = useState(null);
  const [errorList, setErrorList] = useState([]);
  const [searchCountry, setSearchCountry] = useState("");
  const [searchState, setSearchState] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [input, setInput] = useState("");
  const [isRequestLoading, setRequestLoading] = useState(false);
  const [isRequestCallBackModal, setIsRequestCallBackModal] = useState(false)
  const [isRequestInput, setIsRequestInput] = useState({})




  // console.log('item', Object.fromEntries([...allParams]))
  // console.log('userlocation', location)


  const { nationList, nationLoading } = useSelector(
    (state) => state.updateProfileData
  );

  const { categories } = useSelector((state) => state.profileData);
  const params = '?' + new URLSearchParams(Object.fromEntries([...allParams])).toString().toLowerCase();
  const { coinsList, coinsloading } = useSelector((state) => state.coinsData);



  const divRef = useRef();
  const outerDivRef = useRef();
  const userData = getUserDataFromLocal();

  const [bidInput, setBidInput] = useState({
    job_id: "",
    amount: "",
    amount_sign: "",
    duration: "",
    duration_key: "",
  });
  const dispatch = useDispatch();
  const { jobLoading, jobList, metaCount, profileData } = useSelector(
    (state) => state.profileData
  );
  // console.log('profile', profileData)
  // console.log('user', userData)
  // useEffect(() => {
  //   dispatch(ProfileCreators.getJobListSuccess({ data:[] }));
  // }, []);


  useEffect(() => {
    dispatch(ProfileCreators.getCategories());
    dispatch(ProfileCreators.getJobList({ params }));
  }, [params]);


  useEffect(() => {
    if (metaCount.last_page >= page) {
      dispatch(ProfileCreators.getJobList({ page, params }));
    }
  }, [page]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setBidInput({ ...bidInput, [name]: value });
  };

  const checkerFunction = (id, title) => {
    setJobTitle(title)
    setIsModalVisible(true);
    setBidInput({ ...bidInput, job_id: id });
  };

  const listInnerRef = useRef();

  useEffect(() => {
    const onScroll = () => {
      const container = document.querySelector(".customScroll");
      const { scrollTop, clientHeight, scrollHeight } = container;
      // console.log('scrol', scrollTop, clientHeight, scrollHeight)
      if (scrollTop + clientHeight >= scrollHeight) {
        // console.log(page, "age");
        setPage((prevPage) => prevPage + 1);
      }
      // else{
      //   setPage((prevPage) => prevPage = 1);
      // }
    };

    const container = document.querySelector(".customScroll");
    container.addEventListener("scroll", onScroll);

    return () => {
      container.removeEventListener("scroll", onScroll);
    };
  }, []);

  // console.log(metaCount, "=-=-=-", page, "--", jobList.length);

  const placeBidSuccess = async() => {
    try {
      const response = await WebService.post(
        apiEndPoints.jobbidding,
        JSON.stringify(bidInput),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        setBidInput([]);
        setErrorList([])
        setIsModalVisible(false);
        dispatch(ProfileCreators.getJobList({ page, params }));
        dispatch(CoinsCreators.getCoinsList());
      }
      else if (response.response.status === 422) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setErrorList(response.response.data.data);
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setBidInput([]);
        setIsModalVisible(false);
      } else {
        toast.error("something went wrong.", {
          ToastWarning,
          position: "top-right",
        });
      }

    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error("Something Went Wrong!", {
        position: "top-right",
      });
      // console.log(error, "error000");
    }
  }

  const placeBid = async (e) => {
    e.preventDefault();
    confirmAlert({
      title: 'Confirm to submit',
      message: <ul>
                <strong>Are you sure you want to make a bid?</strong>
                <li><strong>Free Bids: </strong> Enjoy 3 free bids every day to participate in our platform.</li>
                <li><strong>Remaining Free Bids: </strong> {coinsList?.today_bids >= 3 ? "you've used them all free bids." :  `You have ${3 - coinsList?.today_bids} free bids remaining for today.`}</li>
                <li><strong>Additional Bids: </strong>After using your free bids, there's a small charge of 5 coins for each extra bid.</li>
                <li><strong>Your Coin Balance:</strong> You currently have {coinsList?.coin_count} coins in your account.</li>
          </ul>,
      buttons: [
        {
          label: 'Yes',
          onClick: () => placeBidSuccess()
        },
        {
          label: 'No',
          onClick: () => alert('Please try again.')
        }
      ]
    });
   
  };

  const handleShowBid = async (id) => {
    // console.log(
    //   JSON.stringify({
    //     job_id: id,
    //   })
    // );
    const config = {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
      params: {
        job_id: id,
      },
    };
    setLoading(true);
    setIsModal("showBid");
    try {
      const response = await WebService.get(apiEndPoints.jobBid, config);
      if (response.status === 200) {
        const { data } = response;
        toast.success(data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        setJobTitle(data.data.title)
        setBidData(data.data?.job_bid);
        setLoading(false);
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setIsModalVisible(false);
      } else {
        toast.error('something went wrong!', {
          ToastWarning,
          position: "top-center",
        });
      }
    } catch (err) {
      toast.error(err, {
        position: "top-right",
      });
      setLoading(false);
    }
  };

  const addToFav = async (ele) => {
    // e.preventDefault();
    // console.log(ele);
    try {
      const response = await WebService.post(
        apiEndPoints.favJobCreate,
        JSON.stringify({ job_id: ele.id }),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      const { data } = response;
      if (response.status === 200) {
        // console.log(data, "=====");
        toast.success(data.msg, {
          ToastSuccess,
          position: "top-center",
        });
      } else if (response.response.status === 404) {

        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
      } else {
        throw response;
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }
  };

  const onUpdateModal = (ele) => {
    setSelectedJob(ele);
    setIsModal("updateForm");
  };

  const handleNation = (name, id) => {
    // console.log("fsd", name, id);
  };

  useEffect(() => {
    dispatch(updateProfileCreators.getNationData());
  }, [searchCountry]);


  const approveBidSuccess = async(job_id, bid_id) => {
    try {
      const response = await WebService.post(
        apiEndPoints.approvedBid,
        JSON.stringify({ job_id: job_id, bid_id: bid_id }),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          }
        }
      )
      if (response.status === 200) {
        toast.success(response.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        setIsModal(false);
        dispatch(ProfileCreators.getJobList({ page, params }));
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setIsModal(false);
      } else {
        toast.error("something went wrong.", {
          ToastWarning,
          position: "top-right",
        });
      }
    }
    catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }
  }

  const approveBid = async (job_id, bid_id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: <div>
                <strong>Are you sure you want to accept a bid?</strong>
          </div>,
      buttons: [
        {
          label: 'Yes',
          onClick: () => approveBidSuccess(job_id, bid_id)
        },
        {
          label: 'No',
          onClick: () => alert('Please try again.')
        }
      ]
    });
    
  }


  const handleRequestCallBack = async(job_code, jobTitle) => {
    setIsRequestCallBackModal(true)
    setJobTitle(jobTitle)
    setIsRequestInput({job_code: job_code})
  }
  const handleSubmitRequestCallbackAction = (e) => {
    e.preventDefault()
    handleSubmitRequestCallBackActionStatus()
  }
  const handleSubmitRequestCallBackActionStatus = async () => {
    try {
      setRequestLoading(true)
      const response = await WebService.post(
        apiEndPoints.requestACallBack,
        JSON.stringify({ ...isRequestInput}),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      // console.log('response', response)
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        setRequestLoading(false)
        setIsRequestCallBackModal(false)
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        setRequestLoading(false)
        setIsRequestCallBackModal(false)
      }
      else {
        toast.error('something went wrong!', {
          position: "top-center",
        });
        setIsRequestCallBackModal(false)
        setRequestLoading(false)
      }

    } catch (err) {
      const error = sagaCatchBlockHandling(err);
      toast.error("Something Went Wrong!", {
        position: "top-right",
      });

    }
  }

  const getValueStatus = (value) => {
    if (value === 'Processing') {
      return (
        <>
          <span className="badge bg-primary-light">
            <i className="far fa-circle"></i> {value}
          </span>
        </>
      )
    } else if (value === 'Active') {
      return (
        <>
          <span className="badge bg-success-light">
            <i className="fas fa-check"></i> {value}
          </span >
        </>
      )
    } else if (value === 'Approved') {
      return (
        <>
          <span className="badge bg-info-light">
            <i className="far fa-circle"></i> {value}
          </span>
        </>
      )
    } else if (value === 'Cancel') {
      return (
        <>
          <span className="badge bg-warning-light">
            <i className="fas fa-times"></i> {value}
          </span>
        </>
      )
    } else if (value === 'Completed') {
      return (
        <>
          <span className="badge bg-danger-light">
            <i className="fas fa-ban"></i> {value}
          </span>
        </>
      )
    }
    return value;
  }

  const searchItem = (searchValue) => {
    setSearchInput(searchValue)
    if (searchInput !== '') {
      const filteredData = jobList.filter((item) => {
        // const keys = Object.keys(item);
        // const keyValuePairs = keys.map(key => {
        //   return encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(item[key]));
        // });
        // console.log('key', keyValuePairs)
        // return keyValuePairs.join('&');

        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
      })
      setFilteredResults(filteredData)
    }
    else {
      setFilteredResults(jobList)
    }

  }

  

  useEffect(() => {
    document.body.classList.remove('menu-opened');
    dispatch(CoinsCreators.getCoinsList());
  }, [])

  const handleCopyJob = async (job_code) => {
    await copy(`${rootConfig?.app_url}/jobs?code=${job_code}`);        
    toast.success('The job copied successfully', {
      position: "top-center",
    });

    // if ("clipboard" in navigator) {
    //   await navigator.clipboard.writeText(`${rootConfig?.app_url}/jobs?code=${job_code}`);
    //   toast.success('The job url copied successfully', {
    //     ToastSuccess,
    //     position: "top-center",
    //   });
    // } else {
    //   document.execCommand("copy", true, `${rootConfig?.app_url}/jobs?code=${job_code}`);
    //   toast.success('The job url copied successfully', {
    //     ToastSuccess,
    //     position: "top-center",
    //   });
    // }
  }

  const handleGlobalSubmit = (e) => {
    // console.log('search', searchInput)
    e.preventDefault()
    navigate({
      pathname: '/jobs',
      // search: `?${createSearchParams(params)}`,
      search: `?${createSearchParams('service=' + searchInput)}`,
    });
    // dispatch(ProfileCreators.getJobList({ page, params }));
    // const path = location.pathname;
    // console.log('input', searchInput)
    // if (path === "/jobs") {
    //   navigate("?service=" + input);
    // } else {
    //   navigate("jobs?service=" + searchInput);
    // }
  }

  // useEffect(() => {
  //   const param = new URL(window.location.href).searchParams.get("service");
  //   if (param) console.log(param);
  // }, [params]);

  return (
    <>
    <AppHelmet title={`Browse Job Listings on Serviceora - Find Your Next Opportunity`} description="Explore a comprehensive list of job postings on Serviceora. Whether you're seeking freelance gigs or full-time roles, find and apply for the best opportunities that match your skills and interests." keywords="job list Serviceora, job postings, find jobs, job opportunities, browse job listings, apply for jobs, Serviceora jobs, job search, career opportunities" />
      <Toaster />
      <ModalComponent
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        title={jobTitle}
        isForm={true}
        setBidInput={setBidInput}
        bidInput={bidInput}
        handleChange={handleChange}
        handlerFunction={placeBid}
        errorList={errorList}
        setErrorList={setErrorList}
      />
      {isRequestCallBackModal && (
        <Modal
          show={true}
          size="lg"
          onHide={() => setIsRequestCallBackModal(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}>{jobTitle}</span> Request a call back?</Modal.Title>
          </Modal.Header>
            <Modal.Body>
              <h5>Free Expert Opinion at Your Convenience</h5>
              <p>Are you seeking expert guidance or advice for your needs, Our "Request a Callback" service is here to assist you.</p>
              <h5>What We Offer:</h5>
              <ul>
                  <li style={{listStyleType:'none'}}><i className="fa-solid fa-check-double"></i><strong> Free Expert Opinions: </strong> Our experienced team is ready to provide you with valuable insights and advice tailored to your unique requirements.</li>
                  <li style={{listStyleType:'none'}}><i className="fa-solid fa-check-double"></i><strong> Convenient Scheduling: </strong> You choose that suits you best, and our friendly customer executive will call you.</li>
                  <li style={{listStyleType:'none'}}><i className="fa-solid fa-check-double"></i><strong> No Obligation: </strong> There's no cost or commitment required. It's completely free!</li>
              </ul>
              <p>Take the first step toward getting the information you need. Request a callback now, and our customer executive will be happy to assist you.</p>
              <form onSubmit={handleSubmitRequestCallbackAction}>
                
                <Button className="btn btn-primary" type="submit" loading={isRequestLoading ? true : false}>Submit Your Request!</Button>
              </form>
            </Modal.Body>
          
        </Modal>
      )}
      {isModal && (
        <Modal
          show={true}
          size="lg"
          onHide={() => setIsModal(null)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}>{jobTitle}</span> View Bids</Modal.Title>
          </Modal.Header>

          <Modal.Body
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
          >
            {isModal === "updateForm" ? (
              <UppdateJob seletedJob={seletedJob} setIsModal={setIsModal} />
            ) : (
              <Table columns={bidColumns} data={bidData} loading={loading} userData={userData} approveBid={approveBid} />
            )}
          </Modal.Body>
        </Modal>
      )}

      <form onSubmit={handleGlobalSubmit}>
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-3">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Jobs
                    </li>
                  </ol>
                </nav>
                <h5 className="">{filteredResults?.length < 1 ? jobList?.length : filteredResults?.length} jobs found!</h5>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <input type="text" className="form-control" placeholder="Search the job by city, code, name, type" onChange={(e) => searchItem(e.target.value)} name="service" />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="submit" style={{ lineHeight: "28px" }}>
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                  <div className="input-group-append">
                    <Link className="btn btn-secondary" to="/jobs" style={{ lineHeight: "28px", marginLeft: "10px" }}><i className="fas fa-undo"></i> <span>Reset</span></Link>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="sort-by">
                  {/* <span className="sort-title">Sort by</span> */}
                  <span className="sortby-fliter">
                    {/* <select className="form-control select">
              <option>Sort By</option>
              <option className="sorting">Rating</option>
              <option className="sorting">Popular</option>
              <option className="sorting">Latest</option>
              <option className="sorting">Free</option>
              </select> */}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="content">
        <div className="container-fluid">
          <div className="row justify-content-center" ref={outerDivRef}>


            <div className="col-md-12 col-lg-8 col-xl-9">
              <div
                className=" overflow-auto customScroll"
                style={{ height: "70vh" }}
                // onScroll={onScroll}
                ref={listInnerRef}
              >
                {/* {searchInput.length < 1 ? 'No job found' : ''} */}
                {jobList?.length < 1 ? 'No Job Found' : jobList.map((item, index) => {
                  

                  return (
                    <div key={index} className="card recent-job">
                      <div className="card-body">
                        <div className="mentor-widget">
                          <div className="user-info-left">

                            <div className="avatar avatar-xl mentor-img" >
                              <img className="avatar-img rounded-circle" style={{ height: "100px" }} alt={item?.job_user?.name} src={rootConfig.base_url + item?.job_user?.profile} />
                            </div>
                            <div className="user-info-cont">
                              <h4 className="usr-name capitalize">
                                <Link to={`/jobs/${item?.job_code}`}>{item?.title + " "} {getValueStatus(item?.status)}</Link> 
                              </h4>
                              <Link to={`/business/${item?.job_user?.user_name}`}><span className="capitalize" style={{ color: "#22A699" }}>{item?.job_user?.name} </span></Link>
                              <span className="badge badge-info">{item?.job_user?.type !== 'Customer' ? 'Service Provider' : 'Customer'}</span>
                              {/* <span className="capitalize" style={{ color: "#22A699" }}>{item?.job_user?.name}</span> */}
                              <p className="mentor-type capitalize ">
                                {item?.description?.substring(0, 130)}... <Link to={`/jobs/${item?.job_code}`}>Read More</Link>
                              </p>

                              <div className="avatar-group">
                                {
                                  item?.job_image?.map((imgitem, index) => {
                                    return (
                                        <div className="avatar" key={index}>
                                          <img className="avatar-img rounded-circle border border-white" alt={imgitem?.image} src={rootConfig.base_url + imgitem?.image} />
                                        </div>
                                    )
                                  })
                                }
                              </div>
                              <ul className="tags">
                                <li className="capitalize" style={{ marginLeft: "10px" }}><Link to="#" className="tag"><img src={rootConfig.base_url + item?.job_sub_category?.image} height="20px" width="20px" /> {item?.job_sub_category?.name}</Link></li>
                                <li className="capitalize"><Link to="#" className="tag" ><img src={rootConfig.base_url + item?.job_category?.image} height="20px" width="20px" /> {item?.job_category?.name}</Link></li>
                              </ul>
                            </div>
                          </div>
                          <div className="user-info-right">
                            <div className="user-infos">
                              <ul>
                                {
                                  userData?.userData?.id === item?.user_id ? 
                                  <li>
                                      <img src={callBack} width="25px" />
                                      <Link onClick={() => handleRequestCallBack(item?.job_code, item?.title)}><span style={{ color: '#ff9c27' }}> Request a call back?</span></Link>
                                  </li>
                                  : ''
                                }
                                <li>
                                  <i className="far fa-clock"></i>
                                  <span style={{ color: 'rgb(34, 166, 153)' }}>Published</span>  {moment(item?.created_at).fromNow()}
                                </li>
                                <li>
                                  <i className="fas fa-clock"></i>
                                  <span style={{ color: 'red' }}>Valid till</span>  {moment(item?.end_date).format(" Do MMMM, YYYY")}
                                </li>
                                <li>
                                  <i
                                    className="fas fa-map-marker-alt"
                                    aria-hidden="true"
                                  ></i>
                                  {item?.job_country?.emoji} {item?.job_city?.name}, {item?.job_state?.name}, {item?.job_country?.iso2}
                                </li>
                                {/* <li>
                                    <i className="far fa-money-bill-alt" aria-hidden="true"></i>
                                    {item?.job_bid_count}
                                  </li> */}
                              </ul>
                            </div>
                            <div className="mentor-booking">
                              <>
                                {/* <Link className="apt-btn">Show Bids</Link> */}
                                <IconSpan onClick={() => checkerFunction(item?.id, item?.title)}>
                                  {/* <FontAwesomeIcon
                                      icon={faPersonCirclePlus}
                                      size="xl"
                                    ></FontAwesomeIcon> */}
                                  {/* <i className="fa-solid fa-person-circle-question" size="lg" style={{ fontSize: '20px' }}></i>
                                     */}
                                  <span className="badge bg-success">
                                    <i className="fa fa-stamp"></i> Bid Now ({item?.job_bid_count})
                                  </span>
                                </IconSpan>
                                <IconSpan
                                  className="iconSpan"
                                  onClick={() => handleShowBid(item?.id)}
                                >
                                  <span className="badge bg-warning">
                                    <i className="fa fa-eye"></i> View Bid
                                  </span>
                                </IconSpan>
                                <IconSpan onClick={() => addToFav(item)}>
                                  <span className="badge bg-secondary">
                                    <i className="fa fa-heart"></i>
                                  </span>
                                </IconSpan>
                                <IconSpan onClick={() => handleCopyJob(item?.job_code)}>
                                  <span className="badge bg-info" title="Copy job url" >
                                    <i className="fa fa-copy"></i> Copy
                                  </span>
                                </IconSpan>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  );
                })}

                {jobLoading && <Loader width="3.4rem" />}

              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export const IconSpan = styled.span`
  padding: 2px;

  // margin: 0px 5px 0px 5px;
  // background: #eaeaea;
  cursor: pointer;
  border: 1px solid white;
  // border-radius: 5px;
  // height: 30px;
  // width: 50px;
  // display: inline-block;
  text-align: center;
`;
