import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { UserNotificationCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { ProfileCreators } from "../../Dashboard/Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import Loader from "../../../../Common/Loader/Loader";
import { SideBar } from "../SideBar";
import moment from "moment";
import './style.css'
import AppHelmet from "../../Helmet/Helmet";

export const UserNotification = () => {
    const userData = getUserDataFromLocal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { profileData } = useSelector((state) => state.profileData);
    const { usernotificationList, usernotificationloading } = useSelector((state) => state.userNotificationData);

    useEffect(() => {
        dispatch(UserNotificationCreators.getUserNotificationList());
    }, []);
    // console.log('usernotificationList', usernotificationList)

    return (
        <>
            <Toaster />
            <AppHelmet title="Serviceora Notifications - Stay Updated with Real-Time Alerts" description="View all your Serviceora notifications in one place. Stay informed about new bids, messages, service updates, and more with real-time alerts. Never miss an important update." keywords="Serviceora notifications, real-time alerts, notification screen, updates and alerts, bid notifications, service updates, message alerts, Serviceora updates" />
            {/* <ModalComponent /> */}
            {usernotificationloading ? (
                <Loader containerHeight={70} width="4rem" />
            ) : (
                <div className="main-wrapper">
                    <div className="breadcrumb-bar">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-12">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                <Link to="/user/notification">Notification</Link>
                                            </li>
                                        </ol>
                                    </nav>
                                    <h2 className="breadcrumb-title">Notification</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <SideBar profileData={profileData} />
                                <div className="col-md-7 col-lg-8 col-xl-9">
                                    {/* <div className="row">
                                        <div className="col-md-12">
                                            <h4 className="mb-4">Notification</h4>
                                            <div className="card card-table">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover">
                                                            <tbody>
                                                            {usernotificationList?.notifications?.map((notify, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td style={{ width: '100%' }}>
                                                                            <p className="text-muted pull-right capitalize" style={{textDecoration:'underline'}}><Link to={notify?.value}>{notify?.key}</Link></p><small style={{color:"#ff9c27"}}>{moment(notify?.created_at).fromNow()}</small>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            </tbody>
                                                            
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <section className="section-50">
                                        <div className="container">
                                            <h3 className="m-b-50 heading-line">Notifications <i className="fa fa-bell text-muted"></i></h3>

                                            <div className="notification-ui_dd-content">
                                            {usernotificationloading && <Loader width="1.4rem" />}
                                            {usernotificationList?.notifications?.length === 0 ? 'No notification found!' : usernotificationList?.notifications?.map((notify, index) => {
                                                return (
                                                    <div className={`notification-list ${(notify?.read) ? ' text-muted ' : ' notification-list--unread'}`} key={index}>
                                                        <div className="notification-list_content">
                                                            <div className="notification-list_img">
                                                                {
                                                                    notify?.type === 'add_job' ? 
                                                                    <img src={rootConfig?.base_url+notify?.job_notification?.job_user?.profile} alt="user" />
                                                                    : ''
                                                                }
                                                                {
                                                                    notify?.type === 'job_bid' ? 
                                                                    <img src={rootConfig?.base_url+notify?.service_notification?.profile} alt="user" />
                                                                    : ''
                                                                }
                                                                {
                                                                    notify?.type === 'bid_approve' ? 
                                                                    <img src={rootConfig?.base_url+notify?.job_notification?.job_user?.profile} alt="user" />
                                                                    : ''
                                                                }
                                                                {
                                                                    notify?.type === 'start_job' ? 
                                                                    <img src={rootConfig?.base_url+notify?.service_notification?.profile} alt="user" />
                                                                    : ''
                                                                }
                                                                {
                                                                    notify?.type === 'action_job' ? 
                                                                    <img src={rootConfig?.base_url+notify?.service_notification?.profile} alt="user" />
                                                                    : ''
                                                                }
                                                                {
                                                                    notify?.type === 'rating' ? 
                                                                    <img src={rootConfig?.base_url+notify?.job_notification?.job_user?.profile} alt="user" />
                                                                    : ''
                                                                }
                                                                {
                                                                    notify?.type === 'alert' ? 
                                                                    <img src={notify?.service_id} alt="user" />
                                                                    : ''
                                                                }
                                                                
                                                                
                                                            </div>
                                                            <div className="notification-list_detail">
                                                                {
                                                                    notify?.type === 'add_job' ? 
                                                                    <p><strong><Link to={`${`/business/${notify?.job_notification?.job_user?.user_name}`}`} style={{color:'#03b2cb'}}>{userData?.userData?.id === notify?.job_notification?.job_user?.id ? 'you' : notify?.job_notification?.job_user?.name} </Link></strong> {notify?.key} </p>
                                                                    : notify?.type === 'job_bid' ? 
                                                                    <p> {notify?.key} <strong><Link to={`${`/business/${userData?.userData?.id === notify?.job_notification?.job_user?.id ? notify?.service_notification?.user_name : notify?.job_notification?.job_user?.user_name}`}`} style={{color:'#03b2cb'}}>{userData?.userData?.id === notify?.job_notification?.job_user?.id ? notify?.service_notification?.name : notify?.job_notification?.job_user?.name} </Link></strong></p>
                                                                    : notify?.type === 'bid_approve' ? 
                                                                    <p> {notify?.key} <strong><Link to={`${`/business/${userData?.userData?.id === notify?.job_notification?.job_user?.id ? notify?.service_notification?.user_name : notify?.job_notification?.job_user?.user_name}`}`} style={{color:'#03b2cb'}}>{userData?.userData?.id === notify?.job_notification?.job_user?.id ? notify?.service_notification?.name : notify?.job_notification?.job_user?.name} </Link></strong></p>
                                                                    : notify?.type === 'start_job' ? 
                                                                    <p> {notify?.key} <strong><Link to={`${`/business/${userData?.userData?.id === notify?.job_notification?.job_user?.id ? notify?.service_notification?.user_name : notify?.service_notification?.user_name}`}`} style={{color:'#03b2cb'}}>{userData?.userData?.id === notify?.job_notification?.job_user?.id ? notify?.service_notification?.name : 'you'} </Link></strong></p>
                                                                    : notify?.type === 'action_job' ? 
                                                                    <p> {notify?.key} <strong><Link to={`${`/business/${userData?.userData?.id === notify?.job_notification?.job_user?.id ? notify?.service_notification?.user_name : notify?.service_notification?.user_name}`}`} style={{color:'#03b2cb'}}>{userData?.userData?.id === notify?.job_notification?.job_user?.id ? 'you' : notify?.service_notification?.name} </Link></strong></p>
                                                                    : notify?.type === 'rating' ? 
                                                                    <p> {notify?.key} <strong><Link to={`${`/business/${userData?.userData?.id === notify?.job_notification?.job_user?.id ? notify?.service_notification?.user_name : notify?.service_notification?.user_name}`}`} style={{color:'#03b2cb'}}>{userData?.userData?.id === notify?.job_notification?.job_user?.id ? notify?.service_notification?.name : notify?.service_notification?.name} </Link></strong></p>
                                                                    : notify?.type === 'start' ? 
                                                                    <p> {notify?.key} <strong><Link to={`${`/business/${userData?.userData?.id === notify?.job_notification?.job_user?.id ? notify?.service_notification?.user_name : notify?.service_notification?.user_name}`}`} style={{color:'#03b2cb'}}>{userData?.userData?.id === notify?.job_notification?.job_user?.id ? notify?.service_notification?.name : notify?.service_notification?.name} </Link></strong></p>
                                                                    : notify?.type === 'alert' ? 
                                                                    <p><strong><Link to={notify?.url} style={{color:'#009DA6'}}>{notify?.key}</Link></strong></p>
                                                                    : ''
                                                                }
                                                                
                                                                <p className="text-muted capitalize"><Link to={notify?.url} style={{color:'#ff9c27'}}>{notify?.value}</Link></p>
                                                                <p className="text-muted"><small>{moment(notify?.created_at).fromNow()}</small></p>
                                                            </div>
                                                        </div>
                                                        <div className="notification-list_feature-img">
                                                            {/* <img src={ShramLogo} alt="Feature image" /> */}
                                                        </div>
                                                    </div>
                                                )
                                                })
                                            }
                                            </div>

                                            {/* <div className="text-center">
                                                <a href="#!" className="dark-link">Load more activity</a>
                                            </div> */}

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
} 