import { createActions } from 'reduxsauce';

export const { Types: MyJobTypes, Creators: MyJobCreators } = createActions({
    getMyJobList: ['payload'],
    getMyJobListStart: ['payload'],
    getMyJobListSuccess: ['payload'],
    getMyJobListFailure: ['payload']

/*** hello, I am here */

})