
export const ToastSuccess = {
    iconTheme: {primary: 'green', secondary: '#FFFAEE' },
    style: {border: '1px solid green', padding: '16px', color: 'green'}

  };

  export const ToastWarning = {
    iconTheme: {primary: 'red', secondary: '#FFFAEE'  },
    style: {border: '1px solid red', padding: '16px', color: 'red'}

  };