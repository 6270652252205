import { createReducer } from "reduxsauce";
import { EarnmoneyTypes } from "./action";


const INITIAL_STATE = {
    Earnmoneyloading: false,
    EarnmoneyList: [],
    error: ''
}


const getEarnmoneyListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        Earnmoneyloading: true
    }
}

const getEarnmoneyListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    // console.log('action', action)
    return {
        ...state,
        Earnmoneyloading: false,
        EarnmoneyList: data
    }
}

const getEarnmoneyListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        Earnmoneyloading: false
     }
}


const HANDLERS = {
    [EarnmoneyTypes.GET_EARNMONEY_LIST_START]: getEarnmoneyListStart,
    [EarnmoneyTypes.GET_EARNMONEY_LIST_SUCCESS]: getEarnmoneyListSuccess,
    [EarnmoneyTypes.GET_EARNMONEY_LIST_FAILURE]: getEarnmoneyListFailure
}

export const EarnmoneyReducer = createReducer(INITIAL_STATE, HANDLERS)