import React from "react";
import { rootConfig } from "../../../services/axios/endPoints";
import {
  Link,
  useNavigate,
} from 'react-router-dom';



export const CategoryListView = ({ categorylist, itemsToShow, auth }) => {
  return (
    <>
      {categorylist.slice(0, itemsToShow).map((item, index) => {
        const { id, slug, name, type, image } = item;
        return (
            <div key={index} className="col-md-4 col-lg-4 d-flex flex-wrap">
              <div className="allcourse-card popular-subcat" data-aos="zoom-in" data-aos-delay="100">
                <div className="allcourse-img">
                  <img
                    src={rootConfig?.base_url+image}
                    alt={name}
                    className="img-fluid-category"
                  />
                </div>
                <div className="allcourse-content">
                  <h4 className="mb-3"><Link to={`${auth ? `/jobs?subcategory=${slug}` : "/login"}`} style={{color:'white'}}>{name?.toLocaleUpperCase()}</Link></h4>
                  <p>Over <span className="text-warning">{item?.job_count}</span> Services</p>
                </div>
              </div>
            </div>
        );
      })}
    </>
  );
};
