import { createReducer } from "reduxsauce";
import { TestimonialsTypes } from "./action";


const INITIAL_STATE = {
    testimonialsloading: false,
    testimonialsList: [],
    aboutloading: false,
    aboutList: [],
    error: ''
}


const getTestimonialsListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        testimonialsloading: true
    }
}

const getTestimonialsListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        testimonialsloading: false,
        testimonialsList: data
    }
}

const getTestimonialsListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        testimonialsloading: false
     }
}

const getAboutListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        aboutloading: true
    }
}
const getAboutListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        aboutloading: false,
        aboutList: data
    }
}

const getAboutListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        aboutloading: false
     }
}


const HANDLERS = {
    [TestimonialsTypes.GET_TESTIMONIALS_LIST_START]: getTestimonialsListStart,
    [TestimonialsTypes.GET_TESTIMONIALS_LIST_SUCCESS]: getTestimonialsListSuccess,
    [TestimonialsTypes.GET_TESTIMONIALS_LIST_FAILURE]: getTestimonialsListFailure,
    [TestimonialsTypes.GET_ABOUT_LIST_START]: getAboutListStart,
    [TestimonialsTypes.GET_ABOUT_LIST_SUCCESS]: getAboutListSuccess,
    [TestimonialsTypes.GET_ABOUT_LIST_FAILURE]: getAboutListFailure,
}

export const TestimonialsReducer = createReducer(INITIAL_STATE, HANDLERS)