import { createReducer } from "reduxsauce";
import { UserNotificationTypes } from "./action";


const INITIAL_STATE = {
    usernotificationloading: false,
    usernotificationList: [],
    error: ''
}


const getUserNotificationListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        usernotificationloading: true
    }
}

const getUserNotificationListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    // console.log('action', action)
    return {
        ...state,
        usernotificationloading: false,
        usernotificationList: data
    }
}

const getUserNotificationListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        usernotificationloading: false
     }
}


const HANDLERS = {
    [UserNotificationTypes.GET_USER_NOTIFICATION_LIST_START]: getUserNotificationListStart,
    [UserNotificationTypes.GET_USER_NOTIFICATION_LIST_SUCCESS]: getUserNotificationListSuccess,
    [UserNotificationTypes.GET_USER_NOTIFICATION_LIST_FAILURE]: getUserNotificationListFailure
}

export const UserNotificationReducer = createReducer(INITIAL_STATE, HANDLERS)