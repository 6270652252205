import React, { useEffect, useState, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { SubcategoryCreators } from "../Subcategory/store";
import { TestimonialsCreators } from "./store";
import { rootConfig } from "../../../../services/axios/endPoints";
import Loader from "../../../../Common/Loader/Loader";
import './style.css'
import invite_friend from '../../../../assets/img/invite_friend.png'
import coin_received from '../../../../assets/img/coin_received.png'
import coins from '../../../../assets/img/5coins.png'
import dotted_arrow1 from '../../../../assets/img/dotted-arrow1.png'
import dotted_arrow2 from '../../../../assets/img/dotted-arrow2.png'
import copy from 'copy-to-clipboard';
import toast, { Toaster } from "react-hot-toast";
import ShareWhatsapp from "../ShareWhatsapp/ShareWhatsapp";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";


// export default class Testimonials extends Component {
export const RefrelCodeFriend = (profileData) => {
    const userData = getUserDataFromLocal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const [buttonText, setButtonText] = useState('COPY LINK');
    const [styleVal, setStyleVal] = useState("light");
    const { subcategoryList } = useSelector((state) => state.subcategoryData);
    const { testimonialsList, testimonialloading, aboutloading, aboutList } = useSelector((state) => state.testimonialsData)
    useEffect(() => {
        dispatch(TestimonialsCreators.getTestimonialsList());
        dispatch(TestimonialsCreators.getAboutList())
        setInputValue();
    }, []);


    const handleCopyReferral = async (referral_link) => {
        await copy(referral_link);
        setButtonText('Copied!');
        if (styleVal !== "dark")
            setStyleVal("light");
        else setStyleVal("light");
        toast.success('The referal link copied successfully', {
            position: "top-center",
        });
    }

    return (
        <div className="container">
            <div className="section-heading d-flex align-items-center">
                <div className="heading-content">
                    <h2>
                        <span className="text-weight">Featured </span> Category
                        <span className="header-right"></span>
                    </h2>
                    <p>They are highly qualified and trained in their areas</p>
                </div>
            </div>

            <div className="row mt-30 mb-30">
                <div className="col-sm-12 col-md-3">
                    <div className="share-boxes">
                        <img src={invite_friend} alt="img1" border="0" />
                        <p>Share with your friends to give them an opportunity.</p>
                    </div>
                </div>
                <div className="col"></div>
                <div className="col-sm-12 col-md-3">
                    <div className="share-boxes">
                        <img src={coins} alt="img2" border="0" />
                        <p>Give him 5 Coins.</p>
                        <img src={dotted_arrow1} alt="dotted-arrow1" className="dotted-line" />
                        <img src={dotted_arrow2} alt="dotted-arrow2" className="dotted-line2" />
                    </div>
                </div>
                <div className="col"></div>
                <div className="col-sm-12 col-md-3">
                    <div className="share-boxes">
                        <img src={coin_received} alt="img3" border="0" style={{ height: '70px' }} />
                        <p>Earn 5 coins for every sign-up using your referral code.</p>
                    </div>
                </div>
            </div>
            <div className="row refer-form-sec">
                <div className="col">
                    <div className="refer-form-content">
                        <h2>Share To WhatsApp Friends</h2>
                        <p>Earn coins! Share referral link on WhatsApp!</p>
                        <div className='title flex_middle'>
                            <Link className="btn btn-primary btn-sm" to={`https://api.whatsapp.com/send?text=Hey%20Friends!%0ATry%20ShramSuvidha!%20Use%20my%20code%20for%20rewards:%20%5B${profileData?.profileData[0]?.refId}%5D.%0ALink:%20${rootConfig?.app_url}/register?referral=${profileData?.profileData[0]?.refId}.%0ADownload%20App:%20https://play.google.com/store/apps/details?id=serviceora.app%0AEnjoy!`}  rel="nofollow noopener" target="_blank"><WhatsAppIcon /> Share the referral link</Link>
                        </div>


                    </div>
                </div>
                <div className="col">
                    {/* <div className="refer-form">
                        <ul>
                            <li className="facebook-color"><a href="#">Facebook</a></li>
                            <li className="youtube-color"><a href="#">you tube</a></li>
                            <li className="twitter-color"><a href="#">twitter</a></li>
                        </ul>
                    </div> */}
                    <div className="refer-form-content">
                        <h2>Share To Friends referral link</h2>
                        <p>Earn coins! Share your referral code link with friends. You can start <Link>NOW!</Link></p>

                        <h3 className="mt-4" >Share the referral link</h3>
                        <label className="form-label" htmlFor="modalRnFLink">You can also copy and send it or share it on your social media. </label>
                        <input type="text" className={`form-control ${{ styleVal }}`} name="referral_link" value={`${rootConfig?.app_url}/register?referral=${profileData?.profileData?.length > 0 ? profileData?.profileData[0]?.refId : ''}`} style={{ fontSize: '14px', fontWeight: 'bold' }} readOnly />
                        <button className="btn btn-primary btn-sm" onClick={() => handleCopyReferral(`${rootConfig?.app_url}/register?referral=${profileData?.profileData[0]?.refId}`)}>{buttonText}</button>

                    </div>
                </div>
            </div>
            {/* <div className="row mt-30 mb-30">
                <div className="col">
                    <div className="referal-progress">
                        <h2>YOUR REFERAL PROGRESS</h2>
                        <table className="table table-hover">
                            <tbody>
                                <tr>
                                    <td>No. of friends who have purchased</td>
                                    <td><strong>USD : 10.00</strong></td>
                                </tr>
                                <tr>
                                    <td>No. of friends who have purchased</td>
                                    <td><strong>USD : 10.00</strong></td>
                                </tr>
                                <tr>
                                    <td>No. of friends who have purchased</td>
                                    <td><strong>USD : 10.00</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
        </div>
    );
}