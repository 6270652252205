export const bidColumns = [
  {
    id: 1,
    label: "Username",
    key: "username",
  },
  {
    id: 2,
    label: "Amount",
    key: "amount",
  },

  {
    id: 3,
    label: "Duration",
    key: "duration",
  },
  {
    id: 4,
    label: "Message",
    key: "message",
  },
  {
    id: 5,
    label: "Status",
    key: "status",
  },
  {
    id: 6,
    label: "Action",
    key: "action",
  }
];
