import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { ProfileCreators } from "../store";
import { FlexContainer } from "../../../../globalStyles";
import { Creators } from "../../../auth/store";
import Loader from "../../../../Common/Loader/Loader";
import { SideBar } from "../SideBar";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Badge from 'react-bootstrap/Badge';
import AppHelmet from "../../Helmet/Helmet";

export const Profile = () => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();
  const { profileData, loading } = useSelector((state) => state.profileData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ProfileCreators.getProfile());
  }, []);

  // const handleLogout = () => {
  //   dispatch(Creators.onLogout());
  //   navigate("/");
  // };

  // console.log(profileData, "profileData");

  return (
    <>
    <AppHelmet title="Serviceora Profile - Manage Your Account as a Customer or Service Provider" description="Access and update your Serviceora profile as a customer or service provider. Manage your account details, services, preferences, and track your activity in one convenient place." keywords="Serviceora profile, customer profile, service provider profile, manage account, update profile details, service management, account preferences, track activity, Serviceora login" />
      {loading ? (
        <Loader containerHeight={70} width="4rem" />
      ) : (
        <div className="main-wrapper">
          <div className="breadcrumb-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        <Link to="/user/profile">Profile</Link>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="breadcrumb-title">Profile</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div className="row ">
                <SideBar profileData={profileData} />
                <div className="col-md-7 col-lg-8 col-xl-9">
                  <div className="row card">
                    <div className="card-header">
                      <h4 className="card-title">Profile</h4>
                    </div>
                    <div className="card-body">
                      <div className="mentor-widget">
                        <div className="user-info-left align-items-center">
                          <div className="mentor-img d-flex justify-content-center" style={{ border: "1px groove lightgray", background: "none" }}>
                            <img src={rootConfig.base_url + profileData[0]?.profile} style={{ border: "1px groove lightgray", background: "none", height: "143px", width: "143px", borderRadius: "50%" }} />

                          </div>
                          <div className="user-info-cont">
                          <h4 className="usr-name">
                              <span style={{ color: '#ff9c27' }}>{profileData[0]?.business_name?.toLocaleUpperCase()}
                                {/* <span className="badge badge-primary">{profileData[0]?.user_role?.toLocaleUpperCase()}</span> */}
                              </span>
                            </h4>
                          {profileData[0]?.country?.emoji} {profileData[0]?.name?.toLocaleUpperCase()} 
                          <b style={{color:"#009da6"}}>({(profileData[0]?.type === 'Customer') ? profileData[0]?.type : 'Service Provider'})</b>
                            
                            <p className="mentor-type">{profileData[0]?.email} {profileData[0]?.is_verified ? <i className="fas fa-check-circle" style={{ color: "green" }}></i> : ''}</p>
                            <div className="mentor-action">
                              {/* <p className="mentor-type social-title">
                                <i className="fas fa-key"></i> {profileData[0]?.shram_code}
                              </p> */}
                              <p className="mentor-type social-title">
                                {
                                  profileData[0]?.phone_number ? (
                                    <>
                                      <i className="fas fa-phone"></i> {profileData[0]?.phone_number} {profileData[0]?.is_phone_verified ? <i className="fas fa-check-circle" style={{ color: "green" }}></i> : ''}
                                    </>
                                  )
                                    : ""
                                }

                              </p>
                              <p className="mentor-type social-title">
                                {
                                  profileData[0]?.aadhar_number ? (
                                    <>
                                      <i className="fas fa-id-card"></i> {profileData[0]?.aadhar_number} 
                                    </>
                                  )
                                    : ""
                                }

                              </p>
                              
                                {
                                  profileData[0]?.front_aadhar_image ? (
                                    <div className="avatar" style={{ height: "100px", width: "200px", margin: "6px" }}>
                                    <img className="avatar-img rounded" alt="User Image" src={rootConfig.base_url + profileData[0]?.front_aadhar_image} />
                                    </div>
                                  )
                                    : ""
                                }

                              
                              
                                {
                                  profileData[0]?.front_aadhar_image ? (
                                    <div className="avatar" style={{ height: "100px", width: "200px", margin: "6px" }}>
                                      <img className="avatar-img rounded" alt="User Image" src={rootConfig.base_url + profileData[0]?.back_aadhar_image} />
                                    </div>
                                  )
                                    : ""
                                }

                              <p className="mentor-type social-title">
                              <Link className="badge btn-warning" to="/user/profile-settings">
                                Update Profile
                              </Link>
                              <Link className="badge btn-info" to={`/business/${profileData[0]?.user_name}`}>
                                View Profile
                              </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div className="card">
                    <div className="card-body custom-border-card pb-0">
                      <div className="widget education-widget mb-0">
                        <h4 className="widget-title">Details</h4>
                        <hr />
                        <div className="experience-box">
                          <ul className="experience-list profile-custom-list">
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>{profileData[0]?.type === 'Service' ? 'Business Name' : 'Display Name'}</span>
                                  <div className="row-result">{profileData[0]?.business_name}</div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>Service Type</span>
                                  <div className="row-result">{profileData[0]?.user_role}</div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>Skilled</span>
                                  <div className="row-result">
                                    {
                                        profileData[0]?.user_meta?.filter((item, index) => item.key === 'skilled')?.length === 1 ?

                                            JSON.parse(profileData[0]?.user_meta?.filter((item, index) => item.key === 'skilled')[0]?.value).map((tag, index) => {
                                                return (
                                                    <Badge className="capitalize" key={index} bg="light" text="dark" style={{ margin: "3px" }}>{tag?.label}</Badge>
                                                )
                                            })
                                            : ''
                                    }
                                    </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                  <div className="card">
                    <div className="card-body custom-border-card pb-0">
                      <div className="widget education-widget mb-0">
                        <h4 className="widget-title">Personal Details</h4>
                        <hr />
                        <div className="experience-box">
                          <ul className="experience-list profile-custom-list">
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>Gender</span>
                                  <div className="row-result">{profileData[0]?.gender}</div>
                                </div>
                              </div>
                            </li>
                            {/* <li>
                            <div className="experience-content">
                              <div className="timeline-content">
                                <span>Date of Birth</span>
                                <div className="row-result">{profileData[0]?.dob}</div>
                              </div>
                            </div>
                          </li> */}
                            {/* <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>Where did you hear about us?</span>
                                  <div className="row-result">Through web search</div>
                                </div>
                              </div>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {/* <div className="card">
                  <div className="card-body custom-border-card pb-0">
                    <div className="widget experience-widget mb-0">
                      <h4 className="widget-title">Qualification</h4>
                      <hr />
                      <div className="experience-box">
                        <ul className="experience-list profile-custom-list">
                          <li>
                            <div className="experience-content">
                              <div className="timeline-content">
                                <span>Undergraduate College</span>
                                <div className="row-result">
                                  Coimbatore University
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="experience-content">
                              <div className="timeline-content">
                                <span>Undergraduate Major</span>
                                <div className="row-result">Mathematics</div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="experience-content">
                              <div className="timeline-content">
                                <span>Graduate College</span>
                                <div className="row-result">
                                  Coimbatore University
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="experience-content">
                              <div className="timeline-content">
                                <span>Type of Degree</span>
                                <div className="row-result">B.Sc (Maths)</div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                  <div className="card">
                    <div className="card-body pb-1 custom-border-card">
                      <div className="widget awards-widget m-0">
                        <h4 className="widget-title">Location</h4>
                        <hr />
                        <div className="experience-box">
                          <ul className="experience-list profile-custom-list">
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>Correspondence Address</span>
                                  <div className="row-result">{profileData[0]?.correspondence_address}</div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>Permanent Address</span>
                                  <div className="row-result">
                                    {profileData[0]?.permanent_address}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>Country</span>
                                  <div className="row-result">{profileData[0]?.country?.name}</div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>City</span>
                                  <div className="row-result">{profileData[0]?.city?.name}</div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>State</span>
                                  <div className="row-result">{profileData[0]?.state?.name}</div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body custom-border-card pb-0">
                      <div className="widget education-widget mb-0">
                        <h4 className="widget-title"> Description</h4>
                        <hr />
                        <div className="experience-box">
                          <ul className="experience-list profile-custom-list">
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  {/* <span>Gender</span> */}
                                  <div className="row-result">{profileData[0]?.business_description}</div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
