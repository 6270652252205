import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../Footer";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { LandingPageCreators } from "../../Dashboard/store";
import { ProfileCreators } from "../../Dashboard/store";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { WebService } from "../../../../services/axios/webServices";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { Creators } from "../../../auth/store";
import BusinessProfileJob from "../BusinessProfile/BusinessProfileJob";
import moment from "moment";
import { Rating } from 'react-simple-star-rating'
import BusinessProfileRating from "../BusinessProfile/BusinessProfileRating";
import Loader from "../../../../Common/Loader/Loader";
import './style.css'
import { ServiceProviderCreators } from "./store";
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import { ShieldFillCheck, Whatsapp, PatchCheckFill } from 'react-bootstrap-icons';
import { Modal } from "react-bootstrap";
import ShramPost from '../../../../assets/img/shram-post.png'
import Button from 'react-bootstrap-button-loader';
import toast, { Toaster } from "react-hot-toast";
import { ErrorP } from "../../../../Common/Modal/Modal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import AppHelmet from "../../Helmet/Helmet";





const useAuth = () => {
    const user = localStorage.getItem("user");
    if (user) {
        return true;
    } else {
        return false;
    }
};
const FindServiceProviderList = () => {
    const { user_name } = useParams();
    const auth = useAuth();
    const userData = getUserDataFromLocal();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSendEnquiry, setIsSendEnquiry] = useState(false)
    const [loadingForm, setLoadingForm] = useState(false)
    const [serviceProviderId, setServiceProviderId] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [visible, setVisible] = useState(false);
    const [msgTag, setMsgTag] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);


    const { profileData, dashboardLoading, jobList } = useSelector(
        (state) => state.profileData
    );
    const [registerInput, setRegisterInput] = useState({
        provider_id: "",
        name: "",
        email_address: "",
        mobile_number: "",
    })
    const [errorList, seterrorList] = useState([]);
    const { loginmsg } = useSelector((state) => state.loginData);

    const { loading, serviceProviderList } = useSelector((state) => state.serviceProviderData);

    useEffect(() => {

        dispatch(ProfileCreators.getProfile());
        dispatch(ServiceProviderCreators.getServiceProviderList())

    }, []);

    // console.log('serviceProviderList', serviceProviderList)


    const handleLogout = () => {
        dispatch(Creators.onLogout());
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRegisterInput({ ...registerInput, [name]: value });
      };

    const handleEnquiry = (provider_id) => {
        // console.log('provider', provider_id)
        setServiceProviderId(provider_id)
        setRegisterInput({...registerInput, provider_id: provider_id, name: profileData[0]?.name, email_address: profileData[0]?.email, mobile_number: profileData[0]?.phone_number})
        setIsSendEnquiry(true)
        setVisible(false)
        setSuccessMsg('')
        // setJobTitle(jobTitle)
        // setIsRequestInput({job_code: job_code})
    }

    const handleSubmitEnquiryAction = async(e) => {
        e.preventDefault();
        try {
            // console.log('register', registerInput)
            // return false;
            setLoadingForm(true)
            const res = await WebService.post(
              apiEndPoints.submitSendQuery,
              JSON.stringify(registerInput),
              {
                headers: {
                  Authorization: `Bearer ${userData.token}`,
                }
              }
            );
            if (res.status === 200) {
             
              toast.success(res.data.msg, {
                position: "top-center",
                duration: 6000
              });        
              setVisible(true)
              setSuccessMsg(res.data.msg)   
              setMsgTag('success')    
              seterrorList([])         
              setRegisterInput({name:"", email_address:"", mobile_number:"", provider_id: serviceProviderId})                             
            }else if (res.response.status === 422) {
                toast.error(res.response.data.msg, {
                  position: "top-center",
                });
                setVisible(false)
                seterrorList(res.response.data.data);
              } else if (res.response.status === 404) {
              toast.error(res.response.data.msg, {
                position: "top-center",
              });
              setVisible(true)
              setSuccessMsg(res.response.data.msg)
              setMsgTag('warning')
              seterrorList([])
              setRegisterInput({name:"", email_address:"", mobile_number:"", provider_id: serviceProviderId})                    
            } else {
              toast.error("something went wrong.", {
                position: "top-right",
              });
              setLoadingForm(false)
            }
          } catch (e) {
            const { error } = sagaCatchBlockHandling(e);
            setLoadingForm(false)
          }
    }

    const handleWhatsappLink = async(phone_number, business_name, phone_code) => {
        // console.log('phone', phone_number)
        // return false;
        if(phone_number != null)
        {
            window.open(`https://api.whatsapp.com/send?phone=${phone_number}&text=Hello+${business_name},%0A+I+found+your+number+on+the+shram+suvidha.+i+have+a+question.%0AThankyou!&type=phone_number&app_absent=0`, '_blank', 'noopener');
        }
        else{
            
            toast.error("The service provider doesn't use WhatsApp. Please send your inquiry here. Thanks!", {
                position: "top-center",
            });
        }
        

    }

    return (
        <>
        <AppHelmet title="Find Service Providers - Explore Top Professionals on Serviceora" description="Browse our extensive list of service providers on Serviceora. Discover top-rated professionals, read reviews, and find the best fit for your needs. Start connecting with experts today!" keywords="find service providers, Serviceora professionals, service provider list, top-rated service providers, explore providers, connect with experts, Serviceora directory, professional services, find top professionals" />
        {isSendEnquiry && (
        <Modal
          show={true}
          size="lg"
          onHide={() => setIsSendEnquiry(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Send Enquiry</Modal.Title>
          </Modal.Header>
            <Modal.Body>
            <div className="row">
                <div className="col-lg-6">
                    {
                        msgTag === 'success' ? 
                        <div className="alert alert-success" role="alert" data-mdb-color="success" style={{ display: `${visible ? `block` : `none`}` }}>
                            <i className="fas fa-check-circle me-3"></i>{successMsg}
                        </div>
                        : '' ||
                        msgTag === 'warning' ?
                        <div className="alert alert-warning" role="alert" data-mdb-color="warning">
                            <i className="fas fa-exclamation-triangle me-3"></i>{successMsg}
                        </div>
                        : ''


                    }
                    <div className="featured-card bg-light-grey" style={{padding:'20px'}}>
                        <form onSubmit={handleSubmitEnquiryAction}>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="form-control-label"> Name</label>
                                <input
                                id="name"
                                type="text"
                                className="form-control"
                                name="name"
                                autoComplete="off"
                                placeholder="name"
                                value={registerInput.name}
                                onChange={handleChange}
                                />
                                <ErrorP>{errorList.name}</ErrorP>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="form-control-label">Email Address</label>
                                <input
                                id="email-address"
                                type="text"
                                className="form-control"
                                name="email_address"
                                autoComplete="off"
                                placeholder="Email address"
                                value={registerInput.email_address}
                                onChange={handleChange}
                                />
                                <ErrorP>{errorList.email_address}</ErrorP>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <label className="form-control-label">Mobile Number</label>
                                <input
                                id="mobile-number"
                                type="text"
                                className="form-control"
                                name="mobile_number"
                                autoComplete="off"
                                placeholder="Mobile number"
                                value={registerInput.mobile_number}
                                onChange={handleChange}
                                />
                                <ErrorP>{errorList.mobile_number}</ErrorP>
                            </div>
                        </div>
                            <Button className="btn btn-primary login-btn" type="submit"  style={{marginTop:'25px'}}>Send Enquiry!</Button>
                        </form>
                        <br/>
                        <ul className="mb-0 text-muted" style={{fontSize:'12px'}}>
                            <li>Your request is carefully sent to relevant businesses.</li>
                            <li>Businesses compete to offer you the best possible deals.</li>
                            <li>You have the freedom to choose whichever option suits you best.</li>
                            <li>Contact information is then promptly shared with you via SMS or email.</li>
                        </ul>
                        

                    <div className="account-footer text-center mt-3 text-center dont-have">
                  </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="featured-card" style={{padding:'0px'}}>
                        <Link to="https://play.google.com/store/apps/details?id=serviceora.app" target="_blank"><img src={ShramPost} height="100%" width="100%"/></Link>
                    </div>
                </div>
            </div>
              {/* <form onSubmit={handleSubmitRequestCallbackAction}> */}
                
                {/* <Button className="btn btn-primary" type="submit" loading={isRequestLoading ? true : false}>Submit Your Request!</Button> */}
              {/* </form> */}
            </Modal.Body>
          
        </Modal>
      )}

            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-3 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active capitalize" aria-current="page">
                                        Find Service Provider
                                    </li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                </div>
            </div>

            <div className="main-wrapper">
                <div className="content">
                    <div className="container">
                        {/* <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="show-results mt-5">
                                    <div className="float-left">
                                        <h5 className="text-dark mb-0 pt-2">Showing  Results : 1-6 of 540</h5>
                                    </div>
                                    <div className="sort-button float-right">
                                        <ul className="list-inline mb-0">
                                            <li className="list-inline-item mr-3">
                                                <select className="nice-select" >
                                                    <option data-display="Sort By">Nothing</option>
                                                    <option value="1">Web Developer</option>
                                                    <option value="2">PHP Developer</option>
                                                    <option value="3">Web Designer</option>
                                                </select>
                                            </li>

                                            <li className="list-inline-item">
                                                <select className="nice-select">
                                                    <option data-display="Default">Nothing</option>
                                                    <option value="1">Web Developer</option>
                                                    <option value="2">PHP Developer</option>
                                                    <option value="3">Web Designer</option>
                                                </select>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="row">

                            <div className="col-lg-12">
                                <div className="candidates-listing-item">
                                    {serviceProviderList?.data?.length > 1 && serviceProviderList?.data.map((provider, index) => {
                                        return (
                                            <div className="list-grid-item mt-4 p-2" key={index}>
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="candidates-img float-left mr-4">
                                                            <img src={rootConfig.base_url + provider?.profile} alt="" className="img-fluid d-block rounded" />
                                                        </div>
                                                        <div className="candidates-list-desc job-single-meta  pt-2">
                                                            <h5 className="mb-2 f-19">{provider?.business_name ?
                                                                <>
                                                                    <Link to={`${auth ? `/business/${provider?.user_name}` : "/login"}`} className="text-dark capitalize">{provider?.business_name} </Link> <ShieldFillCheck data-tooltip-id="shield-verify" style={{ color:'#999' }}  /> 
                                                                    {provider?.user_meta?.filter((item, index) => item.key === 'shram_verified')?.length === 1 ? 
                                                                    <PatchCheckFill data-tooltip-id="patch-verify" style={{ color:'green' }}  />
                                                                    : ''}
                                                                    
                                                                    <ReactTooltip id="shield-verify" place="bottom"  content="The Business information is verified." style={{fontSize:'10px', color:'white', background:'gray', border:'1px groove lightgray'}}/>  
                                                                    <ReactTooltip id="patch-verify" place="bottom"  content="The Business information is verified by Serviceora." style={{fontSize:'10px', color:'white', background:'green', border:'1px groove lightgray'}}/>  
                                                                </> : ''
                                                            } </h5>


                                                            <h6 className="mb-2 f-19 text-warning"><Badge className="capitalize" key={index} text="light" style={{ backgroundColor: '#058a07' }}>{provider?.sender_rating_avg?.length === 1 ? provider?.sender_rating_avg[0]?.avg_rating : '3'}</Badge> <Rating readonly initialValue={provider?.sender_rating_avg?.length === 1 ? provider?.sender_rating_avg[0]?.avg_rating : '3'} size={18} allowFraction />  <span className="text-muted">{provider?.rating_count} Reviews</span></h6>
                                                            {/* <h6 className="mb-2 f-19"><Link  className="text-primary capitalize">{provider?.name} {provider?.business_name ? <Badge className="capitalize" key={index} bg="primary" text="light">Active</Badge> : '' }</Link></h6> */}
                                                            <ul className="list-inline mb-0">
                                                                <li className="list-inline-item mr-4">
                                                                    <p className="text-muted f-15 mb-0"> {provider?.user_role ?
                                                                        <><i className="fa-solid fa-user"></i> {provider?.user_role} </>
                                                                        : ''}</p>
                                                                </li>

                                                                <li className="list-inline-item mr-4">
                                                                    <p className="f-15 mb-0"><Link className="text-muted"> {provider?.city?.name ?
                                                                        <><i className="fa-sharp fa-solid fa-location-dot"></i> {provider?.city?.name}, {provider?.state?.name}, {provider?.country?.name}</> : ''}</Link></p>
                                                                </li>

                                                                {/* <li className="list-inline-item">
                                                                    <p className="text-muted f-15 mb-0"><i className="mdi mdi-currency-usd mr-1"></i>$700 - $900/month</p>
                                                                </li> */}
                                                            </ul>
                                                            <p className="text-muted mt-1 mb-0">Skills : {
                                                                provider?.user_meta?.filter((item, index) => item.key === 'skilled')?.length === 1 ?

                                                                    JSON.parse(provider?.user_meta?.filter((item, index) => item.key === 'skilled')[0]?.value).map((tag, index) => {
                                                                        return (
                                                                            <Badge className="capitalize" key={index} bg="light" text="dark" style={{ margin: "3px" }}>{tag?.label}</Badge>
                                                                        )
                                                                    })
                                                                    : ''
                                                            }</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <div className="candidates-list-fav-btn text-right">
                                                            <div className="mentor-action">
                                                                <p className="mentor-type social-title text-center">Contact Me</p>
                                                                <Link to={`tel:${provider?.phone_number}`} className="btn-blue">
                                                                    <i className="fas fa-phone" size="18"></i> 
                                                                </Link>
                                                                <Link className="btn btn-primary" onClick={() => handleEnquiry(provider?.id)}>
                                                                    Send Enquiry
                                                                </Link>
                                                                <Link className="btn-blue" data-bs-toggle="modal"
                                                                    data-bs-target="#voice_call">
                                                                    <Whatsapp onClick={() => handleWhatsappLink(provider?.phone_number, provider?.business_name, provider?.country?.phone_code)}  />
                                                                </Link>
                                                            </div>
                                                            <div className="candidates-listing-btn mt-4 text-center">
                                                                {/* <Link className="btn btn-outline btn-sm">View Profile</Link> */}
                                                            </div>
                                                            <div className="jsx-3349e7cd87e12d75 font12 fw500 color111" style={{margin: '20px', position: 'relative', textDecoration: 'underline',fontSize: '13px', textAlign: 'center'}}>
                                                                <i className="fas fa-chart-line-up"></i><i className="fa-solid fa-chart-line"></i> {(provider?.user_enquiry_count) ? provider?.user_enquiry_count : Math.floor(Math.random() * 50) } people recently enquired</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="row">
                                                    <div className="col-lg-11 offset-lg-1">
                                                        <div className="candidates-item-desc">
                                                            <hr />
                                                            <p className="text-muted mb-2 f-14">{provider?.business_description?.substring(0, 130)}...</p>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>

                        {/* <div className="row">
                            <div className="col-lg-12">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination job-pagination justify-content-center mt-5 mb-5">
                                        <li className="page-item disabled">
                                            <Link className="page-link"  tabIndex="-1" aria-disabled="true">
                                                <i className="mdi mdi-chevron-double-left f-15"></i>
                                            </Link>
                                        </li>
                                        <li className="page-item active"><Link className="page-link" >1</Link></li>
                                        <li className="page-item"><Link className="page-link">2</Link></li>
                                        <li className="page-item"><Link className="page-link" >3</Link></li>
                                        <li className="page-item"><Link className="page-link" >4</Link></li>
                                        <li className="page-item">
                                            <Link className="page-link">
                                                <i className="mdi mdi-chevron-double-right f-15"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="container"> */}

                    {/* <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body">


                            </div>
                        </div> */}

                    {/* <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body custom-border-card pb-0">

                                <div className="tab-content pt-0">

                                    <div role="tabpanel" id="biography" className="tab-pane fade show active">
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="widget about-widget custom-about mb-0">
                                                    <h4 className="widget-title">About Me</h4>
                                                    <hr />
                                                    <p>{business?.business_description}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body custom-border-card pb-0">

                                <div className="widget education-widget mb-0">
                                    <h4 className="widget-title">Personal Details</h4>
                                    <hr />
                                    <div className="experience-box">
                                        <ul className="experience-list profile-custom-list">
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>Contact Number</span>
                                                        <div className="row-result">*****</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>Email Address</span>
                                                        <div className="row-result">*****</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body custom-border-card pb-0">

                                <div className="widget awards-widget m-0">
                                    <h4 className="widget-title">Location</h4>
                                    <hr />
                                    <div className="experience-box">
                                        <ul className="experience-list profile-custom-list">
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>Address 1</span>
                                                        <div className="row-result">*****</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>Address 2</span>
                                                        <div className="row-result">*****</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>Country</span>
                                                        <div className="row-result">{business?.country?.name}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>City</span>
                                                        <div className="row-result">{business?.city?.name}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>State</span>
                                                        <div className="row-result">{business?.state?.name}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body custom-border-card pb-0">

                                <div className="widget awards-widget m-0">
                                    <h4 className="widget-title">Recent Jobs</h4>
                                    <hr />
                                    <div className="experience-box">
                                        {loading && <Loader width="1.4rem" />}
                                        <BusinessProfileJob jobs={businessjob} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body custom-border-card pb-0">

                                <div className="widget education-widget mb-0">
                                    <h4 className="widget-title">Review</h4>
                                    <hr />
                                    <div className="experience-box">
                                        {loading && <Loader width="1.4rem" />}
                                        <BusinessProfileRating serviceRating={serviceRating} />


                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card col-10 me-auto ms-auto p-0">



                        </div> */}


                    {/* </div> */}
                </div>
                <Footer />
            </div>
        </>
    )
}


export default FindServiceProviderList