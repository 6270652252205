import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getUserDataFromLocal } from "../shared/utility/helper";
import { OuterContent } from "./PublicRoutes";

const ProtectedRoutes = (props) => {
  const { authenticated } = useSelector((state) => state.loginData);
  const userData = getUserDataFromLocal();

  return userData?.token ? <Outlet /> : <Navigate to="/home" />;
};

export default ProtectedRoutes;
