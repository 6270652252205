import { put, call } from 'redux-saga/effects';
import { TestimonialsCreators } from './action';
import { WebService } from '../../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../../services/axios/endPoints';
import { getUserDataFromLocal } from '../../../../../shared/utility/helper';

const userData = getUserDataFromLocal();
// console.log(userData.token, 'userData.token');

export function* getTestimonialsListSaga() {
  try {
    yield put(TestimonialsCreators.getTestimonialsListStart());
    const response = yield WebService.get(apiEndPoints.testimonialList,
      {
          headers: {
            'Content-Type': 'application/json'
          }
        }
    );

    if (response.status === 200) {
      const { data, msg } = response.data;
      yield put(TestimonialsCreators.getTestimonialsListSuccess({ data, message: msg }));
    } else {
      throw response;
    }
  } catch (error) {

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };

          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };

          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };

          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
        // error: 'custom error'
      };
    }
    yield put(TestimonialsCreators.getTestimonialsListFailure(payload));
  }
}

export function* getAboutListSaga() {
  try {
    yield put(TestimonialsCreators.getAboutListStart());
    const response = yield WebService.get(apiEndPoints.aboutList,
      {
          headers: {
            'Content-Type': 'application/json'
          }
        }
    );

    if (response.status === 200) {
      const { data, msg } = response.data;
      yield put(TestimonialsCreators.getAboutListSuccess({ data, message: msg }));
    } else {
      throw response;
    }
  } catch (error) {

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };

          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };

          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };

          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
        // error: 'custom error'
      };
    }
    yield put(TestimonialsCreators.getAboutListFailure(payload));
  }
}
