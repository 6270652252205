export const setToLocalStorage = (userDetails) => {
  if (userDetails) {
    // console.log('userdetail', userDetails)
    localStorage.setItem("user", JSON.stringify(userDetails));
  }
};

export const getFromLocalStorage = () => localStorage.getItem("user");

export const deleteLocalStorage = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("persist:root");
  localStorage.removeItem("device_token");
  localStorage.removeItem("android_device_token");
  
  
};
