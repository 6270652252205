import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { FlexContainer } from "../../../../globalStyles";
import SearchableDropdown from "../../../../Common/Input/SearchInput";
import Loader from "../../../../Common/Loader/Loader";
// import ModalComponent, { Modal } from "../../Common/Modal/Modal";
import ModalComponent from "../../../../Common/Modal/Modal";
import { SideBar } from "../SideBar";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { ProfileCreators } from "../../Dashboard/Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { styled } from "styled-components";
import { ErrorP } from "../../../../Common/Modal/Modal";
import Select from "react-select";
import { CategoriesCreators } from "../../Landing/Categories/store";

const JobUpdate = () => {
  const naviagate = useNavigate();
  const { job_code } = useParams();
  const userData = getUserDataFromLocal();
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState([]);
  const [errorList, seterrorList] = useState([])
  const [jobData, setJobData] = useState({})

  const { profileData } = useSelector((state) => state.profileData);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value});
  };

  const handleJobView = async() => {
    try{

        setLoading(true)
        const res = await WebService.post(
            apiEndPoints.jobView,
            JSON.stringify({job_code:job_code}),
            {
                headers: {
                  Authorization: `Bearer ${userData.token}`,
                },
              }
        );
        // console.log('res', res);
        if (res.status === 200) {
            setJobData(res.data.data)
            // toast.success(res.data.msg, {
            // ToastSuccess,
            // position: "top-center",
            // duration: 6000
            // });
        } else if (res.response.status === 422) {
            // console.log(res.response.data.data)
            // toast.error(res.response.data.msg, {
            // ToastWarning,
            // position: "top-center",
            // });
            // setLoading(false)
            // seterrorList(res.response.data.data);
        } else if (res.response.status === 404) {
            // toast.error(res.response.data.msg, {
            // ToastWarning,
            // position: "top-center",
            // });
            // setLoading(false)
        } else {
            // toast.error("something went wrong.", {
            // ToastWarning,
            // position: "top-right",
            // });
            // setLoading(false)
        }
    }
    catch(e)
    {
        const { error } = sagaCatchBlockHandling(e);
        setLoading(false)
        toast.error("Something Went Wrong!", {
          position: "top-right",
        });
    }
  }

//   console.log('job_data', jobData)
  useEffect(() => {
    handleJobView();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    handleChangePassword()
}

const handleChangePassword = async() => {
    try{

        setLoading(true)
        const res = await WebService.post(
            apiEndPoints.ChangePassword,
            JSON.stringify(inputData),
            {
                headers: {
                  Authorization: `Bearer ${userData.token}`,
                },
              }
        );
        // console.log('res', res);
        if (res.status === 200) {
            toast.success(res.data.msg, {
            ToastSuccess,
            position: "top-center",
            duration: 6000
            });
            setLoading(false)
            seterrorList([]);
            setTimeout(() => {
                naviagate("/login");
            }, 3000)
        } else if (res.response.status === 422) {
            // console.log(res.response.data.data)
            toast.error(res.response.data.msg, {
            ToastWarning,
            position: "top-center",
            });
            setLoading(false)
            seterrorList(res.response.data.data);
        } else if (res.response.status === 404) {
            toast.error(res.response.data.msg, {
            ToastWarning,
            position: "top-center",
            });
            setLoading(false)
        } else {
            toast.error("something went wrong.", {
            ToastWarning,
            position: "top-right",
            });
            setLoading(false)
        }
    }
    catch(e)
    {
        const { error } = sagaCatchBlockHandling(e);
        setLoading(false)
        toast.error("Something Went Wrong!", {
          position: "top-right",
        });
    }
}

    
  return (
    <>
      <Toaster />
      
        <div className="main-wrapper">
          <div className="breadcrumb-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <Link to="/user/change-password">Change Password</Link>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="breadcrumb-title">Change Password</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
          <div className="container-fluid">
            <div className="row ">
              <SideBar profileData={profileData} />
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row card">
                  <div className="card-header">
                    <h4 className="card-title">Add Job</h4>
                  </div>
                  <div className="card-body">
                    <div className="mentor-widget">
                      <form noValidate onSubmit={handleSubmit}>
                        <div className="row form-row">
                          <div className="">
                            <div className="form-group">
                              <div className="change-avatar">
                                <div className="upload-img"></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label>Category</label> <Required>*</Required>
                              {/* <SearchableDropdown
                                label="name"
                                id="id"
                                options={catagories}
                                selectedVal={search}
                                handleChange={(val) => setSearch(val)}
                                setcategoryId={setcategoryId}
                                setSubCategoryList={setSubCategoryList}
                                aria-describedby="validationTooltipUsernamePrepend"
                                name="category"
                                catagories={catagories}
                                required
                              /> */}
                              
                              <ErrorP>{errorList.category_id}</ErrorP>
                            </div>
                          </div>

                          

                          
                        </div>

                        <div className="submit-section">
                          <button
                            type="submit"
                            className="btn btn-primary submit-btn"
                          >
                            Submit

                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </>
  );
};

export default JobUpdate;

export const Required = styled.span`
  color: red;
`;
