import { createReducer } from "reduxsauce";
import { CoinsTypes } from "./action";


const INITIAL_STATE = {
    coinsloading: false,
    coinsList: [],
    error: ''
}


const getCoinsListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        coinsloading: true
    }
}

const getCoinsListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    // console.log('action', action)
    return {
        ...state,
        coinsloading: false,
        coinsList: data
    }
}

const getCoinsListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        coinsloading: false
     }
}


const HANDLERS = {
    [CoinsTypes.GET_COINS_LIST_START]: getCoinsListStart,
    [CoinsTypes.GET_COINS_LIST_SUCCESS]: getCoinsListSuccess,
    [CoinsTypes.GET_COINS_LIST_FAILURE]: getCoinsListFailure
}

export const CoinsReducer = createReducer(INITIAL_STATE, HANDLERS)