import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener } from './firebase';
import './style.css'
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';

const Notification = () => {
  const [notification, setNotification] = useState({ title: '', body: '' });
  const notify = () => toast(<ToastDisplay />);
  const [isPushModal, setIsPushModal] = useState(false)
  function ToastDisplay() {
    return (
      <>
      {isPushModal && (
        <Modal
          show={true}
          // size="lg"
          onHide={() => setIsPushModal(false)}
          // backdrop="static"
          keyboard={true}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}></span> Push Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content py-md-5 px-md-4 p-sm-3 p-4 text-center">

              
              <i className="fa fa-bell" style={{ color: '#2b84be', fontSize: '90px', padding: '30px 0px' }}></i>
              <h3>{notification?.title}</h3>
              <p className="r3 px-md-5 px-sm-1">{notification?.body}</p>
              {/* <div className="text-center mb-3"> <button className="btn btn-primary w-50 rounded-pill b1">Subscribe</button> </div> */}

              <a href="/user/notification" style={{textDecoration:'underline'}}>Click here to see all details.</a>
            </div>
          </Modal.Body>
        </Modal>
      )}
      </>
    );
  };

  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])


  requestForToken();

  onMessageListener()
    .then((payload) => {
      setNotification({ title: payload?.notification?.title, body: payload?.notification?.body });
      setIsPushModal(true)
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <Toaster />
  )
}

export default Notification
