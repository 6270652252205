import { call, put } from "redux-saga/effects";
import { WebService } from "../../../../../services/axios/webServices";
import { apiEndPoints } from "../../../../../services/axios/endPoints";
import { updateProfileCreators } from "./index.js";
import { getUserDataFromLocal } from "../../../../../shared/utility/helper";

let userData = getUserDataFromLocal();

export function* updateProfileSaga(payload) {
  const { updateInput } = payload.payload;
  try {
    yield put(updateProfileCreators.updateProfileStart());
    const response = yield WebService.post(
      apiEndPoints.updateProfile,
      JSON.stringify(updateInput),
      {
        headers: { Authorization: "Bearer " + userData.token },
      }
    );
    // const response = true;
    // console.log(response, "response");

    if (response.status) {
      // console.log(response, "response");
      //   const { data } = response.data;
      yield put(updateProfileCreators.updateProfileSuccess(response.data));
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          yield put(
            updateProfileCreators.updateProfileFailure({
              error: error?.response?.data?.message,
            })
          );
          break;
        case 500:
          yield put(
            updateProfileCreators.updateProfileFailure({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            updateProfileCreators.updateProfileFailure({
              error: "Unable to update. Try again later",
            })
          );
      }
    } else {
      yield put(
        updateProfileCreators.updateProfileFailure({
          error: "No connection try again Later.",
        })
      );
    }
  }
}

export function* getNationDataSaga() {
  try {
    yield put(updateProfileCreators.getNationDataStart());

    const response = yield WebService.get(`${apiEndPoints.nation}`, {
      headers: { Authorization: "Bearer " + userData.token },
    });
    // const response = true;
    if (response.status === 200) {
      const { data } = response.data;
      yield put(updateProfileCreators.getNationDataSuccess({ data }));
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          yield put(
            updateProfileCreators.getNationDataFailure({
              error: error?.response?.data?.message,
            })
          );
          break;
        case 500:
          yield put(
            updateProfileCreators.getNationDataFailure({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            updateProfileCreators.getNationDataFailure({
              error: "Unable to update. Try again later",
            })
          );
      }
    } else {
      yield put(
        updateProfileCreators.getNationDataFailure({
          error: "No connection try again Later.",
        })
      );
    }
  }
}
