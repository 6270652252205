import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  login: ["loginDetails"],
  onLoginStart: [],
  onLoginSuccess: ["payload"],
  onLoginFail: ["payload"],
  loginOTP: ["loginDetailsOTP"],
  onLoginOTPStart: [],
  onLoginOTPSuccess: ["payload"],
  onLoginOTPFail: ["payload"],
  setUserDetails: ["payload"],
  onLogout: ["payload"],
  onLogoutSuccess: ["payload"],
  onLogoutAllDevice: ["payload"],
  onLogoutAllDeviceSuccess: ["payload"],
  register: ["payload"],
});



export { Types, Creators };
