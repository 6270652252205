import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Toaster, toast } from "react-hot-toast";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { WebService } from "../../../../services/axios/webServices";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import moment from "moment/moment";
import { Rating } from 'react-simple-star-rating'
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';



const useAuth = () => {
    const user = localStorage.getItem("user");
    if (user) {
        return true;
    } else {
        return false;
    }
};
const BusinessProfileJob = (jobs) => {
    const [ratingValue, setRatingValue] = useState(0)

    const userData = getUserDataFromLocal();
    const navigate = useNavigate();


    const handleRating = (rate) => {
        setRatingValue(rate)
    }
    const handleReset = () => {
        setRatingValue(0)
    }

    const addToFav = async (ele) => {
        try {
            const response = await WebService.post(
                apiEndPoints.favJobCreate,
                JSON.stringify({ job_id: ele.id }),
                {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                }
            );
            if (response.status === 200) {
                toast.success(response.data.msg, {
                    position: "top-center",
                });
            } else if (response.response.status === 404) {
                toast.error(response.response.data.msg, {
                    position: "top-center",
                });
            }
            else {
                toast.error('something went wrong!', {
                    position: "top-center",
                });
            }
        } catch (e) {
            const error = sagaCatchBlockHandling(e);
            toast.error(error?.error, {
                position: "top-right",
            });
        }
    };

    const avgrating = (ratingData) => {
        const mapData = ratingData.reduce((acc, { name, rating }) => {
            const match = acc.get(name);
            match ?
                match.push(rating) :
                acc.set(name, [rating]);
            return acc;
        }, new Map);

        const averageArray = Array.from(mapData, ([name, ratings]) => {
            const rating = ratings.reduce((a, r) => (a + r)) / ratings.length;
            return { name, rating }
        });
        return averageArray;
    }

    const ratingavg = (ratingVal) => {
        // console.log('ratingVal', ratingVal)
        const rating = (5 * 252 + 4 * 124) / (252 + 124)
        return rating;

    }

    const getValueStatus = (value) => {
        if (value === 'Processing') {
          return (
              <Badge className="capitalize" bg="primary" text="light" style={{ margin: "3px" }}>{value}</Badge>
          )
        } else if (value === 'Active') {
          return (
            <Badge className="capitalize" bg="success" text="light" style={{ margin: "3px" }}>{value}</Badge>
          )
        } else if (value === 'Approved') {
          return (
              <Badge className="capitalize" bg="info" text="light" style={{ margin: "3px" }}>{value}</Badge>
          )
        } else if (value === 'Cancel') {
          return (
              <Badge className="capitalize" bg="warning" text="light" style={{ margin: "3px" }}>{value}</Badge>
          )
        } else if (value === 'Completed') {
          return (
              <Badge className="capitalize" bg="danger" text="light" style={{ margin: "3px" }}>{value}</Badge>
          )
        }
        return value;
      }


    return (
        <>
            <Toaster />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-7 col-lg-8 col-xl-12"  style={{ overflowY: 'auto', overflowX: 'hidden', height: `${jobs?.jobs?.length ? `400px` : "100px"}` }}>
                        <div className="row row-grid">
                            {
                                jobs?.jobs?.length < 1 ?
                                    <>Not Found Jobs</>
                                    :
                                    jobs?.jobs?.map((job, index) => {
                                        return (
                                            <div key={index} className="col-md-6 col-lg-4 col-xl-3">
                                                <div className="profile-widget">
                                                    <div className="user-avatar">
                                                    </div>
                                                    <div className="pro-content">
                                                        <h3 className="title">
                                                            <Link to="" className="capitalize text-primary" style={{fontSize:'15px'}}>{job?.title} {getValueStatus(job?.status)}</Link>
                                                            {/* <i className="fas fa-check-circle verified"></i> */}
                                                        </h3>
                                                        <ul className="tags">
                                                            <li className="capitalize tag" style={{ marginLeft: "10px" }}><img src={rootConfig.base_url + job?.job_sub_category?.image} height="20px" width="20px" /> {job?.job_sub_category?.name}</li>
                                                            <li className="capitalize tag"><img src={rootConfig.base_url + job?.job_category?.image} height="20px" width="20px" /> {job?.job_category?.name}</li>
                                                        </ul>

                                                        {/* <Rating readonly initialValue={ratingavg(job?.job_rating)} size="18" /> */}
                                                        <ul className="available-info">
                                                            <li>
                                                                <i className="fas fa-map-marker-alt"></i> {job?.job_city?.name}, {job?.job_state?.name}, {job?.job_country?.name}
                                                            </li>
                                                            <li>
                                                                <i className="far fa-clock"></i> Available till {moment(job?.end_date).format(" Do MMMM, YYYY")}
                                                            </li>
                                                        </ul>
                                                        <div className="row row-sm">
                                                            {/* <div className="col-6"> */}
                                                                {/* <Badge className="capitalize" bg="secondary" text="light" style={{ margin: "3px" }} onClick={() => addToFav(job)}>Add to Fav</Badge> */}
                                                            {/* </div>
                                                            <div className="col-6">
                                                                
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                            }


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default BusinessProfileJob