import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthService from "../../services/axios/auth.servies";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AuthCreator, Creators } from "./store/index";
import { debounce } from "lodash";
// import { WebService } from "../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../services/axios/endPoints";
// import { setAccessTokenToHeader } from "../../services/axios/helper";
import { LocalNotifications } from '@capacitor/local-notifications';
import validator from 'validator'
import Button from 'react-bootstrap-button-loader';




import {
  getUserDataFromLocal,
  setUserDataToLocal,
} from "../../shared/utility/helper";
import toast, { Toaster } from "react-hot-toast";
import { ErrorP } from "../../Common/Modal/Modal";
import { isEmail } from "../../shared/utility/regex";
import Loader from "../../Common/Loader/Loader";
import { SocialCreators } from "../Pages/Landing/Social/store";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { WebService } from "../../services/axios/webServices";
import { sagaCatchBlockHandling } from "../../store/utility/sagaErrorHandling";
import { Modal } from "react-bootstrap";





export const LoginWithMobileNumber = () => {
  const userData = getUserDataFromLocal();
  const { error, loading, authenticated, loginmsg } = useSelector(
    (state) => state.loginData
  );
  const form = useRef();
  const checkBtn = useRef();
  const [loginInput, setLoginInput] = useState({ mobile_number: "", device_token: localStorage.getItem('device_token'), android_device_token: localStorage.getItem('android_device_token') });
  const [message, setMessage] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorList, seterrorList] = useState([])
  const [errorPhone, setErrorPhone] = useState(false)
  const [isOtpModal, setIsOtpModal] = useState(false)


  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setLoginInput({ ...loginInput, [name]: value });
    if (!(loginInput?.mobile_number.match('[0-9]{9}'))) {
      setErrorPhone({ mobile_number: 'Please provide valid phone number.' })
      return false;
    }
    else {
      setErrorPhone({ phone: '' })
    }

  };

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await WebService.post(
      apiEndPoints.loginWithMobile,
      JSON.stringify(loginInput),
      // {
      //   headers: {
      //     Authorization: `Bearer ${userData.token}`,
      //   },
      // }
    );
    if (response.status === 200) {
      toast.success(response.data.msg, {
        position: "top-center",
      });
      var decodedPhone = response?.data?.data;
      // Encode the String

      var decodedPhoneA = btoa(decodedPhone);
      navigate(`/otp-verify/${decodedPhoneA}`)
    } else if (response.response.status === 404) {
      toast.error(response.response.data.msg, {
        position: "top-center",
      });
    } else if (response.response.status === 422) {
      toast.error(response.response.data.msg, {
        position: "top-center",
      });
     seterrorList(response?.response?.data?.data)
    }
    else {
      toast.error('something went wrong!', {
        position: "top-center",
      });
    }
  } catch (e) {
    const error = sagaCatchBlockHandling(e);
    toast.error(error?.error, {
      position: "top-right",
    });
  }
};

useEffect(() => {
  if (authenticated) {
    navigate("/");
  }
}, [authenticated]);

useEffect(() => {
  dispatch(Creators.onLoginOTPSuccess({ msg: "", authenticated: false }));
}, [loginInput]);

const { socialList, socialloading } = useSelector((state) => state.socialData);

useEffect(() => {
  dispatch(SocialCreators.getSocialList());
}, []);

useEffect(() => {
  if (error) {
    console.log(error)
    toast?.error(error, { position: "top-center" });
  }
}, [error]);

const OpenHomePage = () => {
  navigate('/')
}

const validatePhoneNumber = (number) => {
  const isValidPhoneNumber = validator.isMobilePhone(number)
  return (isValidPhoneNumber)
}

return (
  <>
    <Toaster />
    <div className="bg-pattern-style" style={{ background: 'url(' + rootConfig?.base_url + socialList?.loginImg + ') no-repeat' }}>
      <div className="content">
        <div className="account-content">
          <div className="account-box">
            <div className="login-right">
              <div className="login-header">
                <h3>
                  Login <span onClick={() => OpenHomePage()} style={{ cursor: "pointer" }}>Serviceora</span>
                </h3>
                <p className="text-muted">Access to our dashboard</p>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label className="form-control-label">Enter Your Registered Mobile Number</label>
                  <input
                    type="text"
                    name="mobile_number"
                    value={loginInput.mobile_number}
                    className="form-control"
                    onChange={handleOnChange}
                    placeholder="Enter Your Mobile Number" />
                  {
                    loginInput?.mobile_number === "" && isEmpty ?
                      null
                      : <ErrorP>{errorPhone?.mobile_number}</ErrorP>
                  }
                  <ErrorP>{errorList.mobile_number}</ErrorP>
                </div>
                <div className="text-start">
                  <Link to="/login" className="forgot-link" style={{ color: '#009da6' }}> Return Back ? </Link>
                </div>

                <Button className="btn btn-primary login-btn" type="submit" loading={loading ? true : false}>Send OTP!</Button>
                <div className="text-center dont-have">
                  Don’t have an account? <Link to="/register"> Register here!</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)
}