import { createReducer } from "reduxsauce";
import { TicketRaiseTypes } from "./action";


const INITIAL_STATE = {
    ticketloading: false,
    ticketRaiseList: [],
    error: ''
}


const getTicketRaiseListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        ticketloading: true
    }
}

const getTicketRaiseListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        ticketloading: false,
        ticketRaiseList: data
    }
}

const getTicketRaiseListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        ticketloading: false
     }
}


const HANDLERS = {
    [TicketRaiseTypes.GET_TICKET_RAISE_LIST_START]: getTicketRaiseListStart,
    [TicketRaiseTypes.GET_TICKET_RAISE_LIST_SUCCESS]: getTicketRaiseListSuccess,
    [TicketRaiseTypes.GET_TICKET_RAISE_LIST_FAILURE]: getTicketRaiseListFailure
}

export const ticketRaiseReducer = createReducer(INITIAL_STATE, HANDLERS)