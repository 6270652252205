import React, { useState } from "react";
import { ErrorP } from "../../../../Common/Modal/Modal";
import { Required } from "../../../Pages/Dashboard/Profile/ProfileSettings";
import { getBase64 } from "../../../../shared/utility/getBase64";
import { XmarkSpan } from "./JobForm";
import { ReactComponent as Xmark } from "../../../../assets/icons/Xmark.svg";
import { useDispatch } from "react-redux";
import { WebService } from "../../../../services/axios/webServices";
import { Toaster, toast } from "react-hot-toast";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Loader from "../../../../Common/Loader/Loader";
import { FlexContainer } from "../../../../globalStyles";
import { ProfileCreators } from "../store";

export const UppdateJob = ({ seletedJob, setIsModal }) => {
  const [input, setInput] = useState({
    job_code: seletedJob?.job_code || "",
    title: seletedJob?.title || "",
    description: seletedJob?.description || "",
    end_date: seletedJob?.end_date || "",
    address: seletedJob?.address || "",
    image: seletedJob?.job_image || [],
  });
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = getUserDataFromLocal();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };
  // console.log(seletedJob, "seletedJob");

  function handleImageChange(e) {
    const { name, value } = e.target;
    getBase64(e.target.files[0]).then((result) => {
      setInput({
        ...input,
        [name]: [...input[name], result],
      });
    });

    e.currentTarget.value = null;
  }

  const removeImage = (item, index) => {
    let newImages = input?.image.filter((ele, i) => index !== i);
    setInput({ ...input, image: newImages });
  };

  const handleUpdateJob = async () => {
    setLoading(true);
    if (
      input?.title !== "" &&
      input?.description !== "" &&
      input?.end_date !== "" &&
      input.address !== ""
    ) {
      try {
        const res = await WebService.post(
          apiEndPoints.updateJob,
          JSON.stringify(input),
          {
            headers: { Authorization: "Bearer " + userData.token },
          }
        );
        // console.log(res, "jobs");
        if (res.status === 200) {
          toast?.success(res?.data?.msg, { position: "top-right" });
          dispatch(ProfileCreators.getJobList({ page: 1 }));
          setLoading(false);
        }
      } catch (err) {
        // console.log(err, "err");
        toast?.err(err, { position: "top-right" });
        setLoading(false);
      }
      setIsModal(null);
    } else {
      setIsEmpty(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateJob();
  };
  return (
    <>
      <Toaster />
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="row form-row">
            <div className="col-12 col-md-12">
              <div className="form-group">
                <label>Job Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="job_code"
                  value={input?.job_code}
                  placeholder="Please Enter Amount"
                />
              </div>
              {input?.job_code === "" && isEmpty ? (
                <ErrorP>this field is required</ErrorP>
              ) : null}
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <label>
                  Title <Required>*</Required>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={input?.title}
                  name="title"
                  placeholder="Please Enter Title"
                  onChange={handleChange}
                />
              </div>
              {input?.title === "" && isEmpty ? (
                <ErrorP>this field is required</ErrorP>
              ) : null}
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group datepicker">
                <label>
                  End Date <Required>*</Required>
                </label>
                <div>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Select date"
                    value={input.end_date}
                    onChange={handleChange}
                    name="end_date"
                  />
                </div>
              </div>
              {/* {input?.end_date === "" ? (
                <ErrorP>this field is required</ErrorP>
              ) : null} */}
            </div>
            <div className="col-12 col-md-12">
              <div className="form-group">
                <label>
                  Address <Required>*</Required>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  value={input?.address}
                  placeholder="Please Enter Address"
                  onChange={handleChange}
                />
              </div>
              {input?.address === "" && isEmpty ? (
                <ErrorP>this field is required</ErrorP>
              ) : null}
            </div>
            <div className="col-12 col-md-10">
              <div className="form-group">
                <label for="formFileLg" className="form-label">
                  Upload Image
                </label>
                <input
                  className="form-control form-control-lg"
                  id="formFileLg"
                  type="file"
                  onChange={handleImageChange}
                  name="image"
                />
              </div>

              <FlexContainer className=" shadow-lg bg-white rounded mb-2 flex-wrap">
                {input?.image.map((ele, index) => (
                  <div style={{ position: "relative" }}>
                    {/* {console.log(ele, "elelelelel")} */}
                    <XmarkSpan
                      className="crossIcon"
                      onClick={() => removeImage(ele, index)}
                    >
                      <Xmark width="25px" height="25px" />
                    </XmarkSpan>
                    <div className="bg-secondary bg-gradient rounded p-1 m-2">
                      {/* <i className="fa-solid fa-xmark"></i> */}
                      {/* <img src={Xmark} /> */}
                      <img
                        src={rootConfig.base_url + ele?.image}
                        width="100px"
                        height="100px"
                      />
                    </div>
                  </div>
                ))}
              </FlexContainer>
            </div>
            <div className="col-12 col-md-12">
              <div className="form-group">
                <label for="description">
                  Description <Required>*</Required>
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  rows="5"
                  name="description"
                  value={input?.description}
                  onChange={handleChange}
                  // id="validationCustomUsername"
                />
                <div className="invalid-tooltip">Please choose a username.</div>
              </div>
              {input?.description === "" && isEmpty ? (
                <ErrorP>this field is required</ErrorP>
              ) : null}
            </div>
          </div>
          <div className="submit-section d-flex justify-content-center">
            <button
              type="submit"
              className="btn btn-primary submit-btn"
              // onClick={handlerFunction}
            >
              {loading ? <Loader /> : "Update"}
            </button>
            <button
              onClick={() => setIsModal(null)}
              className="btn btn-danger submit-btn"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
