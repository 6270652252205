import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Toaster, toast } from "react-hot-toast";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { WebService } from "../../../../services/axios/webServices";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import moment from "moment/moment";
import { Rating } from 'react-simple-star-rating'
import Loader from "../../../../Common/Loader/Loader";
import { Modal } from "react-bootstrap";



const useAuth = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
};
const BusinessProfileRating = (serviceRating) => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [ratingValue, setRatingValue] = useState(0)
  const [input, setInput] = useState({})
  const [jobTitle, setJobTitle] = useState(false)
  const [isRatingModal, setIsRatingModal] = useState(false);
  const [customername, setCustomername] = useState(false)
  const [rating, setRating] = useState({
    sender_id: userData?.userData?.id,
    approve_job_id: '',
    job_id: ''

  })





  const handleRating = (rate) => {
    setRatingValue(rate)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleGiveRating()
  }

  const handleGiveRating = async () => {
    try {
      // console.log(JSON.stringify(input));
      // return false;
      const response = await WebService.post(
        apiEndPoints.giveRating,
        JSON.stringify(input),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        setIsRatingModal(false)
        setInput({})
        navigate(0)
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setIsRatingModal(false)
        setInput({})
        navigate(0)
      }
      else {
        toast.error('something went wrong!', {
          ToastWarning,
          position: "top-center",
        });
        setIsRatingModal(false)
        setInput({})
      }

    } catch (err) {
      const error = sagaCatchBlockHandling(err);
      toast.error("Something Went Wrong!", {
        position: "top-right",
      });

    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value, 'rating': ratingValue, ...rating });
  }

  const Ratingpopup = (approve_job_id, job_id, job_title, customer_name) => {
    if (!userData) {
      navigate("/login");
    } else {
      setJobTitle(job_title)
      setIsRatingModal(true)
      setCustomername(customer_name)
      setRating({
        sender_id: userData?.userData?.id,
        approve_job_id: approve_job_id,
        job_id: job_id,
      })
    }
  }


  return (
    <>
      <Toaster />
      {isRatingModal && (
        <Modal
          show={true}
          size="small"
          onHide={() => setIsRatingModal(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}>{jobTitle}, {customername}</span> Rating</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="row form-row">
                <div className="col-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Rating
                    </label>
                    <Rating onClick={handleRating} initialValue={0} size="30"
                      showTooltip
                      tooltipArray={['Terrible', 'Bad', 'Average', 'Great', 'Prefect']}
                      fillColorArray={['#f17a45', '#f19745', '#f1a545', '#f1b345', '#f1d045']}
                      tooltipStyle={{ backgroundColor: '#009da6' }}
                      name='rating'
                    />
                  </div>

                </div>
                <div className="col-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Review
                    </label>
                    <textarea className="form-control" placeholder="Enter review" name="review" value={input.review} onChange={handleChange}></textarea>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    type="submit"
                    className="btn btn-primary btn-xs"
                  >
                    {loading ? (
                      <Loader width="1.4rem" btn={true} />
                    ) : (
                      "Give Rating"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
      <div className="doc-review review-listing" style={{ overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'nowrap', height: `${serviceRating?.serviceRating?.length < 1 ? `100px` : "300px"}` }}
      >
        <ul className="comments-list">
          {
            serviceRating?.serviceRating?.length < 1 ? 'No Review Found' : serviceRating?.serviceRating?.map((rating, index) => {
              
              return (
                <>
                  {rating?.job_approve_rating?.length < 1 ? '' :
                    <li>
                      <div className="comment">
                        <img className="avatar rounded-circle" alt="User Image" src={rootConfig?.base_url + rating?.job_approve_rating[0]?.user?.profile} />
                        <div className="comment-body">
                          <div className="meta-data">
                            <span className="comment-author text-primary">{rating?.job_approve_rating[0]?.user?.name}</span><Link style={{textDecoration:'underline',}} className="capitalize">{rating?.job_approve_rating[0]?.job?.title}</Link>
                            <span className="comment-date" style={{fontSize:'12px'}}>Reviewed {moment(rating?.job_approve_rating[0]?.created_at).fromNow()}</span>

                          </div>
                          <div className="review-count rating">
                            <Rating readonly initialValue={rating?.job_approve_rating[0]?.rating} size={18} />{rating?.job_approve_rating[0]?.rating}.0
                          </div>
                          <p className="recommended" style={{fontSize:'12px'}}><i className="far fa-thumbs-up"></i> I recommend the review</p>
                          <p className="comment-content">
                            {rating?.job_approve_rating[0]?.review}
                          </p>
                          <div className="comment-reply">
                            {
                              rating?.service_id === userData?.userData?.id
                                ?
                                <Link className="comment-btn" onClick={() => Ratingpopup(rating?.job_approve_rating[0]?.approve_job_id, rating?.job_approve_rating[0]?.job?.id, rating?.job_approve_rating[0]?.job?.title, rating?.job_approve_rating[0]?.user?.name)}>
                                  <i className="fas fa-reply"></i> Reply
                                </Link>
                                :
                                <Link className="comment-btn">
                                  <i className="fas fa-reply"></i> Reply
                                </Link>
                            }

                            <p className="recommend-btn">
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* {rating?.job_approve_rating.length} */}
                      {rating?.job_approve_rating.length == 2 ?
                        <ul className="comments-reply">
                          <li>
                            <div className="comment">
                              <img className="avatar rounded-circle" alt={rating?.job_approve_rating[1]?.user?.name} src={rootConfig?.base_url + rating?.job_approve_rating[1]?.user?.profile} />
                              <div className="comment-body">
                                <div className="meta-data">
                                  <span className="comment-author text-primary">{rating?.job_approve_rating[1]?.user?.name}</span>
                                  <span className="comment-date" style={{fontSize:'12px'}}>Reviewed {moment(rating?.job_approve_rating[1]?.created_at).fromNow()}</span>
                                </div>
                                <div className="review-count rating">
                                  <Rating readonly initialValue={rating?.job_approve_rating[1]?.rating} size={18} />{rating?.job_approve_rating[1]?.rating}.0
                                </div>
                                <p className="comment-content">
                                  {rating?.job_approve_rating[1]?.review}
                                </p>
                                <div className="comment-reply">

                                </div>
                              </div>
                            </div>
                          </li>

                        </ul>
                        : ''
                      }
                    </li>
                  }
                </>
              )
            })
          }


        </ul>
      </div>
    </>
  )
}


export default BusinessProfileRating