import { createReducer } from "reduxsauce";
import { Types } from "./action";

const INITIAL_STATE = {
  profileData: [],
  loading: false,
  catagories: [],
  categoryLoading: false,
  dashboardCat: [],
  dashboardLoading: false,
  jobList: [],
  jobLoading: false,
  metaCount: "",
  ApprovedJobList: [],
  ApprovedJobListLoading: false,
};

const getProfileStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const getProfileSuccess = (state = INITIAL_STATE, action) => {
  // console.log(action.payload, "getProfileSuccess");

  return {
    ...state,
    loading: false,
    profileData: [action.payload],
  };
};

const getProfileFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const getCategoriesStart = (state = INITIAL_STATE) => ({
  ...state,
  categoryLoading: true,
});

const getCategoriesSuccess = (state = INITIAL_STATE, action) => {
  // console.log(action.payload, "=====");
  return {
    ...state,
    categoryLoading: false,
    catagories: [...action.payload],
  };
};

const getCategoriesFailure = (state = INITIAL_STATE) => ({
  ...state,
  categoryLoading: false,
});

const getDashCategoriesStart = (state = INITIAL_STATE) => ({
  ...state,
  dashboardLoading: true,
});

const getDashCategoriesSuccess = (state = INITIAL_STATE, action) => {
  
  return {
    ...state,
    dashboardLoading: false,
    dashboardCat: [...action.payload],
  };
};

const getDashCategoriesFailure = (state = INITIAL_STATE) => ({
  ...state,
  dashboardLoading: false,
});

const getJobListStart = (state = INITIAL_STATE) => ({
  ...state,
  jobLoading: true,
});

const getJobListSuccess = (state = INITIAL_STATE, action) => {
  const { data, count } = action.payload;

  return {
    ...state,
    jobLoading: false,
    jobList: [...data],
    metaCount: count,
  };
};

const getJobListFailure = (state = INITIAL_STATE) => ({
  ...state,
  jobLoading: false,
});

const getApprovedJobListStart = (state = INITIAL_STATE, action) => ({
  ...state,
  ApprovedJobListLoading: true,
});

const getApprovedJobListSuccess = (state = INITIAL_STATE, action) => {
  const { data, message } = action.payload
  // console.log(data, 'test')
  return {
      ...state,
      ApprovedJobListLoading: false,
      ApprovedJobList: data
  }
}

const getApprovedJobListFailure = (state = INITIAL_STATE, action) => {
  return { 
      ...state,
      ApprovedJobListLoading: false
   }
}

const HANDLERS = {
  [Types.GET_PROFILE_START]: getProfileStart,
  [Types.GET_PROFILE_SUCCESS]: getProfileSuccess,
  [Types.GET_PROFILE_FAILURE]: getProfileFailure,
  [Types.GET_CATEGORIES_START]: getCategoriesStart,
  [Types.GET_CATEGORIES_SUCCESS]: getCategoriesSuccess,
  [Types.GET_CATEGORIES_FAILURE]: getCategoriesFailure,
  [Types.GET_DASH_CATEGORIES_START]: getDashCategoriesStart,
  [Types.GET_DASH_CATEGORIES_SUCCESS]: getDashCategoriesSuccess,
  [Types.GET_DASH_CATEGORIES_FAILURE]: getDashCategoriesFailure,
  [Types.GET_JOB_LIST_START]: getJobListStart,
  [Types.GET_JOB_LIST_SUCCESS]: getJobListSuccess,
  [Types.GET_JOB_LIST_FAILURE]: getJobListFailure,
  [Types.GET_APPROVED_JOB_LIST_START]: getApprovedJobListStart,
  [Types.GET_APPROVED_JOB_LIST_SUCCESS]: getApprovedJobListSuccess,
  [Types.GET_APPROVED_JOB_LIST_FAILURE]: getApprovedJobListFailure,
};

export const profileReducer = createReducer(INITIAL_STATE, HANDLERS);
