import React, { useCallback, useRef, useState } from "react";
import { Span } from "../../globalStyles";
import { sampleColumns } from "../../store/utility/constants";
import Loader from "../Loader/Loader";
import { Tr, Th, Td, Table } from "./Table.styles";
// import SortIcon from '../../assets/icons/Sort.svg';
import styled from "styled-components";

const TableComp = (props) => {
  const { columns = sampleColumns, loading, data, handleNext, error } = props;
  const [activeSortCol, setActiveSortCol] = useState("company");
  const [isAsc, setIsAsc] = useState(true);

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      handleNext({
        sortBy: activeSortCol,
        isAsc,
      });
    }
  };

  const observer = useRef();
  const loader = useCallback(
    (node) => {
      if (loading || error) return;
      const option = {
        root: null,
        rootMargin: "300px",
        threshold: 1.0,
      };
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(handleObserver, option);
      if (node) observer.current.observe(node);
    },
    [handleNext, loading, handleObserver]
  );
  const getValue = (val, isTrueLabel, isFalseLabel, column) => {
    const { key, type, unit } = column;
    let value = "";
    // parsing the value
    if (typeof val === "boolean") {
      value = val ? isTrueLabel : isFalseLabel;
    } else if (type === "number") {
      let fixTo = 1;
      if (key === "price") {
        fixTo = 2;
      } else if (key === "totalRevenue" || key === "grossProfits") {
        fixTo = 2;
      }
      value = val ? Number(val)?.toFixed(fixTo) : "N/A";
    } else {
      value = val || "N/A";
    }
    // adding unit
    if (value !== "N/A" && unit === "$") {
      value = `$${value}`;
    } else if (value !== "N/A" && unit === "%") {
      value = `${value}%`;
    } else if (value !== "N/A" && (unit === "M" || unit === "B")) {
      value = `$ ${value}${unit}`;
    }
    return value;
  };
  const handleSort = (col) => {
    let isAscLocal = isAsc;
    if (col === activeSortCol) {
      // toggle the icon
      isAscLocal = !isAsc;
    } else {
      isAscLocal = true;
    }
    setIsAsc(isAscLocal);
    setActiveSortCol(col);
    handleNext({
      pageNoParam: 0,
      sortBy: col,
      isAsc: isAscLocal,
    });
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row col-sm-12 d-flex justify-content-center">
            <div className="col-sm-10 ">
              <div className="card">
                <table className="table table-striped">
                  {/* row header */}
                  <thead>
                    <tr>
                      {columns.map((column) => {
                        const { align, key, label, id, isSortable } = column;
                        // console.log('id', id, key);
                        return (
                          <>
                          <th
                            id={id}
                            key={id}
                            // className={`zebra_strip ${align}`}
                            onClick={() => handleSort(key)}
                            style={{ cursor: "pointer" }}
                            scope="col"
                          >
                            {label}
                            <span>
                              {isSortable ? (
                                <Image
                                  // src={activeSortCol === key ? SortIcon : SortIcon}
                                  src={""}
                                  width="11px"
                                  height="6px"
                                  className={`${
                                    activeSortCol === key && isAsc
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                />
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                          </>
                        );
                      })}
                    </tr>
                  </thead>
                  {/* data rows  */}
                  <tbody>
                    <>
                    {Object.values(data || {}).length ? (
                      Object.values(data || {}).map((item, index) => {
                        // console.log('id', item.id, item.key);
                        return (
                          <tr key={item.id} scope="row">
                            {columns.map((column) => {
                              const { key, isTrue, isFalse, align } = column;
                              return (
                                <td
                                  key={key}
                                  className={`zebra_strip ${align} ${
                                    activeSortCol === key ? "enable" : ""
                                  }`}
                                  id={key}
                                >
                                  {getValue(item?.[key], isTrue, isFalse, column)}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    ) : (
                      <>
                        {(!loading || error) && (
                          <tr className="noHover">
                            <td colSpan={columns?.length}>
                              <Span>{error?.message || "No Items Found"}</Span>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                    {loading && (
                      <>
                      <tr className="noHover">
                        <td colSpan={columns?.length}>
                          <Loader />
                        </td>
                      </tr>
                      </>
                    )}
                    </>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={loader}></div>
    </>
  );
};

export default TableComp;

export const Image = styled.img`
  &.rotate-180 {
    transform: rotate(-180deg);
  }
`;
