import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getRecentJob: ["payload"],
  getRecentJobStart: ["payload"],
  getRecentJobSuccess: ["payload"],
  getRecentJobFailure: ["payload"],
  getFavJob: ["payload"],
  getFavJobStart: ["payload"],
  getFavJobSuccess: ["payload"],
  getFavJobFailure: ["payload"],
  getMyJob: ["payload"],
  getMyJobStart: ["payload"],
  getMyJobSuccess: ["payload"],
  getMyJobFailure: ["payload"],
  removeFavJob: ['payload']
});

export { Types, Creators };
