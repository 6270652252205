import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { FlexContainer } from "../../../../globalStyles";
import SearchableDropdown from "../../../../Common/Input/SearchInput";
import Loader from "../../../../Common/Loader/Loader";
// import ModalComponent, { Modal } from "../../Common/Modal/Modal";
import ModalComponent from "../../../../Common/Modal/Modal";
import { SideBar } from "../SideBar";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { ProfileCreators } from "../../Dashboard/Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { styled } from "styled-components";
import { ErrorP } from "../../../../Common/Modal/Modal";
import Button from 'react-bootstrap-button-loader';
import AppHelmet from "../../Helmet/Helmet";


const ChangePassword = () => {
  const naviagate = useNavigate();
  const userData = getUserDataFromLocal();
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState([]);
  const [errorList, seterrorList] = useState([])

  const { profileData } = useSelector((state) => state.profileData);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleChangePassword()
}

const handleChangePassword = async() => {
    try{

        setLoading(true)
        const res = await WebService.post(
            apiEndPoints.ChangePassword,
            JSON.stringify(inputData),
            {
                headers: {
                  Authorization: `Bearer ${userData.token}`,
                },
              }
        );
        // console.log('res', res);
        if (res.status === 200) {
            toast.success(res.data.msg, {
            ToastSuccess,
            position: "top-center",
            duration: 6000
            });
            setLoading(false)
            seterrorList([]);
            setTimeout(() => {
                naviagate("/login");
            }, 3000)
        } else if (res.response.status === 422) {
            // console.log(res.response.data.data)
            toast.error(res.response.data.msg, {
            ToastWarning,
            position: "top-center",
            });
            setLoading(false)
            seterrorList(res.response.data.data);
        } else if (res.response.status === 404) {
            toast.error(res.response.data.msg, {
            ToastWarning,
            position: "top-center",
            });
            setLoading(false)
        } else {
            toast.error("something went wrong.", {
            ToastWarning,
            position: "top-right",
            });
            setLoading(false)
        }
    }
    catch(e)
    {
        const { error } = sagaCatchBlockHandling(e);
        setLoading(false)
        toast.error("Something Went Wrong!", {
          position: "top-right",
        });
    }
}
  return (
    <>
      <Toaster />
      <AppHelmet title="Change Password - Secure Your Serviceora Account" description="Easily update your password on Serviceora to enhance account security. Protect your profile by changing your password regularly and keeping your information safe." keywords="change password Serviceora, update password, secure account, password reset, account security, Serviceora login, protect account, change login credentials" />
      
        <div className="main-wrapper">
          <div className="breadcrumb-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <Link to="/user/change-password">Change Password</Link>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="breadcrumb-title">Change Password</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <SideBar profileData={profileData} />
                <div className="col-md-7 col-lg-8 col-xl-9">
                  <div className="row">
                    <div className="card new-comment clearfix">
                      <div className="card-header">
                        <h4 className="card-title">Change Password</h4>
                      </div>
                      <div className="card-body">
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label>
                              Current Password <span className="text-danger">*</span>
                            </label>
                            <input type="password" name="current_password" value={inputData.current_password} className="form-control" onChange={handleOnChange} autoComplete="off"/>
                            <ErrorP>{errorList.current_password}</ErrorP>
                          </div>
                          <div className="form-group">
                            <label>
                             New Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input type="password" name="new_password" value={inputData.new_password} className="form-control" onChange={handleOnChange} autoComplete="off" />
                            <ErrorP>{errorList.new_password}</ErrorP>
                          </div>
                          <div className="form-group">
                            <label>
                             New Password Confirmation{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input type="password" name="new_password_confirmation"value={inputData.password_confirmation} className="form-control"  onChange={handleOnChange} autoComplete="off" />
                            <ErrorP>{errorList.new_password_confirmation}</ErrorP>
                          </div>
                          <div className="submit-section">
                            {/* <button
                              className="btn btn-primary submit-btn"
                              type="submit"
                            >
                              {loading ? <Loader /> : "Change Password"}
                            </button> */}
                            <Button className="btn btn-sm btn-primary submit-btn" type="submit" loading={loading ? true : false}>Change Password!</Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default ChangePassword;

export const Required = styled.span`
  color: red;
`;
