import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import { getUserDataFromLocal } from "../../../shared/utility/helper";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import ModalComponent from "../../../Common/Modal/Modal";
import toast, { Toaster } from "react-hot-toast";
import { WebService } from "../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../services/axios/endPoints";
import { sagaCatchBlockHandling } from "../../../store/utility/sagaErrorHandling";
import { Modal } from "react-bootstrap";
import TableComp from "../../../Common/Table/Table.view";
import { bidColumns } from "../../../Common/Data";
import { Table } from "../../../Common/Table/Table2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { styled } from "styled-components";
import { Rating } from 'react-simple-star-rating'
import bidIcon from "../../../assets/img/logo/bidding.png";
import copy from 'copy-to-clipboard';
import callBack from '../../../assets/img/callback.png'
import Loader from "../../../Common/Loader/Loader";
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap-button-loader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ListGroup from 'react-bootstrap/ListGroup';
import { CoinsCreators } from "../Dashboard/Coins/store";





const RecentJob = (props) => {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);
  const [bidInput, setBidInput] = useState({
    job_id: "",
    amount: "",
    amount_sign: "",
    duration: "",
    duration_key: "",
    message: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRequestLoading, setRequestLoading] = useState(false);
  const [bidData, setBidData] = useState([]);
  const [jobTitle, setJobTitle] = useState(false)
  const [errorList, setErrorList] = useState([]);
  const [isRequestCallBackModal, setIsRequestCallBackModal] = useState(false)
  const { data } = props;
  const [isRequestInput, setIsRequestInput] = useState({})
  const [bidloading, setbidloding] = useState(false)
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();


  const { coinsList, coinsloading } = useSelector((state) => state.coinsData);
  

  const checkerFunction = (id, title) => {
    if (!userData) {
      navigate("/login");
    } else {
      setJobTitle(title)
      setIsModalVisible(true);
      setBidInput({ ...bidInput, job_id: id });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBidInput({ ...bidInput, [name]: value });
  };

  const placeBidSuccess = async() => {
    try {
      setbidloding(true)
      const response = await WebService.post(
        apiEndPoints.jobbidding,
        JSON.stringify(bidInput),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        dispatch(CoinsCreators.getCoinsList());
        setbidloding(false)
        setBidInput([]);
        setIsModalVisible(false);
        
      }
      else if (response.response.status === 422) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        setbidloding(false)
        setErrorList(response.response.data.data);
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        setbidloding(false)
        setBidInput([]);
        setIsModalVisible(false);
      } else {
        toast.error("something went wrong.", {
          position: "top-right",
        });
        setbidloding(false)
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error("Something Went Wrong!", {
        position: "top-right",
      });
      setbidloding(false)
    }
  }
  
  

  const placeBid =  (e) => {
    e.preventDefault();
    confirmAlert({
      title: 'Confirm to submit',
      message: <ul>
                    <strong>Are you sure you want to make a bid?</strong>
                    <li><strong>Free Bids: </strong> Enjoy 3 free bids every day to participate in our platform.</li>
                    <li><strong>Remaining Free Bids: </strong> {props?.today_bids >= 3 ? "you've used them all free bids." :  `You have ${3 - props?.today_bids} free bids remaining for today.`}</li>
                    <li><strong>Additional Bids: </strong>After using your free bids, there's a small charge of 5 coins for each extra bid.</li>
                    <li><strong>Your Coin Balance:</strong> You currently have {props?.coins} coins in your account.</li>
              </ul>,
      buttons: [
        {
          label: 'Yes',
          onClick: () => placeBidSuccess()
        },
        {
          label: 'No',
          onClick: () => alert('Please try again.')
        }
      ]
    });
    
    
  };

  const handleSetShowBid = (id) => {
    if (!userData) {
      navigate("/login");
    } else {
      handleShowBid(id);
    }

  }
  const handleShowBid = async (id) => {
    if (!userData) {
      navigate("/login");
    }
    const config = {
      headers: {
        Authorization: `Bearer ${userData.token}`,
      },
      params: {
        job_id: id,
      },
    };
    setLoading(true);
    setIsModal(true);
    try {
      const response = await WebService.get(apiEndPoints.jobBid, config);
      const { data } = response;
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        setJobTitle(data.data.title)
        setBidData(data.data.job_bid);
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        setIsModalVisible(false);
      } else {
        toast.error('something went wrong!', {
          position: "top-center",
        });
      }


    } catch (err) {
      toast.error(err, {
        position: "top-right",
      });
    }
  };

  const handleSetaddToFav = (item) => {
    if (!userData) {
      navigate("/login");
    } else {
      addToFav(item);
    }

  }

  const addToFav = async (ele) => {
    try {
      const response = await WebService.post(
        apiEndPoints.favJobCreate,
        JSON.stringify({ job_id: ele.id }),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
      }
      else {
        toast.error('something went wrong!', {
          position: "top-center",
        });
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }
  };

  const approveBidSuccess = async(job_id, bid_id) => {
    try {
      const response = await WebService.post(
        apiEndPoints.approvedBid,
        JSON.stringify({ job_id: job_id, bid_id: bid_id }),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          }
        }
      )
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        setIsModalVisible(false);
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        
        setIsModalVisible(false);
      } else if (response.response.status === 429) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        
        setIsModalVisible(false);
      } else {
        toast.error("something went wrong.", {
          position: "top-right",
        });
      }
    }
    catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }

  }

  const approveBid = async (job_id, bid_id) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: <div>
                <strong>Are you sure you want to accept a bid?</strong>
          </div>,
      buttons: [
        {
          label: 'Yes',
          onClick: () => approveBidSuccess(job_id, bid_id)
        },
        {
          label: 'No',
          onClick: () => alert('Please try again.')
        }
      ]
    });
    
  }

  const getValueStatus = (value) => {
    if (value === 'Processing') {
      return (
        <>
          <span className="badge bg-primary-light">
            <i className="far fa-circle"></i> {value}
          </span>
        </>
      )
    } else if (value === 'Active') {
      return (
        <>
          <span className="badge bg-success-light">
            <i className="fas fa-check"></i> {value}
          </span >
        </>
      )
    } else if (value === 'Approved') {
      return (
        <>
          <span className="badge bg-info-light">
            <i className="far fa-circle"></i> {value}
          </span>
        </>
      )
    } else if (value === 'Cancel') {
      return (
        <>
          <span className="badge bg-warning-light">
            <i className="fas fa-times"></i> {value}
          </span>
        </>
      )
    } else if (value === 'Completed') {
      return (
        <>
          <span className="badge bg-danger-light">
            <i className="fas fa-ban"></i> {value}
          </span>
        </>
      )
    }
    return value;
  }
  // useEffect(() => {
  //   dispatch(LandingPageCreators.getRecentJob());


  // }, []);

  const handleCopyJob = async (job_code) => {
    await copy(`${rootConfig?.app_url}/jobs?code=${job_code}`);
    toast.success('The job copied successfully', {
      position: "top-center",
    });

    // if ("clipboard" in navigator) {
    //   await navigator.clipboard.writeText(`${rootConfig?.app_url}/jobs?code=${job_code}`);
    //   toast.success('The job url copied successfully', {
    //     position: "top-center",
    //   });
    // } else {
    //   document.execCommand("copy", true, `${rootConfig?.app_url}/jobs?code=${job_code}`);
    //   toast.success('The job url copied successfully', {
    //     position: "top-center",
    //   });
    // }
  }

  

  const handleRequestCallBack = async(job_code, jobTitle) => {
    setIsRequestCallBackModal(true)
    setJobTitle(jobTitle)
    setIsRequestInput({job_code: job_code})
  }

  const handleSubmitRequestCallbackAction = (e) => {
    e.preventDefault()
    handleSubmitRequestCallBackActionStatus()
  }
  const handleSubmitRequestCallBackActionStatus = async () => {
    try {
      setRequestLoading(true)
      const response = await WebService.post(
        apiEndPoints.requestACallBack,
        JSON.stringify({ ...isRequestInput}),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      // console.log('response', response)
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        setRequestLoading(false)
        setIsRequestCallBackModal(false)
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        setRequestLoading(false)
        setIsRequestCallBackModal(false)
      }
      else {
        toast.error('something went wrong!', {
          position: "top-center",
        });
        setIsRequestCallBackModal(false)
        setRequestLoading(false)
      }

    } catch (err) {
      const error = sagaCatchBlockHandling(err);
      toast.error("Something Went Wrong!", {
        position: "top-right",
      });

    }
  }


  return (
    <>
      <ModalComponent
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        title={jobTitle}
        isForm={true}
        setBidInput={setBidInput}
        bidInput={bidInput}
        handleChange={handleChange}
        handlerFunction={placeBid}
        error={isEmpty}
        setErrorList={setErrorList}
        errorList={errorList}
        bidloading={bidloading}
      />
      {isModal && (
        <Modal
          show={true}
          size="lg"
          onHide={() => setIsModal(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}>{jobTitle}</span> View Bids</Modal.Title>
          </Modal.Header>
          <Table columns={bidColumns} data={bidData} userData={userData} approveBid={approveBid} />
        </Modal>
      )}
      {isRequestCallBackModal && (
        <Modal
          show={true}
          size="lg"
          onHide={() => setIsRequestCallBackModal(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}>{jobTitle}</span> Request a call back?</Modal.Title>
          </Modal.Header>
            <Modal.Body>
              <h5>Free Expert Opinion at Your Convenience</h5>
              <p>Are you seeking expert guidance or advice for your needs, Our "Request a Callback" service is here to assist you.</p>
              <h5>What We Offer:</h5>
              <ul>
                  <li style={{listStyleType:'none'}}><i className="fa-solid fa-check-double"></i><strong> Free Expert Opinions: </strong> Our experienced team is ready to provide you with valuable insights and advice tailored to your unique requirements.</li>
                  <li style={{listStyleType:'none'}}><i className="fa-solid fa-check-double"></i><strong> Convenient Scheduling: </strong> You choose that suits you best, and our friendly customer executive will call you.</li>
                  <li style={{listStyleType:'none'}}><i className="fa-solid fa-check-double"></i><strong> No Obligation: </strong> There's no cost or commitment required. It's completely free!</li>
              </ul>
              <p>Take the first step toward getting the information you need. Request a callback now, and our customer executive will be happy to assist you.</p>
              <form onSubmit={handleSubmitRequestCallbackAction}>
                
                <Button className="btn btn-primary" type="submit" loading={isRequestLoading ? true : false}>Submit Your Request!</Button>
              </form>
            </Modal.Body>
          
        </Modal>
      )}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-8 col-xl-12">
              {data?.length < 1 ?
                
                  <div className="card">
                    <div className="card-body">
                      <div className="mentor-widget">
                        <div className="user-info-left">
                          <div className="user-info-cont">
                            <h4 className="usr-name capitalize">
                                No Job Found!
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 : data?.map((item, index) => {
                  
                  return (
                    <div key={index} className="card recent-job" data-aos="zoom-in" data-aos-delay="100">
                      <div className="card-body">
                        <div className="mentor-widget">
                          <div className="user-info-left">

                            <div className="avatar avatar-xl mentor-img" >
                              <img className="avatar-img rounded-circle" style={{ height: "100px" }} alt={item?.job_user?.name} src={rootConfig.base_url + item?.job_user?.profile} />
                            </div>
                            <div className="user-info-cont">
                              <h6 className="usr-name capitalize">
                                <p><Link to={`${props.auth ? `/jobs/${item?.job_code}` : "/login"}`}>{item?.title} {getValueStatus(item?.status)}</Link></p>
                              </h6>
                              <Link to={`${props.auth ? `/business/${item?.job_user?.user_name}` : "/login"}`}>
                                <span className="capitalize" style={{ color: "#22A699" }}>{item?.job_user?.name}</span>
                              </Link> <span className="badge badge-info">{item?.job_user?.type !== 'Customer' ? 'Service Provider' : 'Customer'}</span>
                              <p className="mentor-type ">
                                {item?.description?.substring(0, 130)}... <Link to={`/jobs/${item.job_code}`}>Read More</Link>
                              </p>
                              <div className="avatar-group">
                                {
                                  item?.job_image?.map((imgitem, index) => {
                                    return (
                                      <div key={index} className="avatar">
                                        <img className="avatar-img rounded-circle border border-white" alt={imgitem?.image} src={rootConfig.base_url + imgitem?.image} />
                                      </div>
                                    )
                                  })
                                }
                              </div>

                              <ul className="tags">
                                <li className="capitalize" style={{ marginLeft: "10px" }}><Link to="#" className="tag"><img src={rootConfig.base_url + item?.job_sub_category?.image} height="20px" width="20px" /> {item?.job_sub_category?.name}</Link></li>
                                <li className="capitalize"><Link to="#" className="tag" ><img src={rootConfig.base_url + item?.job_category?.image} height="20px" width="20px" /> {item?.job_category?.name}</Link></li>
                              </ul>
                            </div>
                          </div>
                          <div className="user-info-right">
                            <div className="user-infos">
                              <ul>
                                {
                                  userData?.userData?.id === item?.user_id ? 
                                  <li>
                                      <img src={callBack} width="25px" />
                                      <Link onClick={() => handleRequestCallBack(item?.job_code, item?.title)}><span style={{ color: '#ff9c27' }}> Request a call back?</span></Link>
                                  </li>
                                  : ''
                                }
                                
                                <li>
                                  <i className="far fa-clock"></i>
                                  <span style={{ color: 'rgb(34, 166, 153)' }}>Published</span> {moment(item?.created_at).fromNow()}
                                </li>
                                <li>
                                  <i className="fas fa-clock"></i>
                                  <span style={{ color: 'red' }}>Valid till</span> {moment(item?.end_date).format(" Do MMMM, YYYY")}
                                </li>
                                <li className="capitalize">
                                  <i
                                    className="fas fa-map-marker-alt"
                                    aria-hidden="true"
                                  ></i>{item?.job_country?.emoji} {item?.job_city?.name}, {item?.job_state?.name}, {item?.job_country?.iso2}
                                </li>
                                {/* <li> */}
                                {/* <i className="far fa-bidding-alt" aria-hidden="true"></i> */}
                                {/* <img src={bidIcon} height="20px" width="20px" /> {item?.job_bid_count} */}
                                {/* </li> */}
                              </ul>
                            </div>
                            <div className="mentor-booking">
                              <IconSpan onClick={() => checkerFunction(item?.id, item?.title)} style={{ width: 'auto' }}>
                                <span className="badge bg-success">
                                  <i className="fa fa-stamp"></i> Bid Now <strong>({item?.job_bid_count})</strong>
                                </span>
                                {/* <i className="fa-solid fa-person-circle-question" size="lg" style={{ fontSize: '20px' }}></i> */}
                              </IconSpan>
                              <IconSpan
                                onClick={() => handleSetShowBid(item?.id)}
                                style={{ width: 'auto' }}
                              >
                                <span className="badge bg-warning">
                                  <i className="fa fa-eye"></i> View Bid
                                </span>

                              </IconSpan>
                              <IconSpan onClick={() => handleSetaddToFav(item)}>

                                <span className="badge bg-secondary" title="Add to favourite">
                                  <i className="fa fa-heart"></i>
                                </span>
                              </IconSpan>
                              <IconSpan onClick={() => handleCopyJob(item?.job_code)}>
                                <span className="badge bg-info" title="Copy job url" >
                                  <i className="fa fa-copy"></i> Copy
                                </span>
                              </IconSpan>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecentJob;

export const IconSpan = styled.span`
  padding: 2px;

  // margin: 0px 5px 0px 5px;
  // background: #eaeaea;
  cursor: pointer;
  border: 1px solid white;
  // border-radius: 5px;
  // height: 30px;
  // width: 50px;
  // display: inline-block;
  text-align: center;
`;
