import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { JobInvoiceCreators } from "./store";
import { FlexContainer } from "../../../../globalStyles";
import SearchableDropdown from "../../../../Common/Input/SearchInput";
import Loader from "../../../../Common/Loader/Loader";
// import ModalComponent, { Modal } from "../../Common/Modal/Modal";
import ModalComponent from "../../../../Common/Modal/Modal";
import { SideBar } from "../SideBar";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { ProfileCreators } from "../../Dashboard/Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";

import moment from "moment";
export const JobInvoice = () => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { loading, jobInvoiceList } = useSelector((state) => state.jobInvoiceData);
//   console.log('job invoice',jobInvoiceList)

  useEffect(() => {
    dispatch(JobInvoiceCreators.getJobInvoiceList());
  }, []);
  const { profileData } = useSelector((state) => state.profileData);
  // console.log(userData, "testing");

    const updateStatus = async (ele) => {
          
        try {
            const response = await WebService.post(
              apiEndPoints.publishing,
              JSON.stringify({ slug: ele }),
              {
                headers: {
                  Authorization: `Bearer ${userData.token}`,
                },
              }
            );
            if(response.status === 200)
              {
                toast.success(response.data.msg, {
                  ToastSuccess,
                  position: "top-center",
                });
              } else if(response.response.status === 404) {
                toast.error(response.response.data.msg, {
                  ToastWarning,
                  position: "top-center",
                });
              }
              else{
                toast.error('something went wrong!', {
                  ToastWarning,
                  position: "top-center",
                });
              }
          } catch (e) {
            const error = sagaCatchBlockHandling(e);
            toast.error(error?.error, {
              position: "top-right",
            });
          }
    }

    

    

  return (
    <>
    <Toaster />
      {/* <ModalComponent /> */}
      {loading ? (
        <Loader containerHeight={70} width="4rem" />
      ) : (
        <div className="main-wrapper">
          <div className="breadcrumb-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <Link to="/user/jobs/invoices">Orders</Link>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="breadcrumb-title">Orders</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <SideBar profileData={profileData} />
                <div className="col-md-7 col-lg-8 col-xl-9">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="mb-4">Orders</h4>
                      <div className="card card-table">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Sr. no</th>
                                  <th>Order No.</th>
                                  <th>Title</th>
                                  <th>CREATED DATE</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {jobInvoiceList?.length < 1 ? <>
                                <tr>
                                  <td>No job Found!</td>
                                </tr>
                                </> : jobInvoiceList?.map(
                                  (jobInvoice, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>{jobInvoice?.order_no}</td>
                                        {
                                          <td>
                                            <h2 className="table-avatar">
                                              <Link
                                                to="#"
                                                className="capitalize"
                                              >
                                                {jobInvoice?.job?.title}
                                                <span>
                                                  <span
                                                    style={{
                                                      textTransform:
                                                        "lowercase",
                                                    }}
                                                  >
                                                  </span>
                                                </span>
                                                <ul className="tags">
                                                    <li className="capitalize"><Link to="#" className="tag"><img src={rootConfig.base_url+jobInvoice?.job?.job_sub_category?.image} height="20px" width="20px"  /> {jobInvoice?.job?.job_sub_category?.name}</Link></li>
                                                    <li className="capitalize"><Link to="#" className="tag" ><img src={rootConfig.base_url+jobInvoice?.job?.job_category?.image} height="20px" width="20px"  /> {jobInvoice?.job?.job_category?.name}</Link></li>
                                                </ul>
                                              </Link>
                                            </h2>
                                          </td>
                                        }
                                        <td>
                                          {moment(
                                            jobInvoice?.created_at
                                          ).fromNow()}
                                        </td>
                                        <td className="text-left">
                                            {jobInvoice?.status === 'Pending' ? 
                                          <span className="badge bg-secondary">{jobInvoice?.status}</span>
                                        :  jobInvoice?.status === 'Paid' ? 
                                          <span className="badge rounded-pill badge-info">{jobInvoice?.status}</span>
                                        :  jobInvoice?.status === 'Failed' ? 
                                          <span className="badge rounded-pill badge-success">{jobInvoice?.status}</span>
                                        : ''}
                                        </td>
                                        <td className="text-left">
                                        <Link  to={`/user/job/order/${jobInvoice?.id}/show/${jobInvoice?.order_no}`} className={`badge btn-sm bg-info-light`}>
                                            <i className="far fa-check-circle"></i> Show Invoice
                                        </Link>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
