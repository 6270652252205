import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../Common/Loader/Loader";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { ErrorP } from "../../../../Common/Modal/Modal";
import serviceorasvg from "../../../../assets/img/logo/serviceora.png";
import { SocialCreators } from "../Social/store";
import Button from 'react-bootstrap-button-loader';

const ForgetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [errorList, seterrorList] = useState([])
  const [forgetInput, setForgetInput] = useState({ email: "", password: "" });


  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setForgetInput({ ...forgetInput, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleForgetPassword()
  }

  const { socialList, socialloading } = useSelector((state) => state.socialData);

  useEffect(() => {
    dispatch(SocialCreators.getSocialList());
  }, []);

  const handleForgetPassword = async () => {
    try {
      setLoading(true)
      const res = await WebService.post(
        apiEndPoints.ForgetPassword,
        JSON.stringify(forgetInput)
      );
      if (res.status === 200) {
        toast.success(res.data.msg, {
          ToastSuccess,
          position: "top-center",
          duration: 6000
        });
        setLoading(false)
        seterrorList([]);
      } else if (res.response.status === 422) {
        toast.error(res.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setLoading(false)
        seterrorList(res.response.data.data);
      } else if (res.response.status === 404) {
        toast.error(res.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setLoading(false)
      } else {
        toast.error("something went wrong.", {
          ToastWarning,
          position: "top-right",
        });
        setLoading(false)
      }
    }
    catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      setLoading(false)
    }
  }

  const OpenHomePage = () => {
    navigate('/')
  }

  return (
    <>
      <Toaster />
      <div className="bg-pattern-style"  style={{background: 'url('+rootConfig?.base_url+socialList?.forgotImg+') no-repeat'}}>
        <div className="content">
          <div className="account-content">
            <div className="account-box">
              <div className="login-right">
                <div className="login-header">
                {/* <Link to='/'><img src={serviceorasvg} className="img-fluid" alt="Serviceora" style={{ maxHeight: "30px" }} /></Link> */}
                  <h3>
                    Forgot Password <span onClick={() => OpenHomePage()} style={{ cursor: "pointer" }}>Serviceora</span>
                  </h3>
                  <p className="text-muted">Enter your email to get a password reset link: </p>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="form-control-label">Email Address</label>
                    <input
                      type="text"
                      name="email"
                      value={forgetInput.email}
                      className="form-control"
                      onChange={handleOnChange}
                      placeholder="Your Email address"
                    />
                    <ErrorP>{errorList.email}</ErrorP>
                  </div>
                  <div className="text-end">
                    <Link className="forgot-link" to="/login">Remember your password?</Link>
                  </div>
                  <Button className="btn btn-primary login-btn" type="submit" loading={loading ? true : false}>Reset Password!</Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgetPassword