import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Header from "../Header/Header"
import { useNavigate, Link } from "react-router-dom";
import './style.css'
import { Footer } from "../Footer"
import { Creators } from "../../../auth/store";
import { useDispatch, useSelector } from "react-redux";
import 'react-bootstrap'
import AppHelmet from "../../Helmet/Helmet";




const Privacy = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(() => {
    //     document.body.classList.toggle('menu-opened');
    //   }, [])

    return (
        <>
            <Header />
            <AppHelmet title="Privacy Policy - Serviceora's Commitment to Your Privacy" description="Review Serviceora’s privacy policy to understand how we collect, use, and protect your personal information. Learn about our commitment to safeguarding your data and ensuring your privacy." keywords="privacy policy Serviceora, data protection, personal information, privacy practices, Serviceora security, user data, privacy commitment, privacy terms, data usage policy" />
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-3 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Privacy & Policy
                                    </li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-heading d-flex align-items-center">
                                        <div className="heading-content">
                                            <h2>
                                                <span className="text-weight">Privacy & Policy:</span> {" "}
                                                <span className="header-right"></span>
                                            </h2>
                                            <p>
                                                Reach out to our dedicated team for any inquiries, support, or further information about our exceptional service/product.
                                            </p>
                                            <p>
                                                <span>
                                                    At <span style={{ color: '#009da6' }}> Serviceora</span>, we value the privacy and security of our customers' data. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information. By using our services, you consent to the practices described in this policy.
                                                </span>
                                            </p>
                                            <section id="about" className="about">
                                                <div className="container" data-aos="fade-up">

                                                    <div className="row">
                                                        <div className="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                                                            <h5>We may collect personal information from customers when they interact with our website, products, or services. This information may include but is not limited to:</h5>
                                                            <p className="fst-italic">
                                                            </p>
                                                            <ul>
                                                                <li><i className="fa-solid fa-check"></i><strong>Name</strong></li>
                                                                <li><i className="fa-solid fa-check"></i><strong>Contact information (email address, phone number, mailing address)</strong></li>
                                                                <li><i className="fa-solid fa-check"></i><strong>Payment details (credit card information, billing address)</strong></li>
                                                                <li><i className="fa-solid fa-check"></i><strong>User preferences and settings</strong></li>
                                                                <li><i className="fa-solid fa-check"></i><strong>IP address and device information</strong></li>
                                                                <li><i className="fa-solid fa-check"></i><strong>Usage and interaction data</strong></li>
                                                            </ul>
                                                            <h5>We collect this information to provide and improve our services, process orders, respond to inquiries, personalize user experiences, and comply with legal obligations.</h5>
                                                            <p></p>
                                                            <ul>
                                                                <li><i className="fa-solid fa-check-double"></i><strong>Data Security: </strong> We take data security seriously and implement industry-standard security measures to protect customer data from unauthorized access, disclosure, alteration, or destruction. We use encryption, secure connections, access controls, and regular security audits to ensure the safety of your information.</li>
                                                                <li><i className="fa-solid fa-check-double"></i><strong>Data Retention: </strong>We retain customer data only for as long as necessary to fulfill the purposes for which it was collected, unless required by law to retain it for a longer period.</li>
                                                                <li><i className="fa-solid fa-check-double"></i><strong>Third-Party Services: </strong>We may engage third-party service providers to assist with payment processing, customer support, or other services. These providers have access to customer data only to perform specific tasks and are obligated to keep it confidential. Cookies and Tracking Technologies: Our website and services may use cookies and similar tracking technologies to improve user experiences and analyze usage patterns. You can control cookie preferences through your browser settings.</li>
                                                                <li><i className="fa-solid fa-check-double"></i><strong>Marketing Communications: </strong>We may send promotional emails or other marketing communications to customers who have opted to receive such information. You can opt-out of these communications at any time.</li>
                                                                <li><i className="fa-solid fa-check-double"></i><strong>Data Sharing and Disclosure: </strong>We do not sell, trade, or rent customer data to third parties for marketing purposes. However, we may share information with trusted partners for specific purposes, such as order fulfillment, customer support, or legal requirements.</li>
                                                                <li><i className="fa-solid fa-check-double"></i><strong>Legal Compliance: </strong>We will disclose customer data if required by law or in response to valid legal requests, such as subpoenas, court orders, or government investigations.</li>
                                                                <li><i className="fa-solid fa-check-double"></i><strong>Children's Privacy: </strong>Our services are not directed to children under the age of 13. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child under 13, we will promptly delete such data.</li>
                                                                <li><i className="fa-solid fa-check-double"></i><strong>Changes to this Privacy Policy: </strong>We may update this Privacy Policy from time to time. Changes will be effective upon posting on our website. Please review this policy periodically for any updates.</li>
                                                                <li><i className="fa-solid fa-check-double"></i><strong>Contact Us: </strong>If you have any questions or concerns about this Privacy Policy or the security of your data, please contact us at <span style={{ color: '#009da6' }}>info@ideativemind.com</span>.</li>
                                                            </ul>
                                                            <p>By using our services, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and disclosure of your personal information as described herein.</p>
                                                            <p>Thank you for choosing Serviceora as your trusted service provider.</p>


                                                        </div>
                                                    </div>

                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}


export default Privacy