/* eslint-disable max-len */
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { loginReducer } from "../view/auth/store";
import { profileReducer } from "../view/Pages/Dashboard/store";
import { updateProfileReducer } from "../view/Pages/Dashboard/Profile/store/reducer";
import { publishReducer } from "../view/Published/store/reducer";
import { landingPageReducer } from "../view/Pages/Landing/store";
import { myJobReducer } from "../view/Pages/Dashboard/MyJob/store"
import { nationalReducer } from "../view/Pages/Dashboard/National/store"
import { nationReducer } from "../view/Pages/Landing/Nation/store"
import { categoriesReducer } from "../view/Pages/Landing/Categories/store"
import { jobInvoiceReducer } from "../view/Pages/Dashboard/JobInvoice/store"
import { SubcategoryReducer } from "../view/Pages/Landing/Subcategory/store";
import { ticketRaiseReducer } from "../view/Pages/Dashboard/TicketRaise/store";
import { TestimonialsReducer } from "../view/Pages/Landing/Testimonial/store";
import { socialReducer } from "../view/Pages/Landing/Social/store";
import { UserNotificationReducer } from "../view/Pages/Dashboard/UserNotification/store";
import { CoinsReducer } from "../view/Pages/Dashboard/Coins/store";
import { serviceProviderReducer } from "../view/Pages/Landing/FindServiceProviderList/store";
import { enquiryReducer } from "../view/Pages/Dashboard/Enquiry/store";
import { EarnmoneyReducer } from "../view/Pages/Dashboard/Earnmoney/store";
import { CityReducer } from "../view/Pages/Landing/City/store";


const rootReducer = combineReducers({
  loginData: loginReducer,
  profileData: profileReducer,
  updateProfileData: updateProfileReducer,
  publishData: publishReducer,
  landingPageData: landingPageReducer,
  myJobData: myJobReducer,
  nationalData: nationalReducer,
  nationData: nationReducer,
  categoriesData: categoriesReducer,
  jobInvoiceData: jobInvoiceReducer,
  subcategoryData: SubcategoryReducer,
  ticketRaiseData: ticketRaiseReducer,
  testimonialsData: TestimonialsReducer,
  socialData: socialReducer,
  userNotificationData: UserNotificationReducer,
  coinsData: CoinsReducer,
  serviceProviderData: serviceProviderReducer,
  enquiryData: enquiryReducer,
  earndata: EarnmoneyReducer,
  cityData: CityReducer

});

export default rootReducer;
