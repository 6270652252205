import { createReducer } from "reduxsauce";
import { MyJobTypes } from "./action";


const INITIAL_STATE = {
    loading: false,
    myJobList: [],
    error: ''
}


const getMyJobListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true
    }
}

const getMyJobListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        loading: false,
        myJobList: data
    }
}

const getMyJobListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        loading: false
     }
}


const HANDLERS = {
    [MyJobTypes.GET_MY_JOB_LIST_START]: getMyJobListStart,
    [MyJobTypes.GET_MY_JOB_LIST_SUCCESS]: getMyJobListSuccess,
    [MyJobTypes.GET_MY_JOB_LIST_FAILURE]: getMyJobListFailure
}

export const myJobReducer = createReducer(INITIAL_STATE, HANDLERS)