// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { firebase, rootConfig } from '../services/axios/endPoints';
import sound from '../assets/audio/tone.wav'
import { UserNotificationCreators } from '../view/Pages/Dashboard/UserNotification/store';


const firebaseConfig = {
  apiKey: firebase?.apiKey,
  authDomain: firebase?.authDomain,
  projectId: firebase?.projectId,
  storageBucket: firebase?.storageBucket,
  messagingSenderId: firebase?.messagingSenderId,
  appId: firebase?.appId,
  measurementId: firebase?.measurementId
};


// Initialize Firebase app
const app = initializeApp(firebaseConfig);


// Get the Firebase Cloud Messaging instance
const messaging = getMessaging(app);
export const requestForToken = () => {
  
  
  return getToken(messaging, {
    vapidKey: firebase?.vapidKey
  })
    .then((currentToken) => {
      if (currentToken) {
        
        if (!("Notification" in window)) {
          console.log('device');
        } else {
          Notification.requestPermission();
          // console.log('Web!');
          localStorage.setItem("device_token", currentToken);
        }
        // console.log('FCM Token:', currentToken);
        
        // Perform any other necessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token:', err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log('payload', payload)
      resolve(payload);
          showNotification(payload);
    });
  });

// Export other components or functions as needed

// import { initializeApp } from "firebase/app";
// import { getMessaging, onMessage, getToken } from "firebase/messaging";


// //Add this in relevant component
// const app = initializeApp({
  // apiKey: firebase?.apiKey,
  // authDomain: firebase?.authDomain,
  // projectId: firebase?.projectId,
  // storageBucket: firebase?.storageBucket,
  // messagingSenderId: firebase?.messagingSenderId,
  // appId: firebase?.appId,
  // measurementId: firebase?.measurementId
// });
// const messaging = getMessaging(app);
// //save firebase token locally
// const FirebaseToken = localStorage?.FirebaseToken;
// //notification permission
// const getNotification = () => {
//   Notification.requestPermission();
//   if (!("Notification" in window)) {
//   } else {
//     Notification.requestPermission();
//   }
//   getFirebaseToken();//set token
// };
// const getFirebaseToken = () => {
//   getToken(messaging, {
//     vapidKey: "BN04tfy5T1_ktSRX6wf-1BlWtOr9zZ3ap5NGZIYz2lsKJ1hDJi7HVBYuBdeExb9F4FQhxrLGypP_hVados4Blqs"
//   }).then((currentToken) => {
//     if (currentToken) {
//       if (FirebaseToken !== currentToken) {
//         localStorage.setItem("FirebaseToken", currentToken);
//         console.log('currentToken', localStorage.getItem("FirebaseToken"))
//         //also save the token in your apps backend to send the message     to all those saved deviceIDs
//       }
//     } else {
//       getNotification();//ask for permission
//     }
//   }).catch((err) => { });
// }
// //foreground message
// onMessage(messaging, (payload) => {
//   if (payload) {
//     showNotification(payload);
//   }
// });
//BroadcastChannel to recieve background messaging
const channel = new BroadcastChannel("background-message");
channel.onmessage = (event) => {
  showNotification(event.data.key)
  
};
// let sound = new Audio('http://localhost:3000/static/media/tone.6cc7661e59a1b7786f85.wav');


const showNotification = (body) => {
  if (body) {
    
    //notification sound
    // const a = rootConfig?.local_url+sound;
    
    const audio = new Audio(process.env.REACT_APP_API_URL+'/assets/audio/tone.wav');
    audio.play();
    

    // console.log('body', body)
    //notification body
    const options = {
      body: body.notification.body,
      icon: "icon.png",
      vibrate: true,//vibrate will work only on the mobile device
    };
    let notification = new Notification(body.notification.title, options);
    // console.log('notification', notification)
    notification.onclick = function (event) {
      event.preventDefault();
      window.open("/user/dashboard", "_blank");
    };
  }
} 



/*** New Code */
