import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPublishedJob: ['payload'],
  getPublishedJobStart: ['payload'],
  getPublishedJobSuccess: ['payload'],
  getPublishedJobFailure: ['payload'],
  getCreatedJobStart: ['payload'],
  getCreatedJobSuccess: ['payload'],
  getCreatedJobFailure: ['payload'],
});

export { Types, Creators };
