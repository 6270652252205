import React, { useState, useEffect } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { styled } from "styled-components";
import Select from 'react-select';
import Button from 'react-bootstrap-button-loader';
// import { Modal } from 'bootstrap';
// import { Button } from 'bootstrap';
// import Button from 'rea';

export default function ModalComponent({
  isModalVisible,
  setIsModalVisible,
  title,
  handleLogout,
  isForm,
  setBidInput,
  bidInput,
  handleChange,
  handlerFunction,
  errorList,
  setErrorList,
  bidloading,
}) {
  const [show, setShow] = useState(false);
  const [option, setOption] = useState([])

  const options = [
    { value: '₹', label: '₹ Indian Rupees' },
    { value: '$', label: '$ US Dollar' },
  ];

  const handleClose = () => {
    if(bidInput != undefined ){
      setErrorList([]);
      setBidInput([]);
    }
    setIsModalVisible(!isModalVisible);
  };

  const handleChange1 = (option) => {
    setOption(option)
    setBidInput({ ...bidInput, 'amount_sign': option?.value })
  }
  // const handleShow = () => setShow(true);
  // console.log(errorList, "==");
  // useEffect(() => {
    
  // }, [])
  
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch static backdrop modal
      </Button> */}

      <Modal
        show={isModalVisible}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><span className="capitalize" style={{color:"#009da6"}}>{title} </span></Modal.Title>
        </Modal.Header>
        {isForm ? (
          <>
          <div className="card-body">
            <form onSubmit={handlerFunction}>
              <div className="row form-row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="amount">Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      name="amount"
                      defaultValue={bidInput?.amount}
                      placeholder="Please Enter Amount"
                      onChange={handleChange}
                    />
                    <ErrorP>{errorList.amount}</ErrorP>
                    <p style={{color:'green'}}> No service charges!</p>
                  </div>
                  
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="amount_sign">Amount sign</label>
                    
                    <Select
                        name="type"
                        // isMulti = {true}
                        options={options}
                        // value={options}
                        closeMenuOnSelect={true}
                        onChange={handleChange1}/>
                    <ErrorP>{errorList.amount_sign}</ErrorP>
                  </div>
                  
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="duration_type">Duration Type</label>
                    <select
                    name="duration_key"
                      className="form-control select"
                      defaultValue={bidInput?.duration_key}
                      onChange={(e) =>
                        setBidInput({
                          ...bidInput,
                          ["duration_key"]: e.target.value,
                        })
                      }
                    >
                      <option defaultValue="">
                        Select you option
                      </option>
                      <option value="days">Days</option>
                      <option value="week">Weeks</option>
                      <option value="month">Months</option>
                      <option value="year">Years</option>
                    </select>
                    <ErrorP>{errorList.duration_key}</ErrorP>
                  </div>
                  
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="duration">Duration</label>
                    <input
                      type="text"
                      className="form-control"
                      name="duration"
                      defaultValue={bidInput?.duration}
                      placeholder="Please Enter Duration"
                      onChange={handleChange}
                    />
                    <ErrorP>{errorList.duration}</ErrorP>
                  </div>
                  
                </div>
                <div className="col-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="description">Remarks (optional) Max:100</label>
                    <textarea
                      className="form-control"
                      id="description"
                      rows="5"
                      name="message"
                      defaultValue={bidInput?.message}
                      onChange={handleChange}
                      // id="validationCustomUsername"
                      aria-describedby="validationTooltipUsernamePrepend"
                    />
                    <ErrorP>{errorList.message}</ErrorP>
                    <div className="invalid-tooltip">
                      Please choose a username.
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="submit-section d-flex justify-content-center">
                {/* <button type="submit" className="btn btn-sm btn-primary">
                  Bid Now
                </button> */}
                <Button className="btn btn-primary login-btn" type="submit" loading={bidloading ? true : false}>Bid Now!</Button>
              </div>
              
            </form>
          </div>
          </>
        ) : (
          <>
            <Modal.Body>Do you realy want to logout ?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="primary" onClick={handleLogout}>
                Yes
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

// render(<Modal />);

export const ErrorP = styled.span`
  color: red;
  bottom: 1rem;
  left: 1rem;
  font-size: 12px;
`;
