import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../Footer";
import { Creators } from "../../../auth/store";
import { Toaster, toast } from "react-hot-toast";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import './style.css'
import Header from "../Header/Header";
import Loader from "../../../../Common/Loader/Loader";
import { TestimonialsCreators } from "../Testimonial/store";
import AppHelmet from "../../Helmet/Helmet";


const About = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const { aboutloading, aboutList } = useSelector((state) => state.testimonialsData)
  useEffect(() => {
    dispatch(TestimonialsCreators.getAboutList())
  }, []);






  useEffect(() => {
  }, [])

  return (
    <>
      <Toaster />
      <Header />
      <AppHelmet title="About Us - Learn More About Serviceora's Mission and Team" description="Discover the story behind Serviceora. Learn about our mission, values, and the dedicated team working to connect customers with top service providers. Find out how we're transforming the service marketplace." keywords="About Serviceora, our mission, Serviceora team, company values, Serviceora story, service marketplace, Serviceora vision, who we are, company overview" />
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-3 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    About Us
                  </li>
                </ol>
              </nav>
            </div>

          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-heading d-flex align-items-center">
                    <div className="heading-content">
                      <h2>
                        <span className="text-weight">About Us:</span> {" "}
                        <span className="header-right"></span>
                      </h2>
                      <h3>Welcome to Serviceora: Where Connections Happen</h3>
                      <p>
                        <span>
                          Imagine a world where finding the perfect service provider is just a click away. Where you can get things done efficiently, effectively, and with complete peace of mind. That world is now a reality, thanks to Serviceora.
                        </span>
                      </p>
                      <section id="about" className="about">
                        <div className="container" data-aos="fade-up">

                          <div className="row">
                            <div className="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                              <h4>Our Story</h4>
                              <p>
                                We're on a mission to revolutionize the way people connect with service providers. We believe that everyone deserves access to top-notch services, and that service providers deserve a platform to showcase their skills. That's why we created Serviceora - a platform that brings people together, making it easy to get things done.
                              </p>
                              <h4>How it Works</h4>
                              <p>
                                It's simple. Customers post a job, and our system matches them with the best service providers in their area. Service providers receive notifications, bid on the job, and customers get to choose the perfect fit. It's a win-win!
                              </p>
                              <h4>Our Values</h4>
                              <p></p>
                              <ul>
                                <li><i className="fa-solid fa-check-double"></i><p><strong>Empowerment: </strong>
                                  We believe in giving customers the power to choose, and service providers the platform to shine.</p>
                                </li>
                                <li><i className="fa-solid fa-check-double"></i><p><strong>Innovation: </strong>
                                  We're constantly pushing the boundaries of what's possible, to make your experience better.</p>
                                </li>
                                <li><i className="fa-solid fa-check-double"></i><p><strong>Community: </strong>
                                  We're building a community that's all about connection, collaboration, and mutual success.</p>
                                </li>
                              </ul>
                              <h4>Meet Our Team</h4>
                              <p>
                                  We're a team of dreamers, thinkers, and doers who are passionate about making a difference. We're dedicated to creating a platform that's intuitive, efficient, and fun to use.
                              </p>
                              <h4>Join the Movement</h4>
                              <p>
                              Ready to experience the future of service provision? Register now as a customer or service provider, and discover a world of possibilities.
                              </p>
                              <p>
                              This rewritten version has a more attractive tone, with a focus on the benefits of using Serviceora, and a more conversational language. It also includes a few elements to make it more engaging, such as the use of rhetorical questions, and a call to action to "join the movement".
                              </p>
                              <h5>Have any questions or need assistance? Our dedicated support team is here to help. Reach out to us at <span style={{ color: '#009da6' }}>info@ideativemind.com</span>.</h5>
                            </div>
                          </div>

                        </div>
                      </section>
                    </div>
                  </div>
                  <div className="card card-table">
                    <div className="card-body">
                      <section id="team" className="team section-bg">
                        <div className="container-fluid" data-aos="fade-up">
                          <div className="heading-content">
                            <h2>
                              <span className="text-weight">Our Team</span> {" "}
                              <span className="header-right"></span>
                            </h2>
                          </div>

                          <div className="row">

                            {aboutloading ? <p>Loading</p> :
                              aboutList.map((about, index) => {
                                return (
                                  <div key={index} className="col-lg-6" data-aos="zoom-in" data-aos-delay="100" style={{ padding: '20px' }}>
                                    <div className="member d-flex align-items-start">
                                      <div className="pic"><img src={rootConfig?.base_url + about?.image} className="img-fluid" alt={about?.name} /></div>
                                      <div className="member-info">
                                        <h4 className="capitalize">{about?.name}</h4>
                                        <span className="capitalize">{about?.designation}</span>
                                        <p>{about?.message}</p>
                                        {/* <div className="social">
                                <a href=""><i className="far fa-twitter-fill"></i></a>
                                <a href=""><i className="ri-facebook-fill"></i></a>
                                <a href=""><i className="ri-instagram-fill"></i></a>
                                <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
                              </div> */}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }



                          </div>

                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}



export default About