import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { EnquiryCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { ProfileCreators } from "../Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import Loader from "../../../../Common/Loader/Loader";
import { SideBar } from "../SideBar";
import moment from "moment";
import './style.css'
import AppHelmet from "../../Helmet/Helmet";
import { ArrowRight, CCircle, ArrowDownLeft, ArrowUpRight } from 'react-bootstrap-icons';

export const Enquiry = () => {
    const userData = getUserDataFromLocal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { profileData } = useSelector((state) => state.profileData);
    const { enquiryList, enquiryloading } = useSelector((state) => state.enquiryData);

    useEffect(() => {
        dispatch(EnquiryCreators.getEnquiryList());
    }, []);
    
    const handleJsonData = (json_data) => {
        const jsonObject = JSON.parse(json_data);
        // console.log('jsonObject', jsonObject)
        return (
            <>
            
                <p className="text-dark" style={{fontSize:'15px'}}> Name: {jsonObject?.name}<br/>Phone: {jsonObject?.phone} </p>
                <p className="text-secondary" style={{color:'#000000'}}><i className="fas fa-question-circle"></i> Someone wants you to call them right away. They have a question.</p>
            </>
        )

    }

    return (
        <>
            <Toaster />
            <AppHelmet title="Send an Enquiry to Discuss with Service Providers on Serviceora" description="Easily connect with trusted service providers on Serviceora. Send your enquiry to discuss services, pricing, and more. Get personalized solutions tailored to your needs." keywords="Serviceora service enquiry, discuss with service provider, send enquiry Serviceora, connect with providers, service provider discussion, personalized service solutions, Serviceora enquiry form" />
            {/* <ModalComponent /> */}
            {enquiryloading ? (
                <Loader containerHeight={70} width="4rem" />
            ) : (
                <div className="main-wrapper">
                    <div className="breadcrumb-bar">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-12">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                <Link to="/user/enquiry">Enquiry</Link>
                                            </li>
                                        </ol>
                                    </nav>
                                    <h2 className="breadcrumb-title">Enquiry</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <SideBar profileData={profileData} />
                                <div className="col-md-7 col-lg-8 col-xl-9">
                                   <section className="section-50">
                                        <div className="container">
                                            <h3 className="m-b-50 heading-line">Enquiry</h3>

                                            <div className="coin-ui_dd-content">
                                                {enquiryloading && <Loader width="1.4rem" />}
                                                {enquiryList?.length === 0 ? 'No enquiry found!' : enquiryList?.map((enquiry, index) => {
                                                    return (
                                                        <div className={`coin-list text-muted'}`} key={index}>
                                                        <div className="coin-list_content">
                                                            <div className="notification-list_detail">
                                                                {handleJsonData(enquiry?.query)}
                                                            </div>
                                                        </div>
                                                        <div className="notification-list_feature-img">
                                                            <p className="text-muted"><small>{moment(enquiry?.created_at).fromNow()}</small></p>
                                                        </div>
                                                    </div>
                                                    )
                                                    
                                                })
                                                }
                                            </div>

                                            {/* <div className="text-center">
                                                <a href="#!" className="dark-link">Load more activity</a>
                                            </div> */}

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
} 