import { createReducer } from "reduxsauce";
import { ServiceProviderTypes } from "./action";


const INITIAL_STATE = {
    loading: false,
    serviceProviderList: [],
    error: ''
}


const getServiceProviderListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true
    }
}

const getServiceProviderListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        loading: false,
        serviceProviderList: data
    }
}

const getServiceProviderListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        loading: false
     }
}


const HANDLERS = {
    [ServiceProviderTypes.GET_SERVICE_PROVIDER_LIST_START]: getServiceProviderListStart,
    [ServiceProviderTypes.GET_SERVICE_PROVIDER_LIST_SUCCESS]: getServiceProviderListSuccess,
    [ServiceProviderTypes.GET_SERVICE_PROVIDER_LIST_FAILURE]: getServiceProviderListFailure
}

export const serviceProviderReducer = createReducer(INITIAL_STATE, HANDLERS)