import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ProfileCreators } from "../store";
import { FlexContainer } from "../../../../globalStyles";
import { updateProfileCreators } from "./store";
import Loader from "../../../../Common/Loader/Loader";
import { SideBar } from "../SideBar";
import { styled } from "styled-components";
import { ErrorP } from "../../../../Common/Modal/Modal";
import validator from "validator";
import { isEmail, isMobilePhone } from "../../../../shared/utility/regex";
import { getBase64 } from "../../../../shared/utility/getBase64";
import SearchableDropdown from "../../../../Common/SearchDropDown/SearchInput";
import { UpdateForm } from "./UpdateForm";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints } from "../../../../services/axios/endPoints";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { Toaster, toast } from "react-hot-toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";

export const ProfileSettings = () => {
  const { profileData, loading } = useSelector((state) => state.profileData);
  const { updatemsg, profileLoading } = useSelector(
    (state) => state.updateProfileData
  );
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();

  const [isEmpty, setIsEmpty] = useState(false);
  const [updateInput, setUpdateInput] = useState({
    first_name: profileData[0]?.first_name || "",
    last_name: profileData[0]?.last_name || "",
    email: profileData[0]?.email || "",
    country: "",
    state: "",
    city: "",
    permanent_address: profileData[0]?.permanent_address || "",
    correspondence_address: profileData[0]?.correspondence_address || "",
    profile: "",
    phone_number: profileData[0]?.phone_number || "",
    alternate_number: profileData[0]?.alternate_number || "",
    aadhar_number: profileData[0]?.aadhar_number || "",
    front_aadhar_image: profileData[0]?.front_aadhar_image || "",
    back_aadhar_image: profileData[0]?.back_aadhar_image || "",
    gender: profileData[0]?.gender || "Male",
    business_name: profileData[0]?.business_name || "",
    user_role: profileData[0]?.user_role || "",
    business_description: profileData[0]?.business_description || "",
    type: profileData[0]?.type || ""
  });

  // console.log('profileData', profileData)

  const [searchCountry, setSearchCountry] = useState("");
  const [searchState, setSearchState] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState(null);
  const [option, setOption] = useState([])
  
  

  // console.log(updateInput, "updateInput");

  const { nationList, nationLoading } = useSelector((state) => state.updateProfileData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ProfileCreators.getProfile());
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateInput({ ...updateInput, [name]: value });
  };

  
  const handleChangePhone = (e) => {
    setUpdateInput({ ...updateInput, phone_number: e });
  }
  const handleChangePhone1 = (e) => {
    setUpdateInput({ ...updateInput, alternate_number: e });
  }

  const handleNation = (name, id) => {
    // console.log(name, id);
    setUpdateInput({ ...updateInput, [name]: id });
  };
  function handleImageChange(e) {
    const { name, value } = e.target;
    getBase64(e.target.files[0]).then((result) => {
      // console.log('yes', updateInput)
      setUpdateInput({
        ...updateInput, [name]: result,
      });
    });
  }

  const handleChange1 = (option) => {
    setOption(option)
    setUpdateInput({ ...updateInput, 'type': option?.value })
  }
  // console.log('profile', profileData)

  const handleUpdate = async () => {
    try {
      // console.log('json', updateInput)
      // return false;
      // yield put(updateProfileCreators.updateProfileStart());
      const response = await WebService.post(
        apiEndPoints.updateProfile,
        JSON.stringify(updateInput),
        {
          headers: { Authorization: "Bearer " + userData.token },
        }
      );
      // const response = true;
      // console.log(response, "response");
      // return false;

      if (response.status) {
        dispatch(ProfileCreators.getProfile());
        navigate('/user/profile')
        toast.success(response?.data?.msg, { position: "top-center" });
      } else {
        throw response;
      }
    } catch (err) {
      const error = sagaCatchBlockHandling(err);
      toast.error("Something Went Wrong!", {
        position: "top-right",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      // dispatch(updateProfileCreators.updateProfile({ updateInput }));
      handleUpdate();
  };

  // console.log(profileData, "profiledata");
  // console.log(updateInput, "updateInput");q
  // console.log(profileLoading, "loading g", updatemsg);

  useEffect(() => {
    dispatch(updateProfileCreators.getNationData());
  }, [searchCountry]);

  useEffect(() => {
    dispatch(updateProfileCreators.updateProfileSuccess({ msg: "" }));
  }, []);

  return (
    <>
      <Toaster />
      {loading ? (
        <Loader containerHeight={70} width="4rem" />
      ) : (
        <>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <SideBar profileData={profileData} />
                <UpdateForm
                  handleSubmit={handleSubmit}
                  updateInput={updateInput}
                  handleChange={handleChange}
                  handleImageChange={handleImageChange}
                  handleNation={handleNation}
                  isEmpty={isEmpty}
                  searchCountry={searchCountry}
                  setSearchCountry={setSearchCountry}
                  setStateData={setStateData}
                  searchState={searchState}
                  setSearchState={setSearchState}
                  cityData={cityData}
                  searchCity={searchCity}
                  setCityData={setCityData}
                  setSearchCity={setSearchCity}
                  stateData={stateData}
                  profileData={profileData}
                  handleChange1={handleChange1}
                  setUpdateInput={setUpdateInput}
                  handleChangePhone={handleChangePhone}
                  handleChangePhone1={handleChangePhone1}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const Required = styled.span`
  color: red;
`;
