import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../auth/store";
import { ProfileCreators } from "./store";
import { FlexContainer } from "../../../globalStyles";
import SearchableDropdown from "../../../Common/Input/SearchInput";
import Loader from "../../../Common/Loader/Loader";
// import ModalComponent, { Modal } from "../../Common/Modal/Modal";
import ModalComponent from "../../../Common/Modal/Modal";
import { SideBar } from "./SideBar";
import { WebService } from "../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../store/utility/sagaErrorHandling";
import { getUserDataFromLocal } from "../../../shared/utility/helper";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { styled } from "styled-components";
import { Rating } from 'react-simple-star-rating'
import { ErrorP } from "../../../Common/Modal/Modal";
import Select from 'react-select';
import { SocialCreators } from "../Landing/Social/store";
import AppHelmet from "../Helmet/Helmet";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export const Dashboard = () => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();
  const { profileData, loading, categoryLoading, catagories, ApprovedJobListLoading, ApprovedJobList } = useSelector(
    (state) => state.profileData
  );

  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(null);
  const [seletedItem, setSelectedItem] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [search, setSearch] = useState("");
  const [isRatingModal, setIsRatingModal] = useState(false);
  const [isJobStatusModal, setIsJobStatusModal] = useState(false);
  const [isTicketModal, setIsTicketModal] = useState(false);
  const [jobTitle, setJobTitle] = useState(false)
  const [ratingValue, setRatingValue] = useState(0)
  const [input, setInput] = useState({})
  const [totaljob, setTotaljob] = useState({})
  const [errorList, seterrorList] = useState([])
  const [rating, setRating] = useState({
    sender_id: profileData[0]?.id,
    approve_job_id: '',
    job_id: ''

  })
  const [option, setOption] = useState([])
  const [optionStatus, setOptionStatus] = useState()
  const [actionInput, setActionInput] = useState({})
  const [ticketInput, setTicketInput] = useState({})
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false)

  const useAuth = () => {
    const user = localStorage.getItem("user");
    if (user) {
      return true;
    } else {
      return false;
    }
  };
  const auth = useAuth();

  const handleLogout = () => {
    dispatch(Creators.onLogout());
    navigate("/");
  };

  const { socialList, socialloading } = useSelector((state) => state.socialData);

  const options = [
    { value: 'Completed', label: 'Completed' },
    { value: 'Cancel', label: 'Cancel' },
  ];

  useEffect(() => {
    dispatch(SocialCreators.getSocialList());
    dispatch(ProfileCreators.getProfile());
    dispatch(ProfileCreators.getApprovedJobList());
    totalJobBidCount()
  }, []);


  // const handleJob = () => {
  //   setIsModalVisible(true);
  //   dispatch(ProfileCreators.getCategories());
  // };

  const handleAddJob = () => {
    // console.log("ele");
    setIsModalVisible("job");
  };

  const generateInvoice = async (ele) => {
    try {
      const response = await WebService.post(
        apiEndPoints.generateinvoice,
        JSON.stringify({ job_bid_approve_id: ele }),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      // console.log('response', response)
      if (response.status === 200) {
        toast.success(response.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        // navigate(`/user/job/order/${ele}/show/`)
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        // navigate(`/user/job/order/${ele}/show/`)
      }
      else {
        toast.error('something went wrong!', {
          ToastWarning,
          position: "top-center",
        });
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }
  }

  const totalJobBidCount = async () => {
    try {
      const response = await WebService.get(
        apiEndPoints.totalJobCountBid,
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      // console.log('response', response)
      if (response.status === 200) {
        // toast.success(response.data.msg, {
        //   ToastSuccess,
        //   position: "top-center",
        // });
        setTotaljob(response.data.data)
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
      }
      else {
        toast.error('something went wrong!', {
          ToastWarning,
          position: "top-center",
        });
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }
  }



  const handleTicketChange = (e) => {
    const { name, value } = e.target;
    setTicketInput({ ...ticketInput, [name]: value });
  }

  const Ratingpopup = (approve_job_id, job_id, job_title) => {
    if (!userData) {
      navigate("/login");
    } else {
      console.log('job', job_id, approve_job_id)
      setJobTitle(job_title)
      setIsRatingModal(true)
      setInput({
        sender_id: profileData[0]?.id,
        approve_job_id: approve_job_id,
        job_id: job_id,
      })
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  }

  const opnActionjobPopup = (approve_job_id, job_id, job_title) => {
    if (!userData) {
      navigate("/login");
    } else {
      setJobTitle(job_title)
      setIsJobStatusModal(true)
      setActionInput({
        sender_id: profileData[0]?.id,
        approve_job_id: approve_job_id,
        job_id: job_id
      })
    }
  }
  const handleChangeStatus = (option) => {
    setOptionStatus(option)
    // setActionInput({ ...actionInput, 'status': option?.value})

  }

  const handleSubmitJobAction = (e) => {
    e.preventDefault()
    handleSubmitJobActionStatus()
  }

  const handleSubmitJobActionStatus = async () => {
    try {
      const response = await WebService.post(
        apiEndPoints.jobStatusUpdate,
        JSON.stringify({ ...actionInput, 'status': optionStatus?.value }),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      // console.log('response', response)
      if (response.status === 200) {
        toast.success(response.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        dispatch(ProfileCreators.getApprovedJobList());
        setIsJobStatusModal(false)
        setActionInput({})
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setIsJobStatusModal(false)
        setActionInput({})
      }
      else {
        toast.error('something went wrong!', {
          ToastWarning,
          position: "top-center",
        });
        setIsJobStatusModal(false)
        setActionInput({})
      }

    } catch (err) {
      const error = sagaCatchBlockHandling(err);
      toast.error("Something Went Wrong!", {
        position: "top-right",
      });

    }
  }

  const handleRating = (rate) => {
    setRatingValue(rate)
    setInput({ ...input, 'rating': rate })
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('tes')
    setInput({ ...input })
    handleGiveRating()
  }

  const handleGiveRating = async () => {
    try {
      // console.log('test', JSON.stringify(input))

      // return false
      const response = await WebService.post(
        apiEndPoints.giveRating,
        JSON.stringify(input),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        setIsRatingModal(false)
        setInput({})
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setIsRatingModal(false)
        setInput({})
      }
      else if (response.response.status === 422) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        seterrorList(response.response.data)
        // setIsRatingModal(false)
        setInput({})
      }
      else {
        toast.error('something went wrong!', {
          ToastWarning,
          position: "top-center",
        });
        setIsRatingModal(false)
        setInput({})
      }

    } catch (err) {
      const error = sagaCatchBlockHandling(err);
      toast.error("Something Went Wrong!", {
        position: "top-right",
      });

    }
  }

  const StartServiceProviderJobSuccess = async (approveJob, jobId) => {
    try {
      const response = await WebService.post(
        apiEndPoints.startJob,
        JSON.stringify({ job_bid_approve_id: approveJob, job_id: jobId, 'status': 'Processing' }),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        setIsRatingModal(false)
        setInput({})
        dispatch(ProfileCreators.getApprovedJobList());
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setIsRatingModal(false)
        setInput({})
      }
      else {
        toast.error('something went wrong!', {
          ToastWarning,
          position: "top-center",
        });
        setIsRatingModal(false)
        setInput({})
      }

    } catch (err) {
      const error = sagaCatchBlockHandling(err);
      toast.error("Something Went Wrong!", {
        position: "top-right",
      });

    }
  }


  const StartServiceProviderJob = async (approveJob, jobId) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: <div>
                <strong>Are you sure you want to start a job?</strong>
          </div>,
      buttons: [
        {
          label: 'Yes',
          onClick: () => StartServiceProviderJobSuccess(approveJob, jobId)
        },
        {
          label: 'No',
          onClick: () => alert('Please try again.')
        }
      ]
    });
    
  }

  const openTicketModal = (approve_job_id, job_id, bid_id, job_title) => {
    if (!userData) {
      navigate("/login");
    } else {
      setJobTitle(job_title)
      setIsTicketModal(true)
      setTicketInput({
        sender_id: profileData[0]?.id,
        approve_job_id: approve_job_id,
        job_id: job_id,
        bid_id: bid_id
      })
    }
  }

  const handleTicketSubmit = (e) => {
    e.preventDefault();
    // console.log('ticket', ticketInput)
    handleTicketRaise()
  }

  const handleTicketRaise = async () => {
    try {
      const response = await WebService.post(
        apiEndPoints.ticketRaiseSubmit,
        JSON.stringify(ticketInput),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        setIsTicketModal(false)
        setTicketInput({})
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        setIsTicketModal(false)
        setTicketInput({})
      }
      else if (response.response.status === 422) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        // setIsTicketModal(false)
        seterrorList(response.response.data.data)
      }
      else {
        toast.error('something went wrong!', {
          ToastWarning,
          position: "top-center",
        });
        setIsTicketModal(false)
        setTicketInput({})
      }

    } catch (err) {
      const error = sagaCatchBlockHandling(err);
      toast.error("Something Went Wrong!", {
        position: "top-right",
      });

    }
  }


  useEffect(() => {
    document.body.classList.remove('menu-opened');
  }, [])

  /*** payFeesToAdmin */
  const payFeesToAdmin = (approveJobId, jobTitle) => {
    setJobTitle(jobTitle)
    console.log('approve', approveJobId)
    setIsPaymentConfirmed(true)
  }


  return (
    <>
      <Toaster />
      <AppHelmet title="Serviceora Dashboard - Manage Your Services, Bids, and Earnings" description="Access your personalized Serviceora dashboard to manage services, track bids, view earnings, and monitor your activity. Stay organized and in control with real-time updates and insights." keywords="Serviceora dashboard, manage services, track bids, view earnings, dashboard features, user activity Serviceora, service management, real-time dashboard" />
      {ApprovedJobListLoading ? (
        <Loader containerHeight={70} width="2.4rem" />
      ) : (
        <div className="main-wrapper">
          {isPaymentConfirmed && (
            <Modal
              show={true}
              size="small"
              onHide={() => setIsPaymentConfirmed(false)}
              backdrop="static"
              keyboard={false}
              aria-labelledby="example-modal-sizes-title-lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}>Service charges fees</span>  ({jobTitle}) </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{socialList?.commission}</p>
                Admin
              </Modal.Body>
            </Modal>
          )}
          {isRatingModal && (
            <Modal
              show={true}
              size="small"
              onHide={() => setIsRatingModal(false)}
              backdrop="static"
              keyboard={false}
              aria-labelledby="example-modal-sizes-title-lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}>{jobTitle}</span> Rating</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSubmit}>
                  <div className="row form-row">
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label>
                          Rating
                        </label>
                        <Rating onClick={handleRating} initialValue={0} size="30"
                          showTooltip
                          tooltipArray={['Terrible', 'Bad', 'Average', 'Great', 'Prefect']}
                          fillColorArray={['#f17a45', '#f19745', '#f1a545', '#f1b345', '#f1d045']}
                          tooltipStyle={{ backgroundColor: '#009da6' }}
                          name='rating'
                        />
                      </div>

                      <ErrorP>{errorList.rating}</ErrorP>
                    </div>
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label>
                          Review
                        </label>
                        <textarea className="form-control" placeholder="Enter review" name="review" value={input.review} onChange={handleChange}></textarea>
                      </div>
                    </div>
                    <div className="submit-section">
                      <button
                        type="submit"
                        className="btn btn-primary btn-xs"
                      // disabled
                      >
                        {loading ? (
                          <Loader width="1.4rem" btn={true} />
                        ) : (
                          "Give Rating"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          )}
          {isTicketModal && (
            <Modal
              show={true}
              size="small"
              onHide={() => setIsTicketModal(false)}
              backdrop="static"
              keyboard={false}
              aria-labelledby="example-modal-sizes-title-lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}></span> Ticket Raise</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleTicketSubmit}>
                  <div className="row form-row">
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label>
                          Ticket Job Name
                        </label>
                        <input className="form-control capitalize" defaultValue={jobTitle} disabled />
                      </div>
                    </div>
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label>
                          Subject
                        </label>
                        <input className="form-control" type="text" name="subject" onChange={handleTicketChange} />
                        <ErrorP>{errorList.subject}</ErrorP>
                      </div>
                    </div>
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label>
                          Query
                        </label>
                        <textarea className="form-control" placeholder="Enter query" name="query" value={ticketInput.query} onChange={handleTicketChange}></textarea>
                        <ErrorP>{errorList.query}</ErrorP>
                      </div>
                    </div>
                    <div className="submit-section">
                      <button
                        type="submit"
                        className="btn btn-primary btn-xs"
                      // disabled
                      >
                        {loading ? (
                          <Loader width="1.4rem" btn={true} />
                        ) : (
                          "Ticket Raise"
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          )}

          {isJobStatusModal && (
            <Modal
              show={true}
              size="small"
              onHide={() => setIsJobStatusModal(false)}
              backdrop="static"
              keyboard={false}
              aria-labelledby="example-modal-sizes-title-lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}>{jobTitle}</span> Action on Job</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSubmitJobAction}>
                  <div className="row form-row">
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label>
                          Job Action
                        </label>
                        <Select
                          name="status"
                          className="select"
                          width="100px"
                          // isMulti = {true}
                          options={options}
                          onChange={handleChangeStatus}
                          closeMenuOnSelect={true}

                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              // borderColor: state.isFocused ? 'grey' : 'red',
                              textAlign: 'left',
                              alignItems: 'right'
                            }),
                          }} placeholder="Job Action " />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="submit-section">
                        <button
                          type="submit"
                          className="btn btn-primary btn-xs"
                        // disabled
                        >
                          {loading ? (
                            <Loader width="1.4rem" btn={true} />
                          ) : (
                            "Action on Job"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          )}
          <div className="breadcrumb-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        <Link to="/user/dashboard">Dashboard</Link>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="breadcrumb-title">Dashboard</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <SideBar
                  handleAddJob={handleAddJob}
                  profileData={profileData}
                />
                <div className="col-md-7 col-lg-8 col-xl-9">
                  <div className="row">
                    <div className="col-md-12 col-lg-3 dash-board-list blue">
                      <div className="dash-widget">
                        <div className="circle-bar">
                          <div className="icon-col">
                            <i className="fas fa-users"></i>
                          </div>
                        </div>
                        <div className="dash-widget-info">
                          <h3>{totaljob?.total_jobs}</h3>
                          <h6>Total Jobs</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3 dash-board-list yellow">
                      <div className="dash-widget">
                        <div className="circle-bar">
                          <div className="icon-col">
                            <i className="fas fa-calendar-check"></i>
                          </div>
                        </div>
                        <div className="dash-widget-info">
                          <h3>{totaljob?.total_complete_jobs}</h3>
                          <h6>Total Completed</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3 dash-board-list pink">
                      <div className="dash-widget">
                        <div className="circle-bar">
                          <div className="icon-col">
                            <i className="fas fa-wallet"></i>
                          </div>
                        </div>
                        <div className="dash-widget-info">
                          <h3>{totaljob?.total_service}</h3>
                          <h6>Total Give Service</h6>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-3 dash-board-list pink">
                      <div className="dash-widget">
                        <div className="circle-bar">
                          <div className="icon-col">
                            <i className="fas fa-wallet"></i>
                          </div>
                        </div>
                        <div className="dash-widget-info">
                          <h3>{totaljob?.total_take_service}</h3>
                          <h6>Total take Services</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="mb-4">Approved Jobs</h4>
                      <div className="card card-table">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Sr. no.</th>
                                  <th>BASIC INFO</th>
                                  <th>Approved Time</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ApprovedJobList?.data?.length < 1 ? <>
                                  <tr>
                                    <td colSpan="7">No Item Found Approved job!</td>
                                  </tr>
                                </> : ApprovedJobList?.data?.map((approveJob, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      {auth && userData?.userData?.id === approveJob?.service?.id ? (
                                        <>
                                        <td>
                                          <h2 className="table-avatar">
                                            <Link to="#" className="avatar avatar-sm me-2">
                                              <img
                                                className="avatar-img rounded-circle"
                                                src={rootConfig?.base_url + approveJob?.customer?.profile}
                                                alt={approveJob?.customer?.name}
                                              />
                                            </Link>
                                            <Link to="#" className="capitalize">
                                              
                                            <span style={{ textDecoration: "underline" }}> {approveJob?.job?.title} <button type="button" className="badge bg-primary text-light" disabled>  Give Service</button> </span> 
                                              {approveJob?.customer?.name} 
                                              {/* ({approveJob?.customer?.type === 'Service' ? 'Service Provider' : 'Customer'}) */}
                                              <span>
                                                <span style={{ textTransform: "lowercase" }}><i className="fas fa-id-card"></i> {approveJob?.customer?.business_name}</span>
                                                <span style={{ textTransform: "lowercase" }}><i className="fas fa-phone"></i> {approveJob?.customer?.phone_number}</span>
                                                {/* <span><i className="fas fa-key"></i> {approveJob?.customer?.shram_code}</span> */}
                                              </span>
                                            </Link>

                                          </h2>
                                          {/* <h2 className="table-avatar">
                                              <a href="profile.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src={rootConfig?.base_url + approveJob?.customer?.profile} alt="User Image" /></a>
                                                <a href="profile.html"><span style={{textDecoration:"underline"}}>Car Hire</span>
                                                <span>
                                                    <span>Neeraj Kumar, Bid As a (customer) </span>
                                                    <span>Ankit Painter</span>
                                                    <span>+91 999 2444 699</span>
                                                </span>
                                              </a>
                                          </h2> */}
                                        </td>
                                        
                                        </>
                                      ) : (
                                        <td>
                                          <h2 className="table-avatar">
                                            <Link to="#" className="avatar avatar-sm me-2">
                                              <img
                                                className="avatar-img rounded-circle"
                                                src={rootConfig?.base_url + approveJob?.service?.profile}
                                                alt={approveJob?.service?.name}
                                              />
                                            </Link>
                                            <Link to={`tel:${approveJob?.service?.phone_number}`} className="capitalize">
                                            
                                            <span style={{ textDecoration: "underline" }}> {approveJob?.job?.title} <button type="button" className="badge bg-warning" disabled>  Take Service</button> </span> 
                                              {approveJob?.service?.name} 

                                              <span style={{ textTransform: "lowercase" }}><i className="fas fa-id-card"></i> {approveJob?.service?.business_name}</span>
                                              <span style={{ textTransform: "lowercase" }}><i className="fas fa-phone"></i> {approveJob?.service?.phone_number}</span>
                                              {/* <span><i className="fas fa-key"></i> {approveJob?.service?.shram_code}</span> */}
                                            </Link>

                                          </h2>
                                        </td>
                                      )}
                                      <td>{moment(approveJob?.created_at).fromNow()}</td>
                                      <td className="text-center">
                                        {approveJob?.status === 'Approved' ? 
                                          <span className="badge badge-info">{approveJob?.status}</span>
                                        :  approveJob?.status === 'Processing' ? 
                                          <span className="badge rounded-pill badge-primary">{approveJob?.status}</span>
                                        :  approveJob?.status === 'Completed' ? 
                                          <span className="badge rounded-pill badge-success">{approveJob?.status}</span>
                                        :  approveJob?.status === 'Cancel' ? 
                                          <span className="badge rounded-pill badge-warning">{approveJob?.status}</span>
                                        :  approveJob?.status === 'Deleted' ? 
                                          <span className="badge rounded-pill badge-danger">{approveJob?.status}</span>
                                        : ''}
                                        
                                      </td>
                                      <td className="text-center">

                                        {auth && approveJob?.status !== 'Cancel' && userData?.userData?.id === approveJob?.service?.id ?
                                          <>
                                            {approveJob.status === 'Approved' ?
                                              approveJob?.status !== 'Completed' ?

                                                <button className="btn btn-sm bg-info-light" onClick={() => StartServiceProviderJob(approveJob?.id, approveJob?.job?.id)}>
                                                  <i className="far fa-hand-pointer"></i> Start Job
                                                </button>
                                                : ''
                                              : ''}
                                          </>
                                          : ''
                                        }
                                        {auth && approveJob?.status === 'Completed' && userData?.userData?.id === approveJob?.user_id ?
                                          <>
                                            <span className="badge bg-primary-light" style={{cursor:'pointer',margin:'5px'}} onClick={() => Ratingpopup(approveJob?.id, approveJob?.job?.id, approveJob?.job?.title)}>
                                              <i className="far fa-star"></i> Rating
                                            </span>
                                            <br />
                                          </>
                                          : ''
                                        }
                                        {auth && approveJob?.status !== 'Cancel' && userData?.userData?.id === approveJob?.customer?.id ?
                                          <>
                                            <span className="badge bg-primary-light" style={{cursor:'pointer',margin:'5px'}} onClick={() => opnActionjobPopup(approveJob?.id, approveJob?.job?.id, approveJob?.job?.title)}>
                                              <i className="far fa-hand-pointer"></i> Job Action
                                            </span>
                                          </>
                                          : ''
                                        }
                                        {auth && approveJob?.status !== 'Cancel' ?
                                          <>
                                            {approveJob?.status === 'Processing' || approveJob?.status === 'Approved' ?
                                              <Link to={`/user/job/message/${approveJob.id}`} className="badge bg-info-light">
                                                <i className="far fa-comments"></i> Message  <span className="badge badge-pill" style={{fontSize:'13px', color:'black'}}>{approveJob?.job_bid_message_count}</span>
                                              </Link>
                                              : ''}
                                            {approveJob?.status === 'Completed' ?
                                              <span className="badge bg-secondary-light" style={{cursor:'pointer',margin:'5px'}} onClick={() => generateInvoice(approveJob?.id)}>
                                                <i className="far fa-file-pdf"></i> Invoice
                                              </span>
                                              : ''}
                                          </>
                                          : ''
                                        }
                                        <span className="badge bg-warning-light" style={{cursor:'pointer',margin:'5px'}} onClick={() => openTicketModal(approveJob?.id, approveJob?.job?.id, approveJob?.bid_id, approveJob?.job?.title)}>
                                          <i className="fa fa-ticket"></i> Ticket Raise
                                        </span>

                                        {auth && approveJob?.status === 'Completed' && userData?.userData?.id === approveJob?.service?.id ?
                                          profileData[0]?.payment_confirmed === 0 ?
                                          <span className="badge bg-success-light" style={{cursor:'pointer', marginLeft:'5px'}} onClick={() => payFeesToAdmin(approveJob?.id, approveJob?.job?.title)}>
                                              <i className="fa fa-ticket"></i> Pay fees
                                            </span>
                                            : '' : ''
                                        }

                                      </td>
                                    </tr>
                                  )
                                }
                                )
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const Required = styled.span`
  color: red;
`;
