import { all, call, put } from "redux-saga/effects";
import { Creators } from "./index";
import { WebService } from "../../../services/axios/webServices";
import { apiEndPoints } from "../../../services/axios/endPoints";
import { setAccessTokenToHeader } from "../../../services/axios/helper";
// import AuthService from "../../../services/axios/auth.servies";
import { getUserDataFromLocal, setUserDataToLocal } from "../../../shared/utility/helper";
import {
  deleteLocalStorage,
  setToLocalStorage,
} from "../../../shared/utility/localStorage";


export function* loginSaga(loginDetail) {
  
  const { loginDetails } = loginDetail;
  const { email } = loginDetails;
  try {
    yield put(Creators.onLoginStart());
    const loginResp = yield WebService.post(
      apiEndPoints.login,
      JSON.stringify(loginDetails)
    );

    // console.log(loginResp, 'login')
    // return false;
    

    if (loginResp.status === 200) {
      
      const { token, data, msg } = loginResp.data;
      const { email, name } = data;
      setAccessTokenToHeader(token);

      setUserDataToLocal({
        token,
        userData: {
          ...data,
          token,
          email,
          name,
        },
      });

      setToLocalStorage({data, token});

      yield all([
        put(
          Creators.setUserDetails({
            token,
            userData: { ...data, email, name },
          })
        ),
        put(Creators.onLoginSuccess({ msg, authenticated: true })),
      ]);
    } else if (loginResp.status === false) {
      // console.log(loginResp, "===========");
    } else {
      throw loginResp;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        
        case 400:
          // eslint-disable-next-line max-len
          yield put(
            Creators.onLoginFail({ error: error?.response?.data?.message })
          );
          break;
        case 404:
          // eslint-disable-next-line max-len
          yield put(
            Creators.onLoginFail({ error: "Email and password is wrong!" })
          );
          yield put(
            Creators.onLoginFail({
              error: error?.response?.data?.msg,
              errorArr: error.response.data.data,
            })
          );
          break;
        case 422:
          yield put(
            Creators.onLoginFail({
              error: "Please fill all details",
            })
          );
          break;
        case 500:
          yield put(
            Creators.onLoginFail({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            Creators.onLoginFail({
              error: "Unable to update. Try again later",
            })
          );
      }
    }
  }
}
export function* loginOTPSaga(loginDetailOTP) {
  
  const { loginDetailsOTP } = loginDetailOTP;
  const { phone_number } = loginDetailsOTP;
  // console.log('loginDetailOTP', loginDetailOTP)
  try {
    yield put(Creators.onLoginOTPStart());
    const loginResp = yield WebService.post(
      apiEndPoints.otpVerify,
      JSON.stringify(loginDetailsOTP)
    );
    if (loginResp.status === 200) {
      
      const { token, data, msg } = loginResp.data;
      const { phone_number, name } = data;
      setAccessTokenToHeader(token);

      setUserDataToLocal({
        token,
        userData: {
          ...data,
          token,
          phone_number,
          name,
        },
      });

      setToLocalStorage({data, token});

      yield all([
        put(
          Creators.setUserDetails({
            token,
            userData: { ...data, phone_number, name },
          })
        ),
        put(Creators.onLoginOTPSuccess({ msg, authenticated: true })),
      ]);
    } else if (loginResp.status === false) {
      // console.log(loginResp, "===========");
    } else {
      throw loginResp;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
       case 400:
          // eslint-disable-next-line max-len
          yield put(
            Creators.onLoginOTPFail({ error: error?.response?.data?.message })
          );
          break;
        case 404:
          // eslint-disable-next-line max-len
          yield put(
            Creators.onLoginOTPFail({ error: "Email and password is wrong!" })
          );
          yield put(
            Creators.onLoginOTPFail({
              error: error?.response?.data?.msg,
              errorArr: error.response.data.data,
            })
          );
          break;
        case 422:
          // console.log('error', error)
          yield put(
            Creators.onLoginOTPFail({
              error: error?.response?.data?.data?.mobile_number
            })
          );
          break;
        case 500:
          yield put(
            Creators.onLoginOTPFail({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            Creators.onLoginOTPFail({
              error: "Unable to update. Try again later",
            })
          );
      }
    }
  }
}


export function* onLogoutSaga(logoutDetails) {
  try {
    const userData = getUserDataFromLocal();
      const response = yield WebService.post(
        apiEndPoints.userLogout,
        JSON.stringify({device_token: localStorage.getItem('device_token'), android_device_token: localStorage.getItem('android_device_token')}),
        {
          headers: { Authorization: "Bearer " + userData?.token },
        }
      )
      if(response.status === 200)
      {
        yield put(Creators.onLogoutSuccess());
        setUserDataToLocal();
        deleteLocalStorage();
      }
      else{
        yield put(Creators.onLogoutSuccess());
        setUserDataToLocal();
        deleteLocalStorage();
      }
      
  } catch (error) {
    console.log(error);
    // yield put(Creators.onLogoutSuccess());
    // setUserDataToLocal();
    // deleteLocalStorage();
  }
}
export function* onLogoutAllDeviceSaga() {
  try {
    const userData = getUserDataFromLocal();
      const response = yield WebService.post(
        apiEndPoints.logoutAlldevice,
        JSON.stringify(),
        {
          headers: { Authorization: "Bearer " + userData?.token },
        }
      )
      if(response.status === 200)
      {
        yield put(Creators.onLogoutAllDeviceSuccess());
        setUserDataToLocal();
        deleteLocalStorage();
      }
      else{
        yield put(Creators.onLogoutAllDeviceSuccess());
        setUserDataToLocal();
        deleteLocalStorage();
      }
      
  } catch (error) {
    console.log(error);
    // yield put(Creators.onLogoutSuccess());
    // setUserDataToLocal();
    // deleteLocalStorage();
  }
}


// export function* registerSaga(loginDetail) {
//   const { loginDetails } = loginDetail;
//   const { email } = loginDetails;
//   try {
//     yield put(Creators.onLoginStart());
//     const loginResp = yield WebService.post(
//       apiEndPoints.login,
//       JSON.stringify(loginDetails)
//     );

//     if (loginResp.status === 200) {
//       const { token, data, msg } = loginResp.data;
//       const { email, name } = data;
//       console.log(data, 'loginres');
//       yield setAccessTokenToHeader(token);
//       setUserDataToLocal({
//         token,
//         userData: {
//           ...data,
//           email,
//           name,
//         },
//       });

//       setToLocalStorage(data);

//       yield put(
//         Creators.setUserDetails({
//           token,
//           userData: { ...data, email, name },
//         })
//       );

//       yield put(Creators.onLoginSuccess());
//     } else if (loginResp.status === false) {
//       console.log(loginResp, '===========');
//     } else {
//       throw loginResp;
//     }
//   } catch (error) {
//     if (error?.response?.status) {
//       switch (error.response.status) {
//         case 400:
//           // eslint-disable-next-line max-len
//           yield put(
//             Creators.onLoginFail({ error: error?.response?.data?.message })
//           );
//           break;
//         case 500:
//           yield put(
//             Creators.onLoginFail({
//               error: 'Somthing went wrong try later',
//             })
//           );
//           break;
//         default:
//           yield put(
//             Creators.onLoginFail({
//               error: 'Unable to update. Try again later',
//             })
//           );
//       }
//     } else {
//       yield put(
//         Creators.onLoginFail({ error: 'No connection try again Later.' })
//       );
//     }
//   }
// }
