import { call, put } from "redux-saga/effects";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints } from "../../../../services/axios/endPoints";
import { ProfileCreators } from "./index";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";

let userData = getUserDataFromLocal();

export function* getProfileSaga() {
  // console.log("called");
  try {
    yield put(ProfileCreators.getProfileStart());
    const response = yield WebService.get(apiEndPoints.getProfile, {
      headers: { Authorization: "Bearer " + userData.token },
    });
    // const response = true;

    if (response.status) {
      // console.log(response, "response");
      //   const { data } = response.data;
      yield put(ProfileCreators.getProfileSuccess(response.data.data));
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          yield put(
            ProfileCreators.getCategoriesFailure({
              error: error?.response?.data?.message,
            })
          );
          break;
        case 500:
          yield put(
            ProfileCreators.getCategoriesFailure({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            ProfileCreators.getCategoriesFailure({
              error: "Unable to update. Try again later",
            })
          );
      }
    } else {
      yield put(
        ProfileCreators.getCategoriesFailure({
          error: "No connection try again Later.",
        })
      );
    }
  }
}

export function* getCategoriesSaga() {
  try {
    yield put(ProfileCreators.getCategoriesStart());
    const response = yield WebService.get(apiEndPoints.getUserCategory, {
      headers: { Authorization: "Bearer " + userData.token },
    });
    // const response = true;

    if (response.status) {
      // console.log(response, "response");
      //   const { data } = response.data;
      yield put(ProfileCreators.getCategoriesSuccess(response.data.data));
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          yield put(
            ProfileCreators.getCategoriesFailure({
              error: error?.response?.data?.message,
            })
          );
          break;
        case 500:
          yield put(
            ProfileCreators.getCategoriesFailure({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            ProfileCreators.getCategoriesFailure({
              error: "Unable to update. Try again later",
            })
          );
      }
    } else {
      yield put(
        ProfileCreators.getCategoriesFailure({
          error: "No connection try again Later.",
        })
      );
    }
  }
}

export function* getDashCategoriesSaga() {
  try {
    yield put(ProfileCreators.getDashCategoriesStart());
    const response = yield WebService.get(apiEndPoints.dashCategory);
    // const response = true;

    if (response.status === 200) {
      // console.log('reponse', response)
      //   const { data } = response.data;
      yield put(ProfileCreators.getDashCategoriesSuccess(response.data.data));
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          yield put(
            ProfileCreators.getDashCategoriesFailure({
              error: error?.response?.data?.message,
            })
          );
          break;
        case 500:
          yield put(
            ProfileCreators.getDashCategoriesFailure({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            ProfileCreators.getDashCategoriesFailure({
              error: "Unable to update. Try again later",
            })
          );
      }
    } else {
      yield put(
        ProfileCreators.getDashCategoriesFailure({
          error: "No connection try again Later.",
        })
      );
    }
  }
}

export function* getJobListSaga({ payload }) {
  
  const { page, params } = payload;
  try {
    yield put(ProfileCreators.getJobListStart());
    const response = yield WebService.get(
      `${apiEndPoints.jobListing}${params}&page=${page}`,{
        headers: { Authorization: "Bearer " + userData.token },
      }
    );
    

    if (response.status === 200) {
      yield put(
        ProfileCreators.getJobListSuccess({
          data: response.data.data.data,
          count: response.data.data,
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          yield put(
            ProfileCreators.getJobListFailure({
              error: error?.response?.data?.message,
            })
          );
          break;
        case 500:
          yield put(
            ProfileCreators.getJobListFailure({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            ProfileCreators.getJobListFailure({
              error: "Unable to update. Try again later",
            })
          );
      }
    } else {
      yield put(
        ProfileCreators.getJobListFailure({
          error: "No connection try again Later.",
        })
      );
    }
  }
}

export function* getApprovedJobListSaga({ payload }) {
  // console.log( "payload approved job list saga");
  // const { page } = payload;
  // console.log('page', page)
  try {
    yield put(ProfileCreators.getApprovedJobListStart());
    const response = yield WebService.get(
      `${apiEndPoints.approvedJobList}`
      , {
        headers: { Authorization: "Bearer " + userData.token },
      });
    // const response = true;
    
    // console.log('response new', response)

    if (response.status === 200) {
      //   const { data } = response.data;
      // console.log('response new', response)
      yield put(
        ProfileCreators.getApprovedJobListSuccess({
          data: response.data,
          count: response,
        })
      );
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          yield put(
            ProfileCreators.getApprovedJobListFailure({
              error: error?.response?.data?.message,
            })
          );
          break;
        case 500:
          yield put(
            ProfileCreators.getApprovedJobListFailure({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            ProfileCreators.getApprovedJobListFailure({
              error: "Unable to update. Try again later",
            })
          );
      }
    } else {
      yield put(
        ProfileCreators.getApprovedJobListFailure({
          error: "No connection try again Later.",
        })
      );
    }
  }
}
