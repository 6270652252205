import { createReducer } from "reduxsauce";
import { NationTypes } from "./action";


const INITIAL_STATE = {
    loading: false,
    nationList: [],
    error: ''
}


const getNationListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true
    }
}

const getNationListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        loading: false,
        nationList: data
    }
}

const getNationListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        loading: false
     }
}


const HANDLERS = {
    [NationTypes.GET_NATION_LIST_START]: getNationListStart,
    [NationTypes.GET_NATION_LIST_SUCCESS]: getNationListSuccess,
    [NationTypes.GET_NATION_LIST_FAILURE]: getNationListFailure
}

export const nationReducer = createReducer(INITIAL_STATE, HANDLERS)