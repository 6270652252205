import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/axios/auth.servies";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AuthCreator, Creators } from "./store/index";
import { debounce } from "lodash";
// import { WebService } from "../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../services/axios/endPoints";
// import { setAccessTokenToHeader } from "../../services/axios/helper";
import { LocalNotifications } from '@capacitor/local-notifications';
import validator from 'validator'
import Button from 'react-bootstrap-button-loader';



import {
  getUserDataFromLocal,
  setUserDataToLocal,
} from "../../shared/utility/helper";
import toast, { Toaster } from "react-hot-toast";
import { ErrorP } from "../../Common/Modal/Modal";
import { isEmail } from "../../shared/utility/regex";
import Loader from "../../Common/Loader/Loader";
import { SocialCreators } from "../Pages/Landing/Social/store";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';





export const Login = () => {
  const userData = getUserDataFromLocal();
  const { error, loading, authenticated, loginmsg } = useSelector(
    (state) => state.loginData
  );
  const form = useRef();
  const checkBtn = useRef();
  const [loginInput, setLoginInput] = useState({ email: "", password: "", device_token:localStorage.getItem('device_token'), android_device_token: localStorage.getItem('android_device_token') });
  const [message, setMessage] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorList, seterrorList] = useState([])
  const [passwordType, setPasswordType] = useState("password");
  const [errorPhone, setErrorPhone] = useState(false)


  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setLoginInput({ ...loginInput, [name]: value });
  };
  const togglePassword =()=>{
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }

  const handleLogin = async () => {
    dispatch(AuthCreator.login(loginInput));
    // setLoginInput({ email: '', password: '' });
    // dispatch(ProfileCreators.getProfile());
  };

  const debounceOnLogin = useCallback(debounce(handleLogin, 400), [
    handleLogin,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loginInput.email !== "" && loginInput.password !== "") {
      debounceOnLogin();
      if (error !== "" && loginmsg === "") {
        toast.error(error, { position: "top-center" });
      }
    } else {
      setIsEmpty(true);
    }
  };

  useEffect(() => {
    if (authenticated) {
      navigate("/");
    }
  }, [authenticated]);

  useEffect(() => {
    dispatch(Creators.onLoginSuccess({ msg: "", authenticated: false }));
  }, [loginInput]);

  const { socialList, socialloading } = useSelector((state) => state.socialData);

  useEffect(() => {
    dispatch(SocialCreators.getSocialList());
  }, []);

  useEffect(() => {
    if (error) {
      console.log(error)
      toast?.error(error, { position: "top-center" });
    }
  }, [error]);

  const OpenHomePage = () => {
    navigate('/')
  }

  // LocalNotifications.schedule({
  //   notifications: [
  //     {
  //       title: "On sale",
  //       body: "Widgets are 10% off. Act fast!",
  //       id: 1,
  //       schedule: { at: new Date(Date.now()) },
  //       sound: null,
  //       attachments: null,
  //       actionTypeId: "",
  //       extra: null
  //     }
  //   ]
  // });

  const validatePhoneNumber = (number) => {
    const isValidPhoneNumber = validator.isMobilePhone(number)
    return (isValidPhoneNumber)
   }

  // const handleOtpLogin = (loginInput) => {
  //     const phone = loginInput?.email;
  //     if( !(phone.match('[0-9]{10}')) ){
  //         console.log('Please provide valid phone number');
  //         setErrorPhone({phone: 'Please provide valid phone number.'})
  //     }else{
  //       setErrorPhone({phone:''})
  //       console.log('good to go there');
  //     }
  //     console.log('errorPhone', errorPhone)
  // }

  return (
    <>
      <Toaster />
      <div className="bg-pattern-style"  style={{background: 'url('+rootConfig?.base_url+socialList?.loginImg+') no-repeat'}}>
        <div className="content">
          <div className="account-content">
            <div className="account-box">
              <div className="login-right">
                <div className="login-header">
                {/* <img src={logoshramsvg} className="img-fluid" alt="Serviceora" style={{ maxHeight: "30px" }} /> */}
                  <h3>
                    Login <span onClick={() => OpenHomePage()} style={{ cursor: "pointer" }}>Serviceora</span>
                  </h3>
                  <p className="text-muted">Access to our dashboard</p>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="form-control-label">Email Address</label>
                    <input
                      type="text"
                      name="email"
                      value={loginInput.email}
                      className="form-control"
                      onChange={handleOnChange}
                      placeholder="Your Email address"
                    />
                    {loginInput?.email === "" && isEmpty ? (
                      <ErrorP>The email field is required!</ErrorP>
                    ) : !isEmail(loginInput?.email) &&
                      loginInput?.email !== "" &&
                      isEmpty ? (
                      <ErrorP>The email must be a valid email address!</ErrorP>
                    ) : null}
                    {
                      errorPhone?.phone === "" && isEmpty ? 
                        null
                      : <ErrorP>{errorPhone?.phone}</ErrorP>
                    }
                  </div>

                  <div className="form-group">
                    <label className="form-control-label">Password</label>
                    <div className="pass-group">
                      <input
                        type={passwordType}
                        name="password"
                        value={loginInput.password}
                        className="form-control pass-input"
                        onChange={handleOnChange}
                        placeholder="Password"
                        autoComplete="off" 
                      />
                      <i className={`toggle-password ${passwordType===`password` ? `fas fa-eye-slash` : `fas fa-eye`}`} onClick={togglePassword}></i>
                    </div>
                    {loginInput?.password === "" && isEmpty ? (
                      <ErrorP>The password field is required!</ErrorP>
                    ) : null}
                  </div>
                  <div className="text-start">
                      <Link to="/login-with-mobile" className="forgot-link" style={{color:'#009da6'}}> Login with Mobile Number ? </Link>
                  </div>
                  
                  <div className="text-end">
                    <Link className="forgot-link" to="/forgot-password">Forgot Password ?</Link>
                  </div>
                  <Button className="btn btn-primary login-btn" type="submit" loading={loading ? true : false}>Login!</Button>
                  <span style={{color:'green'}}>(no service charges)</span>
                  <div className="text-center dont-have">
                  {/* <GoogleOAuthProvider clientId="175267694169-op0f7pvmp5pgdov66d2slqiv67kti5g7.apps.googleusercontent.com">
                  <GoogleLogin
                      onSuccess={credentialResponse => {
                        console.log(credentialResponse);
                      }}
                      onError={() => {
                        console.log('Login Failed');
                      }}
                    />
                  </GoogleOAuthProvider> */}
                  {/* <button className="btn btn-primary login-btn" type="submit">
                    Login With Phone Number
                  </button> */}
                  
                    Don’t have an account? <Link to="/register"> Register here!</Link> 
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
