import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import Loader from "../../../../Common/Loader/Loader";
import { SideBar } from "../SideBar";
import { ProfileCreators } from "../Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import moment from "moment";
import "./style.css";

export const JobMessage = () => {
  const { job_id } = useParams();
  const divRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [jobApproveMessageList, setJobApproveMessageList] = useState([]);
  const [input, setInput] = useState({
    approve_id: job_id,
    message: "",
  });
  const [errorColor, setErrorColor] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(null);
  const userData = getUserDataFromLocal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileData } = useSelector((state) => state.profileData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  
  const showJobMessage = async () => {
    setLoading(true);

    try {
      const response = await WebService.get(apiEndPoints.JobMessageList, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },

        params: {
          approve_id: job_id,
        },
      });
      if (response.status === 200) {
        setJobApproveMessageList(response.data.data);
        setLoading(false);
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
      } else if (response.response.status === 429) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
      } else {
        toast.error("something went wrong!", {
          style: ToastSuccess.style,
          iconTheme: ToastSuccess.iconTheme,
          position: "top-center",
        });
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
    }
  };

  useEffect(() => {
    showJobMessage();
  }, []);
  const handleAddJob = () => {
    setIsModalVisible("job");
  };

  

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitMessage()
  }

  const handleSubmitMessage = async () => {
    try {
      // dispatch(publishedCreators.getCreatedJobStart());
      const res = await WebService.post(
        apiEndPoints.JobMessageStatus,
        JSON.stringify(input),
        {
          headers: { Authorization: "Bearer " + userData.token },
        }
      );
      // console.log('satts', res)
      if (res.status === 200) {
        toast.success(res.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        setInput({
          approve_id: job_id,
          "status": ""
        })
        showJobMessage();


        //   dispatch(
        //     publishedCreators.getCreatedJobSuccess({ data: res.data.data })
        //   );
      } else if (res.response.status === 404) {
        toast.error(res.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
      } else if (res.response.status === 422) {
        toast.error(res.response?.data?.data?.message[0], {
          ToastWarning,
          position: "top-center",
        });
        setErrorColor(true)
      } else {
        toast.error("something went wrong.", {
          ToastWarning,
          position: "top-right",
        });
      }
    } catch (err) {
      // dispatch(publishedCreators.getCreatedJobFailure());
      toast?.error(err, { position: "top-right" });
    }
  };

  const handleMessageRead = async() => {
    try {
      // dispatch(publishedCreators.getCreatedJobStart());
      const res = await WebService.post(
        apiEndPoints.jobMessageUpdate,
        JSON.stringify({'approve_id': job_id}),
        {
          headers: { Authorization: "Bearer " + userData.token },
        }
      );
    } catch (err) {
      // dispatch(publishedCreators.getCreatedJobFailure());
      toast?.error(err, { position: "top-right" });
    }
  }
  useEffect(() => {

    divRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const ScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }));
    return <div ref={elementRef} />;
  };

  useEffect(() => {
    handleMessageRead()
  }, []);


  return (
    <>
      <Toaster />
      {loading ? (
        <Loader containerHeight={70} width="4rem" />
      ) : (
        <div className="main-wrapper">
          <div className="breadcrumb-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <Link to="/user/dashboard">Messages</Link>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="breadcrumb-title">Message</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <SideBar
                  handleAddJob={handleAddJob}
                  profileData={profileData}
                />
                <div className="col-md-7 col-lg-8 col-xl-9">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card card">
                        <div className="card-body">
                          <div
                            className="chat-cont-left"
                            style={{ maxWidth: "100%" }}
                          >
                            <div className="col-12 col-md-6 ">
                              <div className="login-right">
                                <div className="login-header">
                                  <p className="capitalize">
                                    <Link to="/user/dashboard"><i className="fas fa-long-arrow-alt-left"></i> <span>Back</span></Link> </p>
                                </div>
                              </div>
                            </div>
                            <div className="chat-header">
                              <span>
                                {jobApproveMessageList?.[0]?.job?.title} - Message <span className="badge badge-info" style={{fontSize:'10px'}}>{jobApproveMessageList?.[0]?.job?.status}</span>
                              </span>
                            </div>
                            <div className="chat-users-list">
                              <div className="chat-scroll">
                                {jobApproveMessageList
                                  ?.length >= 1 &&
                                  jobApproveMessageList?.map(
                                    (message, index) => {
                                      return (
                                        <Link
                                          key={index}
                                          to="#"
                                          className="media d-flex"
                                        >
                                          <div className="media-img-wrap flex-shrink-0">
                                            <div className="avatar">
                                              <img
                                                src={
                                                  rootConfig?.base_url +
                                                  message?.sender?.profile
                                                }
                                                alt="User Image"
                                                className="avatar-img rounded-circle"
                                              />
                                            </div>
                                          </div>
                                          <div className="media-body flex-grow-1">
                                            <div>
                                              <div
                                                className="user-name"
                                                style={{ fontSize: "13px", textAlign: "left" }}
                                              >
                                                {message?.sender?.name}
                                              </div>
                                              <div className="user-last-chat" style={{ overflow: "visible" }}>
                                                {message?.message}
                                              </div>
                                            </div>
                                            <div>
                                              <div className="last-chat-time block">
                                                {moment(message?.created_at).fromNow()}
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      );
                                    }
                                  )}
                                <ScrollToBottom />
                              </div>
                            </div>
                            <div className="chat-footer" ref={divRef}>
                              <form noValidate onSubmit={handleSubmit}>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    name="message"
                                    className="input-msg-send form-control"
                                    placeholder="Type something..."
                                    onChange={handleChange} autoComplete="off" style={{ border: `${errorColor ? `1px groove red` : ``}` }} />
                                  <button type="submit" className="btn btn-sm btn-msg bg-info-light">
                                    Send
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
