import { createReducer } from "reduxsauce";
import { SubcategoryTypes } from "./action";


const INITIAL_STATE = {
    subcategoryloading: false,
    subcategoryList: [],
    error: ''
}


const getSubcategoryListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        subcategoryloading: true
    }
}

const getSubcategoryListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        subcategoryloading: false,
        subcategoryList: data
    }
}

const getSubcategoryListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        subcategoryloading: false
     }
}


const HANDLERS = {
    [SubcategoryTypes.GET_SUBCATEGORY_LIST_START]: getSubcategoryListStart,
    [SubcategoryTypes.GET_SUBCATEGORY_LIST_SUCCESS]: getSubcategoryListSuccess,
    [SubcategoryTypes.GET_SUBCATEGORY_LIST_FAILURE]: getSubcategoryListFailure
}

export const SubcategoryReducer = createReducer(INITIAL_STATE, HANDLERS)