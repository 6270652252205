import { ProfileCreators, ProfileTypes } from "../view/Pages/Dashboard/store";
import {
  updateProfileCreators,
  updateProfileTypes,
} from "../view/Pages/Dashboard/Profile/store";
import {
  Types as LoginTypes,
  Creators as LoginCreators,
} from "../view/auth/store";
import {
  LandingPageCreators,
  LandingPageTypes,
} from "../view/Pages/Landing/store";
import { MyJobTypes, MyJobCreators } from "../view/Pages/Dashboard/MyJob/store"
import { NationalTypes, NationalCreators } from "../view/Pages/Dashboard/National/store"
import { NationTypes, NationCreators } from "../view/Pages/Landing/Nation/store"
import { CategoriesTypes, CategoriesCreators } from "../view/Pages/Landing/Categories/store"
import { JobInvoiceTypes, JobInvoiceCreators } from "../view/Pages/Dashboard/JobInvoice/store"
import { SubcategoryTypes, SubcategoryCreators } from "../view/Pages/Landing/Subcategory/store";
import { TicketRaiseTypes, TicketRaiseCreators } from "../view/Pages/Dashboard/TicketRaise/store";
import { TestimonialsTypes, TestimonialsCreators } from "../view/Pages/Landing/Testimonial/store";
import { SocialTypes, SocialCreators } from "../view/Pages/Landing/Social/store";
import { UserNotificationTypes, UserNotificationCreators } from "../view/Pages/Dashboard/UserNotification/store";
import { CoinsTypes, CoinsCreators } from "../view/Pages/Dashboard/Coins/store";
import { ServiceProviderTypes, ServiceProviderCreators } from "../view/Pages/Landing/FindServiceProviderList/store";
import { EnquiryTypes, EnquiryCreators } from "../view/Pages/Dashboard/Enquiry/store";
import { EarnmoneyTypes, EarnmoneyCreators } from "../view/Pages/Dashboard/Earnmoney/store";
import { CityTypes, CityCreators } from "../view/Pages/Landing/City/store";


const Types = {
  ...LoginTypes,
  ...ProfileTypes,
  ...updateProfileTypes,
  ...LandingPageTypes,
  ...MyJobTypes,
  ...NationalTypes,
  ...NationTypes,
  ...CategoriesTypes,
  ...JobInvoiceTypes,
  ...SubcategoryTypes,
  ...TicketRaiseTypes,
  ...TestimonialsTypes,
  ...SocialTypes,
  ...UserNotificationTypes,
  ...CoinsTypes,
  ...ServiceProviderTypes,
  ...EnquiryTypes,
  ...EarnmoneyTypes,
  ...CityTypes,
};

const Creators = {
  ...LoginCreators,
  ...ProfileCreators,
  ...updateProfileCreators,
  ...LandingPageCreators,
  ...MyJobCreators,
  ...NationalCreators,
  ...NationCreators,
  ...CategoriesTypes,
  ...JobInvoiceCreators,
  ...SubcategoryCreators,
  ...TicketRaiseCreators,
  ...TestimonialsCreators,
  ...SocialCreators,
  ...UserNotificationCreators,
  ...CoinsCreators,
  ...ServiceProviderCreators,
  ...EnquiryCreators,
  ...EarnmoneyCreators,
  ...CityCreators
};

export { Types, Creators };
