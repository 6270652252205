import { createReducer } from "reduxsauce";
import { CategoriesTypes } from "./action";


const INITIAL_STATE = {
    categoryloading: false,
    categoriesList: [],
    error: ''
}


const getCategoriesListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        categoryloading: true
    }
}

const getCategoriesListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        categoryloading: false,
        categoriesList: data
    }
}

const getCategoriesListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        categoryloading: false
     }
}


const HANDLERS = {
    [CategoriesTypes.GET_CATEGORIES_LIST_START]: getCategoriesListStart,
    [CategoriesTypes.GET_CATEGORIES_LIST_SUCCESS]: getCategoriesListSuccess,
    [CategoriesTypes.GET_CATEGORIES_LIST_FAILURE]: getCategoriesListFailure
}

export const categoriesReducer = createReducer(INITIAL_STATE, HANDLERS)