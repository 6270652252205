import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchableDropdown from "../../../../Common/SearchDropDown/SearchInput";
import { ErrorP } from "../../../../Common/Modal/Modal";
import { isEmail, isMobilePhone } from "../../../../shared/utility/regex";
import { styled } from "styled-components";
import Loader from "../../../../Common/Loader/Loader";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import blankImg from "../../../../assets/img/logo/blank.png";
import Select from "react-select";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import PlacesPicker from '@tasiodev/react-places-autocomplete'
import { MultiSelect } from '../../../../Common/MultiSelect/MultiSelect'
import { SubcategoryCreators } from "../../Landing/Subcategory/store";
import InputMask from "react-input-mask";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';

import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import OtpInput from 'react-otp-input';
import Button from 'react-bootstrap-button-loader';




import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import {
  GetCountries
} from "react-country-state-city";
import { WebService } from "../../../../services/axios/webServices";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";

export const UpdateForm = ({
  handleSubmit,
  handleImageChange,
  updateInput,
  handleChange,
  handleChange1,
  handleChangePhone,
  handleChangePhone1,
  handleNation,
  isEmpty,
  setSearchCountry,
  searchCountry,
  setStateData,
  searchState,
  setSearchState,
  cityData,
  searchCity,
  setCityData,
  setSearchCity,
  stateData,
  profileData,
  setUpdateInput

}) => {
  const { updatemsg, profileLoading } = useSelector(
    (state) => state.updateProfileData
  );
  const { nationList, nationLoading } = useSelector(
    (state) => state.updateProfileData
  );
  const dispatch = useDispatch();
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();

  const [countryid, setCountryid] = useState(101);
  const [stateid, setstateid] = useState(0);
  const [value, setValue] = useState(null)
  const [otp, setOtp] = useState('');
  const [verifyPhone, setVerifyPhone] = useState({})


  const [countryName, setCountryName] = useState('');
  const [stateName, setStateName] = useState('');
  const [cityName, setCityName] = useState('');
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [skilled, setSkilled] = useState({})
  const [skilledInput, setSkillInput] = useState({})
  const [skilledP, setSkilledP] = useState([])
  const [isVerifyPhoneModal, setIsVerifyPhoneModal] = useState(false)
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const [errorList, seterrorList] = useState([])

  const { subcategoryList } = useSelector((state) => state.subcategoryData);

  useEffect(() => {
    dispatch(SubcategoryCreators.getSubcategoryList());
  }, []);

  const options = subcategoryList.map(subcategory => ({
    "value": subcategory?.id,
    "label": subcategory?.name
  }))

  const handleUpdatePhone = async () => {
    try {
      const response = await WebService.post(
        apiEndPoints.verifyPhone,
        JSON.stringify({ phone_number: updateInput?.phone_number }),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        setIsVerifyPhoneModal(true)
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
      }else if (response.response.status === 422) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        seterrorList(response.response.data?.data)
      }
      else {
        toast.error('something went wrong!', {
          position: "top-center",
        });
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }
  }



  useEffect(() => {
    GetCountries().then((result) => {
      // console.log('countries', countries)
      // if (countryid === 101) {
        setCountries(...countries, result[101])
        setUpdateInput({ ...updateInput, 'country': countryid, countryObj: (countryid === 101) ? result[101] : result })
      // }
      // else {
      //   setCountries(...countries, result)
      //   setUpdateInput({ ...updateInput, 'country': countryid, countryObj: (countryid === 101) ? result[101] : result })
      // }

    });
  }, []);

  // console.log('profile', JSON.parse(updateInput?.skilled[0]?.value))

  const handleChangeSkilled = (e) => {
    // setSkilled(skilled => [...skilled, e])
    // const ele = JSON.stringify(e)
    // setSkillInput({...skilled, e})
    setUpdateInput({ ...updateInput, skilled: e })
    // console.log('e', e)
    // console.log('skilled', skilled)


  }

  useEffect(() => {

    setVerifyPhone({ ...verifyPhone, otp_code: otp, phone_number:updateInput?.phone_number});

    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      
  
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
  
    return () => {

      clearInterval(interval);
    };
  }, [seconds]);

  const handleResendOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await WebService.post(
        apiEndPoints.resendOTP,
        JSON.stringify({phone_number:profileData[0]?.phone_number}),
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
      } else if (response.response.status === 422) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
      }
      else {
        toast.error('something went wrong!', {
          position: "top-center",
        });
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }
  }

  const handleUpdatePhoneWithOTP = async(e) => {
    e.preventDefault();
    try {
      const response = await WebService.post(
        apiEndPoints.updatePhoneWithOtp,
        JSON.stringify({phone_number: updateInput?.phone_number,otp_code:verifyPhone?.otp_code}),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        setIsVerifyPhoneModal(false)
        navigate('/user/profile')
      } else if (response.response.status === 422) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
      }
      else {
        toast.error('something went wrong!', {
          position: "top-center",
        });
        setIsVerifyPhoneModal(false)
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
      setIsVerifyPhoneModal(false)
    }


  }

  const handleUpdateEmail = async(e) => {
    e.preventDefault();
    try {
      const response = await WebService.post(
        apiEndPoints.updateEmailVerify,
        JSON.stringify({email:updateInput?.email}),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
        
        navigate('/user/profile')
      } else if (response.response.status === 422) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
        seterrorList(response.response.data?.data)
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
      }
      else {
        toast.error('something went wrong!', {
          position: "top-center",
        });
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }

  }

  return (
    <>
      {isVerifyPhoneModal && (
        <Modal
          show={true}
          size="xs"
          onHide={() => setIsVerifyPhoneModal(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-xs"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}></span> Verify Your Phone Number</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <h6 className="text-center">Please enter the one time password <br /> to verify your account</h6>
                      <div className="text-center"> <span>A code has been sent to</span> <small>{updateInput?.phone_number.replace(/.(?=.{4,}$)/g, '*')}</small> </div>
            <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
            <OtpInput
              inputStyle="inputStyle"
              value={otp}
              onChange={(e) => {
                setOtp(e);
                setVerifyPhone({ ...verifyPhone, otp_code: otp });
              }}
              separator={<span>-</span>}
              isInputSecure='true'
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            </div>
            <div className="mt-4 text-center"> <button className="btn btn-course col-6" onClick={handleUpdatePhoneWithOTP} type="submit">Verify</button> </div>
            <br/>
            {seconds > 0 || minutes > 0 ? (
              <p style={{color:'red'}} className="text-center">
                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            ) : (
              ''
            )}
            {/* {seconds > 0 || minutes > 0 ? (
              ''
            ) : (
              <div className="card-2">
                <div className="justify-content-center align-items-center text-center"> <span>Didn't get the code</span>
                  <Link className="text-decoration-underline text-color-green ms-3" onClick={handleResendOTP}>Resend OTP </Link> 
                </div>
              </div>
            )} */}
          </Modal.Body>
        </Modal>
      )}
      <div className="col-md-7 col-lg-8 col-xl-9">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row form-row">
                <div className="col-12 col-md-12">
                  <div className="form-group">
                    <div className="change-avatar">
                      <div className="profile-img">
                        <img
                          src={
                            updateInput?.profile
                              ? `${updateInput.profile}`
                              : blankImg
                          }
                          alt={updateInput?.name}
                        />
                      </div>
                      <div className="upload-img">
                        <div className="change-photo-btn">
                          <span>
                            <i className="fa fa-upload"></i> Upload Photo
                          </span>
                          <input
                            type="file"
                            className="upload"
                            onChange={handleImageChange}
                            name="profile"
                          />
                        </div>
                        <small className="form-text text-muted">
                          Allowed JPG, GIF or PNG. Max size of 2MB
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      {updateInput.type === 'Service' ? 'Business Name' : 'Display Name'}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Business Name"
                      value={updateInput.business_name}
                      onChange={handleChange}
                      name="business_name"
                    />
                    <small className="text-secondary">Recommended your {updateInput.type === 'Service' ? 'business Name' : 'display Name'}.</small>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      Follow Skill to get a notification
                    </label>

                    <Select
                      defaultValue={profileData[0]?.user_meta?.filter((item, index) => item.key === 'skilled').length === 1 ? JSON.parse(profileData[0]?.user_meta?.filter((item, index) => item.key === 'skilled')[0]?.value) : []}
                      isMulti
                      name="user_role"
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleChangeSkilled}
                    />
                    <small className="text-secondary">Recommended multiple skilled select eg. <b>Painter, Doctor, Advocate to get a push notification to related your skilled.</b></small>
                  </div>
                </div>


                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <label>
                      First Name <Required>*</Required>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your First Name"
                      value={updateInput.first_name}
                      onChange={handleChange}
                      name="first_name"
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <label>
                      Last Name <Required>*</Required>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Last Name"
                      value={updateInput.last_name}
                      onChange={handleChange}
                      name="last_name"
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <label>
                      Your Work <b style={{ color: "#009da6" }}>{(updateInput?.type === 'Both') ? 'Service | Customer' : updateInput?.type}</b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your work"
                      value={updateInput.user_role}
                      onChange={handleChange}
                      name="user_role"
                    />

                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <label>Email Address {profileData[0]?.is_verified ? <i className="fas fa-check-circle" style={{ color: "green" }}></i> : ''}</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter email address"
                      value={updateInput?.email}
                      className="form-control"
                      onChange={handleChange} rules={{ required: true }} />
                      {profileData[0]?.is_verified ?
                      <small style={{ color: 'green' }}>Your email is verified. you can not change.</small>
                      :
                      <small className="text-secondary">Recommended verify your email <Link style={{ textDecoration: 'underline' }} onClick={handleUpdateEmail}>Click here</Link></small>
                      }
                      <br/><ErrorP>{errorList?.email}<br/></ErrorP>
                    
                  </div>
                  
                </div>
                <div className="col-12 col-md-5">
                  <div className="form-group">
                    <label>Contact Number {profileData[0]?.is_phone_verified ? <i className="fas fa-check-circle" style={{ color: "green" }}></i> : ''}</label>
                    <input
                      type="text"
                      name="phone_number"
                      placeholder="Enter phone number"
                      value={updateInput?.phone_number}
                      className="form-control"
                      onChange={handleChange} rules={{ required: true }} />
                      
                    {profileData[0]?.is_phone_verified ?
                      <small style={{ color: 'green' }}>Your phone number is verified. you can not change.</small>
                      :
                      <small className="text-secondary">Recommended verify your phone number. <Link style={{ textDecoration: 'underline' }} onClick={handleUpdatePhone}>Click here</Link></small>
                    }
                    <ErrorP><br/>{errorList?.phone_number}</ErrorP>
                    
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="form-group">
                    <label>
                      Alternate Mobile
                    </label>
                    <input type="text"
                      name="alternate_number"
                      placeholder="Enter Alternate Contact number"
                      value={updateInput?.alternate_number}
                      className="form-control"
                      onChange={handleChange} rules={{ required: true }} />
                    <small className="text-secondary">Recommended alternate number </small>
                  </div>
                </div>

                <div className="col-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Aadhar Number <Required>*</Required>
                    </label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Aadhar 16 digits"
                        value={updateInput.aadhar_number}
                        onChange={handleChange}
                        name="aadhar_number"
                      />
                      <small className="text-secondary">Recommended aadhaar number.</small>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="formFileLg" className="form-label">
                      Upload Front View Image of Your Adhaar
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="formFileLg"
                      type="file"
                      // value={updateInput.front_aadhar_image}
                      onChange={handleImageChange}
                      name="front_aadhar_image"
                    />
                    <small className="text-secondary">Recommended aadhaar front picture.</small>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="formFileLg" className="form-label">
                    Upload Back View Image of Your Adhaar
                  </label>
                  <input
                    className="form-control form-control-lg"
                    id="formFileLg"
                    type="file"
                    onChange={handleImageChange}
                    name="back_aadhar_image"
                  />
                  <small className="text-secondary">Recommended aadhaar back picture.</small>
                </div>

                <div className="col-12">
                  <div className="form-group">
                    <label>Permanent Address</label>
                    <textarea className="form-control"
                      placeholder="Enter Your Address"
                      value={updateInput.permanent_address}
                      onChange={handleChange}
                      name="permanent_address">

                    </textarea>
                    {/* <PlacesPicker
        gMapsKey='AIzaSyDuPFWt0J9KYDkbkbPQH9gzi7fWbhfoFpM'
        placeId={value}
        onChange={setValue}
      /> */}
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Correspondance Address <small className="text-secondary"><b>(Optional)</b></small></label>
                    <textarea className="form-control"
                      placeholder="Enter Your Address 2 "
                      value={updateInput.correspondence_address}
                      onChange={handleChange}
                      name="correspondence_address"></textarea>

                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Gender</label>
                    <select
                      className="form-control select"
                      value={updateInput.gender}
                      onChange={handleChange}
                      name="gender"
                    >
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>Country <b style={{ color: "#009da6" }}>{profileData[0]?.country?.name}</b></label>
                    <CountrySelect name="country"
                      onChange={(e) => {
                        setCountryid(e?.id);
                        setCountryName(e?.name)
                        // setInput({ ...input, 'country': e?.id, countryObj: e })
                        setUpdateInput({ ...updateInput, 'country': e?.id, countryObj: e })
                      }}
                      // disabled="disabled"
                      defaultValue={countries}
                      options={countries}
                      placeHolder="Select Country"
                    />
                    {/* <SearchableDropdown
                      label="name"
                      id="id"
                      selectedVal={searchCountry}
                      handleChange={(val) => setSearchCountry(val)}
                      aria-describedby="validationTooltipUsernamePrepend"
                      options={nationList}
                      name="country"
                      isloading={nationLoading}
                      setStateData={setStateData}
                      handleNation={handleNation}
                    /> */}
                    <small className="text-secondary">Recommended your country to get a push notification to related your skilled</small>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>State <b style={{ color: "#009da6" }}>{profileData[0]?.state?.name}</b></label>
                    <StateSelect name="state"
                      countryid={countryid}
                      onChange={(e) => {
                        setstateid(e.id);
                        setStateName(e?.name)
                        // setInput({ ...input, 'state': e?.id, stateObj: e })
                        setUpdateInput({ ...updateInput, 'state': e?.id, stateObj: e })
                      }}
                      placeHolder="Select State"
                    />
                    {/* <SearchableDropdown
                      label="name"
                      id="id"
                      selectedVal={searchState}
                      handleChange={(val) => setSearchState(val)}
                      aria-describedby="validationTooltipUsernamePrepend"
                      options={stateData}
                      name="state"
                      setCityData={setCityData}
                      handleNation={handleNation}
                    /> */}
                    <small className="text-secondary">Recommended your state to get a push notification to related your skilled</small>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>City <b style={{ color: "#009da6" }}> {profileData[0]?.city?.name}</b></label>
                    {/* <SearchableDropdown
                      label="name"
                      id="id"
                      selectedVal={searchCity}
                      handleChange={(val) => setSearchCity(val)}
                      aria-describedby="validationTooltipUsernamePrepend"
                      options={cityData}
                      name="city"
                      handleNation={handleNation}
                    /> */}
                    <CitySelect
                      countryid={countryid}
                      stateid={stateid}
                      onChange={(e) => {
                        setCityName(e?.name)
                        setUpdateInput({ ...updateInput, 'city': e?.id, cityObj: e })
                      }}
                      placeHolder="Select City"
                    />
                    <small className="text-secondary">Recommended your city to get a push notification to related your skilled</small>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Description <small className="text-secondary"><b>(Optional)</b></small></label>
                  <textarea className="form-control"
                    placeholder="Enter Your business description "
                    value={updateInput.business_description}
                    onChange={handleChange}
                    name="business_description"></textarea>

                </div>
              </div>
              <div className="submit-section">
                {/* <button
                  type="submit"
                  className="btn btn-primary submit-btn"
                  style={{ width: "190px" }}
                >
                  {profileLoading ? <Loader width="1.7rem" /> : "Save Changes"}
                  
                </button> */}
                <Button type="submit" loading={profileLoading ? true : false}>Save Changes!</Button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export const Required = styled.span`
  color: red;
`;
