import React, { useEffect, useState } from "react";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { useParams } from "react-router-dom";
import Loader from "../../../../Common/Loader/Loader";
import moment from "moment";
import { Link } from "react-router-dom";
import "photoswipe/dist/photoswipe.css";

import { Gallery, Item } from "react-photoswipe-gallery";
import AppHelmet from "../../Helmet/Helmet";






export const ShowJob = () => {
  const { job_code } = useParams();
  const userData = getUserDataFromLocal();
  const [loading, setLoading] = useState(false);
  const [jobDetails, SetJobDetails] = useState({});
  

  const showJob = async () => {
    setLoading(true);

    try {
      const response = await WebService.get(apiEndPoints.jobdescription, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },

        params: {
          job_code: job_code,
        },
      });
      const { data } = response;
      // console.log(data.data)
      if (response.status === 200) {
        SetJobDetails(data.data);
        setLoading(false);
      } else {
        throw response;
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);

      // console.log(error, "error000");
    }
  };

  useEffect(() => {
    showJob();
  }, []);

  const imageArrayList = jobDetails?.job_image?.map((job_img, index) => {
    return (
      { src: rootConfig.base_url + job_img?.image, width: 800, height: 900 }
    )
  })

  // console.log(imageArrayList, "jobdetails ");

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <AppHelmet title={`${`${jobDetails?.title} - Serviceora`}`} description={jobDetails?.description?.substring(0, 150)} />
          <div className="breadcrumb-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        <Link to="/jobs">Jobs</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {jobDetails?.title}
                      </li>
                    </ol>
                  </nav>
                  <h2 className="breadcrumb-title capitalize">
                    {jobDetails?.title}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          

          <div className="content">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <div className="card">
                    <div className="card-body">
                      <div className="mentor-widget">
                        <div className="user-info-left align-items-center">
                          <div className=" d-flex flex-wrap">
                            <h2 className="table-avatar">
                              <Link to={`/business/${jobDetails?.job_user?.user_name}`}
                                className="avatar avatar-xl"
                              >
                                <img
                                  className="avatar-img rounded-circle"
                                  src={
                                    rootConfig.base_url +
                                    jobDetails?.job_user?.profile
                                  }
                                  alt={jobDetails?.title}
                                />
                              </Link>
                              <p className="mentor-type capitalize text-warning">
                                {jobDetails?.job_user?.name} <span className="badge badge-info">{jobDetails?.job_user?.type !== 'Customer' ? 'Service Provider' : 'Customer'}</span>
                              </p>
                              <Link className="capitalize">
                                {jobDetails?.title}
                                <span></span>
                                <ul className="tags">
                                  <li className="capitalize"><Link to="#" className="tag"><img src={rootConfig.base_url + jobDetails?.job_sub_category?.image} height="20px" width="20px" /> {jobDetails?.job_sub_category?.name}</Link></li>
                                  <li className="capitalize"><Link to="#" className="tag" ><img src={rootConfig.base_url + jobDetails?.job_category?.image} height="20px" width="20px" /> {jobDetails?.job_category?.name}</Link></li>
                                </ul>
                              </Link>
                            </h2>
                          </div>
                        </div>
                        <div className="user-info-right d-flex align-items-end flex-wrap">
                          <div className="hireme-btn ">
                            <p className="mentor-type social-title">
                              Publish by :-
                              <span
                                style={{ color: "black", fontSize: "14px" }}
                              >
                                {moment(jobDetails?.created_at).format(" Do MMMM, YYYY")}
                              </span>
                            </p>
                            <p className="mentor-type social-title">
                              Till :-   <span
                                style={{ color: "black", fontSize: "14px" }}
                              >
                                {/* {
                                  moment('2018/09/28', "YYYY/MM/DD").isBefore(moment()) ? 
                                  <span className="badge bg-danger">Expired</span>
                                  : moment(jobDetails?.created_at).fromNow()
                                } */}
                                {moment(jobDetails?.end_date).format(" Do MMMM, YYYY")}

                              </span>
                            </p>
                            <p>
                              Status :- <span className="badge bg-danger-light">{jobDetails?.status}</span>
                            </p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body custom-border-card pb-0">
                      <div className="widget education-widget mb-0">
                        <h4 className="widget-title">Image</h4>
                        <hr />
                        <div className="experience-box">

                          <div className="row">
                            <Gallery>
                              {
                                jobDetails?.job_image?.map((job_img, index) => {
                                  return (
                                    <Item 
                                      original={rootConfig.base_url + job_img?.image}
                                      thumbnail={rootConfig.base_url + job_img?.image}
                                      width="800"
                                      height="650"
                                    >
                                      {({ ref, open }) => (
                                        <img
                                          ref={ref} onClick={open}
                                          src={rootConfig.base_url + job_img?.image} height="100px" style={{width:"100px !important"}}  />
                                      )}
                                    </Item>
                                  )
                                })
                              }
                            </Gallery>





                          </div>



                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body pb-1 custom-border-card">
                      <div className="widget awards-widget m-0">
                        <h4 className="widget-title">Location</h4>
                        <hr />
                        <div className="experience-box">
                          <ul className="experience-list profile-custom-list">
                            {/* <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>Address</span>
                                  <div className="row-result">
                                    {jobDetails?.address}
                                  </div>
                                </div>
                              </div>
                            </li> */}
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>Country</span>
                                  <div className="row-result capitalize">
                                    {jobDetails?.job_country?.name}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>State</span>
                                  <div className="row-result capitalize">
                                    {jobDetails?.job_state?.name}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <span>City</span>
                                  <div className="row-result capitalize">
                                    {jobDetails?.job_city?.name}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body custom-border-card pb-0">
                      <div className="widget about-widget custom-about mb-0">
                        <h4 className="widget-title">Description</h4>
                        <hr />
                        <p style={{ width: "100%" }}>
                          {jobDetails?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
