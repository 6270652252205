export const sagaCatchBlockHandling = (error) => {
  let payload;
  // console.log('error.message', error?.response, error?.response?.status);
  if (error?.response?.status) {
    // add toast notification sysytem here
    switch (error.response.status) {
      case 400:
        payload = {
          error: error.response.data.message || error.response.data
        };
        break;
      case 401:
        payload = {
          error: error.response.data.message || error.response.data || 'unAuthorized'
        };
        break;
      case 405:
        payload = {
          error: error.response.data.message || error.response.data || 'Method Not Allowed'
        };
        break;
      case 429:
        payload = {
          error: error.response.data.message || error.response.data || "Too Many Attempts"
        };
        break;
      case 500:
        payload = {
          error: error.response.data.message || error.response.data || 'Somthing went wrong try later'
        };
        break;
      default:
        payload = {
          error: error.response.data.message || error.response.data || 'Unable to update. Try again later'
        };
    }
  } else {
    // for custom error
    payload = {
      error: error?.message || error?.error
    };
  }
  // avoiding from crash as if it returns an object
  if (typeof payload?.error === 'object') {
    // console.log('catching object');
    payload = {
      error: error.response.data?.title || 'Somthing went wrong try later'
    };
  }
  return payload;
};
