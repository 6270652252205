import { createActions } from 'reduxsauce';

export const { Types: TicketRaiseTypes, Creators: TicketRaiseCreators } = createActions({
    getTicketRaiseList: ['payload'],
    getTicketRaiseListStart: ['payload'],
    getTicketRaiseListSuccess: ['payload'],
    getTicketRaiseListFailure: ['payload']

/*** hello, I am here */

})