import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { TicketRaiseCreators } from "./store";
import { FlexContainer } from "../../../../globalStyles";
import SearchableDropdown from "../../../../Common/Input/SearchInput";
import Loader from "../../../../Common/Loader/Loader";
// import ModalComponent, { Modal } from "../../Common/Modal/Modal";
import ModalComponent from "../../../../Common/Modal/Modal";
import { SideBar } from "../SideBar";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { ProfileCreators } from "../../Dashboard/Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import './style.css'
import { Modal } from "react-bootstrap";

import moment from "moment";
export const TicketRaise = () => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();
  const [isReplyModal, setIsReplyModal] = useState(false)
  const [isAnswer, setIsAnswer] = useState({})

  const dispatch = useDispatch();
  const { ticketloading, ticketRaiseList } = useSelector((state) => state.ticketRaiseData);

  useEffect(() => {
    dispatch(TicketRaiseCreators.getTicketRaiseList());
  }, []);
  const { profileData } = useSelector((state) => state.profileData);

  const checkReply = (question, answer, jobTitle) => {
    setIsReplyModal(true)
    setIsAnswer({ 'question': question, 'answer': answer, 'jobTitle': jobTitle })
  }

  return (
    <>
      {isReplyModal && (
        <Modal
          show={true}
          size="lg"
          onHide={() => setIsReplyModal(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}> {isAnswer?.jobTitle}</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="card flex-fill">
                  <div className="card-body">
                  <p><strong><span style={{color: '#ff6600'}}>Q #1) {isAnswer?.question}.</span></strong></p>
                  <p><b style={{color: '#009DA6'}}>Answer : </b> {isAnswer?.answer !== null ? isAnswer?.answer : 'Wait, our customer provider will update you soon.'}</p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Toaster />
      {/* <ModalComponent /> */}
      {ticketloading ? (
        <Loader containerHeight={70} width="4rem" />
      ) : (
        <div className="main-wrapper">
          <div className="breadcrumb-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <Link to="/user/jobs/myjobs">My Jobs</Link>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="breadcrumb-title">My Jobs</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <SideBar profileData={profileData} />
                <div className="col-md-7 col-lg-8 col-xl-9">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="mb-4">Ticket Raise</h4>
                      <div className="card card-table">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Sr. no</th>
                                  <th>Job</th>
                                  <th>Subject</th>
                                  <th>Query</th>
                                  <th>CREATED DATE</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ticketRaiseList?.length < 1 ? <>
                                  <tr>
                                    <td>No Ticket Found!</td>
                                  </tr>
                                </> : ticketRaiseList?.map(
                                  (ticketRaise, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        {
                                          <td>
                                            <h2 className="table-avatar">
                                              <Link
                                                to="#"
                                                className="capitalize"
                                              >
                                                {ticketRaise?.job?.title?.substring(0, 130)}...
                                                <span>
                                                  <span
                                                    style={{
                                                      textTransform:
                                                        "lowercase",
                                                    }}
                                                  >
                                                  </span>
                                                </span>
                                              </Link>
                                            </h2>
                                          </td>
                                        }
                                        <td>
                                          {ticketRaise?.subject}
                                        </td>
                                        <td>
                                          {ticketRaise?.query?.substring(0, 130)}...

                                        </td>
                                        <td>
                                          {moment(
                                            ticketRaise?.created_at
                                          ).fromNow()}
                                        </td>
                                        <td className="text-left">
                                            {ticketRaise?.status === 'Open' ? 
                                          <span className="badge bg-secondary">{ticketRaise?.status}</span>
                                        :  ticketRaise?.status === 'Pending' ? 
                                          <span className="badge rounded-pill badge-info">{ticketRaise?.status}</span>
                                        :  ticketRaise?.status === 'Close' ? 
                                          <span className="badge rounded-pill badge-success">{ticketRaise?.status}</span>
                                        : ''}
                                        </td>
                                        <td className="text-left">
                                          <Link className={`badge btn-sm bg-info-light`} onClick={() => checkReply(ticketRaise?.query, ticketRaise?.reply_to, ticketRaise?.job?.title)}>
                                            <i className="far fa-check-circle"></i> Check Reply
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
