/* eslint-disable max-len */
export const rootConfig = {
  apiRoot: "https://serviceora.ideativemind.com/api/",
  base_url: "https://serviceora.ideativemind.com",
  // apiRoot: "http://127.0.0.1:8000/api/",
  // base_url: "http://127.0.0.1:8000"
  app_url: "https://serviceora.com",
  local_url: "http://localhost:3000",
};

export const apiEndPoints = {
  login: `${rootConfig.apiRoot}user/login`,
  loginWithMobile: `${rootConfig.apiRoot}user/login-with-mobile`,
  resendOTP: `${rootConfig.apiRoot}user/resend_otp`,
  otpVerify: `${rootConfig.apiRoot}user/otp_verify`,
  userLogout: `${rootConfig.apiRoot}user/logout`,
  logoutAlldevice: `${rootConfig.apiRoot}user/logoutalldevice`,
  register: `${rootConfig.apiRoot}user/register`,
  getProfile: `${rootConfig.apiRoot}user/profile`,
  ChangePassword: `${rootConfig.apiRoot}user/change-password`,
  updateProfile: `${rootConfig.apiRoot}user/profile/update`,
  verifyPhone: `${rootConfig.apiRoot}user/verify-phone`,
  updatePhoneWithOtp: `${rootConfig.apiRoot}user/update_phone_with_otp`,
  updateEmailVerify: `${rootConfig.apiRoot}user/update_email`,
  updateEmailAuthVerify: `${rootConfig.apiRoot}user/update_email_auth`,
  /*** Device Token Setting */  
  deviceToken: `${rootConfig.apiRoot}user/device-token`,
  getUserCategory: `${rootConfig.apiRoot}user/categories`,
  userJobCreate: `${rootConfig.apiRoot}user/job/create`,
  dashCategory: `${rootConfig.apiRoot}categories`,
  publishing: `${rootConfig.apiRoot}user/job/publish`,
  jobbidding: `${rootConfig.apiRoot}user/jobbidding/create`,
  jobBid: `${rootConfig.apiRoot}user/job/bid`,
  updateJob: `${rootConfig.apiRoot}user/job/update`,
  nation: `${rootConfig.apiRoot}user/nation`,
  jobdescription: `${rootConfig.apiRoot}user/job/show`,
  jobView: `${rootConfig.apiRoot}user/job/jobView`,
  favJobCreate: `${rootConfig.apiRoot}user/favourite_job/create`,
  recentJob: `${rootConfig.apiRoot}jobs/recent`,
  // Notification
  getNotificationList: `${rootConfig.apiRoot}user/notification_list`,
  updateNotificationList: `${rootConfig.apiRoot}user/update_notification_list`,
  //pending
  favJob: `${rootConfig.apiRoot}user/favourite_jobs`,
  favJobDelete: `${rootConfig.apiRoot}user/favourite_job/delete`,
  myJob: `${rootConfig.apiRoot}user/jobs/myjob`,
  //Business Profile
  businessProfile: `${rootConfig.apiRoot}user/profile/business`,
  //Service Provider List Find 
  serviceProviderListApi: `${rootConfig.apiRoot}user/service_providers`,
  submitSendQuery: `${rootConfig.apiRoot}user/submit_send_enquiry`,
  
  
  // Approved Bid
  approvedBid: `${rootConfig.apiRoot}user/jobbidding/approved_bid`,
  approvedJobList: `${rootConfig.apiRoot}user/jobs/bid/approve`,
  JobMessageStatus: `${rootConfig.apiRoot}user/jobs/bid/approve/status`,
  JobMessageList: `${rootConfig.apiRoot}user/jobs/bid/approve/message`,
  JobMessageList: `${rootConfig.apiRoot}user/jobs/bid/approve/message`,

  // Start Job
  startJob: `${rootConfig.apiRoot}user/job/start_job`,
  jobMessageUpdate: `${rootConfig.apiRoot}user/jobs/bid/approve/message/approved`,

  // Generate Invoice
  generateinvoice: `${rootConfig.apiRoot}user/job/invoice/create`,
  jobInvoiceList: `${rootConfig.apiRoot}user/job/invoices`,
  jobInvoiceShow: `${rootConfig.apiRoot}user/job/order/show`,
  jobInvoiceUpdate: `${rootConfig.apiRoot}user/job/order/update`,

  // Total count job bid
  totalJobCountBid: `${rootConfig.apiRoot}user/total_job_bidcount`,

  jobStatusUpdate: `${rootConfig.apiRoot}user/job/approve_status_update`,
  

  // Rating
  giveRating: `${rootConfig.apiRoot}user/rating/job/create`,

  // Ticket Raise
  ticketRaiseSubmit: `${rootConfig.apiRoot}user/ticket_raise/create`,
  ticketRaiseList: `${rootConfig.apiRoot}user/ticket_raise`,


  // COins
  coinList: `${rootConfig.apiRoot}user/coins`,

  // Earn Money
  earnList: `${rootConfig.apiRoot}user/earns`,

  //Enquiry 
  enquiryList: `${rootConfig.apiRoot}user/enquiry/list`,

  // Without Token
  ForgetPassword: `${rootConfig.apiRoot}forget-password`,
  ResetPassword: `${rootConfig.apiRoot}reset-password`,
  VerifyEmail: `${rootConfig.apiRoot}verify-email`,

  // Request a call back
  requestACallBack: `${rootConfig.apiRoot}user/job/request_a_call_back`,
  
  // Job Without Token
  jobListing: `${rootConfig.apiRoot}user/jobs`,
  globalLocation: `${rootConfig.apiRoot}globallocation`,
  nationLocation: `${rootConfig.apiRoot}nation`,
  categories: `${rootConfig.apiRoot}categories`,
  subCategories: `${rootConfig.apiRoot}services`,
  contactUs: `${rootConfig.apiRoot}contact/create`,

  // Testimonial Without Token
  testimonialList: `${rootConfig.apiRoot}testimonials`,
  aboutList: `${rootConfig.apiRoot}aboutus`,
  
  //City List
  cityList: `${rootConfig.apiRoot}dashboardcities`,

  // Social COmpany 
  socialList: `${rootConfig.apiRoot}socials`,

  

  // Check Current City Api
  currentCityApi: `https://nominatim.openstreetmap.org/reverse?format=json`,

  


};

export const firebase = {
  apiKey: "AIzaSyC9S5wCQqw7skqeCG01ct465ogSfEf20W8",
  authDomain: "serviceora-6117f.firebaseapp.com",
  projectId: "serviceora-6117f",
  storageBucket: "serviceora-6117f.appspot.com",
  messagingSenderId: "41898727243",
  appId: "1:41898727243:web:30668acc8692d281b8face",
  measurementId: "G-9M8BZXCVKF",

  // O8e945XdMmolagfXHIMnCdS-19xvZJ_iTseKTVit92w
  //Vapid Key
  vapidKey: "BGLEsTnwlvbwjEpy1niRAY5R6QRXw0OMJPMO3q-Ei5JkKZE15ls9FsPe4PD_xHJXp7M55hi0rkz6evhpo0iPwvg"

}
