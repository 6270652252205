import React, { useState, useEffect } from "react";
import { WebService } from "../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../services/axios/endPoints";
import { sagaCatchBlockHandling } from "../../store/utility/sagaErrorHandling";
import toast, { Toaster } from "react-hot-toast";
import { ErrorP } from "../../Common/Modal/Modal";
import Loader from "../../Common/Loader/Loader";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Select from 'react-select';
import { SocialCreators } from "../Pages/Landing/Social/store";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator'
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap-button-loader';






export const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [referralParm, setReferralParm] = useState('');
  const [registerInput, setRegisterInput] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    // password_confirmation: "",
    agreed: 0,
    referral_code: searchParams.get('referral')
  });
  const [errorList, seterrorList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState([])
  const [isOtpMobile, setIsOtpMobile] = useState(false)
  const [emailPhone, setEmailPhone] = useState(false);
  

  const options = [
    { value: 'Service', label: 'Service Provider' },
    { value: 'Customer', label: 'Customer' },
  ];
  const { socialList, socialloading } = useSelector((state) => state.socialData);

  useEffect(() => {
    dispatch(SocialCreators.getSocialList());
  }, []);


  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterInput({ ...registerInput, [name]: value });
    if (registerInput[name] === 0) {
      setRegisterInput({ ...registerInput, [name]: 1 });
    }
    if (registerInput[name] === 1) {
      setRegisterInput({ ...registerInput, [name]: 0 });
    }
  };

  const handleChange1 = (option) => {
    setOption(option)
    setRegisterInput({ ...registerInput, 'type': option?.value })
  }

  const handleChangeEmail = () => {
    console.log('resi', registerInput)
    if (validator.isEmail(registerInput?.email)) {
        setEmailPhone(true)
        
    } else if(validator.isMobilePhone(registerInput?.email)) {
      // setEmailPhone(false);
      setIsOtpMobile(true);
    }
    else{

    }
  }


  const handleRegister = async () => {
    try {
      // console.log('register', registerInput)
      // return false;
      setLoading(true)
      const res = await WebService.post(
        apiEndPoints.register,
        JSON.stringify(registerInput)
      );
      if (res.status === 200) {
       
        toast.success(res.data.msg, {
          position: "top-center",
          duration: 6000
        });
        setRegisterInput({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          password_confirmation: "",
          agreed: 0,
        })
        
        seterrorList([]);
          setTimeout(() => {
            navigate('/login')
          }, 3000)
          
        

      } else if (res.response.status === 422) {
        toast.error(res.response.data.msg, {
          position: "top-center",
        });
        setLoading(false)
        seterrorList(res.response.data.data);
      } else if (res.response.status === 404) {
        toast.error(res.response.data.msg, {
          position: "top-center",
        });
        setLoading(false)
      } else {
        toast.error("something went wrong.", {
          position: "top-right",
        });
        setLoading(false)
      }
    } catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      setLoading(false)
    }
  };

  const handleSubmit = (e) => {
    // handleChangeEmail();
    e.preventDefault();
    handleRegister();
  };

  const OpenHomePage = () => {
    navigate('/')
  }

  

  return (
    <>
      <Toaster />
      {isOtpMobile && (
        <Modal
          show={true}
          size="lg"
          onHide={() => setIsOtpMobile(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-xs"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}></span> OTP Mobile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Hello, I am here
          </Modal.Body>
        </Modal>
      )}
      <div className="bg-pattern-style bg-pattern-style-register"  style={{background: 'url('+rootConfig?.base_url+socialList?.registerImg+') no-repeat'}}>
        <div className="content">
          <div className="account-content">
            <div className="account-box">
              <div className="login-right">
                <div className="login-header">
                  {/* <img src={logoshramsvg} className="img-fluid" alt="Serviceora" style={{ maxHeight: "30px" }} /> */}
                  <h3>
                    <span onClick={() => OpenHomePage()} style={{ cursor: "pointer" }}>Serviceora</span> Register
                  </h3>
                  <p className="text-muted">Access to your work</p>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">First Name</label>
                        <input
                          id="first-name"
                          type="text"
                          className="form-control"
                          name="first_name"
                          autoComplete="off"
                          placeholder="First name"
                          value={registerInput.first_name}
                          onChange={handleChange}
                        />
                        <ErrorP>{errorList.first_name}</ErrorP>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">Last Name</label>
                        <input
                          id="last-name"
                          type="text"
                          className="form-control"
                          name="last_name"
                          placeholder="Last name"
                          value={registerInput.last_name}
                          autoComplete="off"
                          onChange={handleChange}
                        />
                        <ErrorP>{errorList.last_name}</ErrorP>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">Email Address or Phone Number</label>
                    <input
                      id="email"
                      type="text"
                      name="email"
                      value={registerInput.email}
                      className="form-control"
                      placeholder="Email Address or Phone Number"
                      autoComplete="off"
                      onChange={handleChange}
                    />
                    <ErrorP>{errorList.email}</ErrorP>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-control-label">Password</label>
                        <input
                          id="password"
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="Password"
                          value={registerInput.password}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        {/* <span className="fas fa-eye toggle-password"style={{right:"53%", top:"63%"}}></span> */}
                        <ErrorP>{errorList.password}</ErrorP>
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">
                          Password Confirm
                        </label>
                        <input
                          id="password-confirm"
                          type="password"
                          className="form-control"
                          name="password_confirmation"
                          placeholder="Password Confirmation"
                          value={registerInput.password_confirmation}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                        <ErrorP>{errorList.password_confirmation}</ErrorP>
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">
                          Select Role Type
                        </label>
                        <Select
                          name="type"
                          // isMulti = {true}
                          options={options}
                          // value={options}
                          closeMenuOnSelect={true}
                          onChange={handleChange1} />
                          <ErrorP>{errorList.type}</ErrorP>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label className="form-control-label">
                          Referral Code
                        </label>
                        <input
                          id="referral_code"
                          type="text"
                          className="form-control"
                          name="referral_code"
                          placeholder="Referral Code"
                          value={registerInput.referral_code}
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  
                  <div className="form-group">
                    <div className="custom-control custom-control-xs custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        name="agreed"
                        id="agree_checkbox_user"
                        value={registerInput.agreed}
                        onChange={handleChange}
                        checked={registerInput.agreed === 1}
                      />
                      <label style={{paddingLeft:'5px'}}
                        className="custom-control-label"
                        htmlFor="agree_checkbox_user"
                      > I agree to Serviceora
                      </label>
                      <Link tabIndex="-1" target="_blank" to="/privacy&policy" style={{ color: "#009da6" }}>
                        {" "}
                        Privacy Policy{" "}
                      </Link>
                      &amp;
                      <Link tabIndex="-1" target="_blank" to="/term&conditions" style={{ color: "#009da6" }}>
                        Terms.
                      </Link>
                    </div>
                    <ErrorP>{errorList.agreed}</ErrorP>
                  </div>
                  {/* <button className="btn btn-primary login-btn " type="submit">
                    {loading ? <Loader /> : "Create Account"}
                  </button> */}
                  <Button className="btn btn-primary login-btn" type="submit" loading={loading ? true : false}>Create Account!</Button>
                  <div className="account-footer text-center mt-3 text-center dont-have">
                    Already have an account?{" "}
                    <Link to="/login">Login here!</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
