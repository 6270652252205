import { createReducer } from "reduxsauce";
import { EnquiryTypes } from "./action";


const INITIAL_STATE = {
    enquiryloading: false,
    enquiryList: [],
    error: ''
}


const getEnquiryListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        enquiryloading: true
    }
}

const getEnquiryListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    // console.log('action', action)
    return {
        ...state,
        enquiryloading: false,
        enquiryList: data
    }
}

const getEnquiryListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        enquiryloading: false
     }
}


const HANDLERS = {
    [EnquiryTypes.GET_ENQUIRY_LIST_START]: getEnquiryListStart,
    [EnquiryTypes.GET_ENQUIRY_LIST_SUCCESS]: getEnquiryListSuccess,
    [EnquiryTypes.GET_ENQUIRY_LIST_FAILURE]: getEnquiryListFailure
}

export const enquiryReducer = createReducer(INITIAL_STATE, HANDLERS)