import React, { useState } from "react";
import { rootConfig } from "../../../../services/axios/endPoints";
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import Loader from "../../../../Common/Loader/Loader";
import moment from "moment";
import './style.css'
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';



export const HeadNotification = ({handleShow, show, setShow, handleClose, usernotificationList, usernotificationloading, openNotificationPopup, handleClearAll, isDown, userData }) => {
  
  return (
    <>
     

      <Offcanvas show={show} onHide={handleClose} placement="end" name="end" backdrop='false' style={{ zIndex: '9999' }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notification <i className="fa fa-bell text-muted"></i></Offcanvas.Title>

          
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="section-50">
            <p><Link to="/user/setting" style={{textDecoration:'underline'}} className="head-allow">Allow Notification</Link>
            <span className="pull-right" style={{float:'right', textDecoration:'underline'}}><Link className="head-clearall" onClick={() => handleClearAll()}>Clear All</Link></span>
            </p>
            {usernotificationloading && <Loader width="1.4rem" />}
              <div className="notification-ui_dd-content">
              {usernotificationList?.recent?.map((notification, index) => {
                return (
                <div key={index} className={`notification-list ${(notification?.read) ? `` : " notification-list--unread"}`}>
                  <div className="notification-list_content">
                    <div className="notification-list_img">
                    {
                      notification?.type === 'start' ?
                        <img src={rootConfig?.base_url + notification?.user_notification?.profile} alt="user" />
                        : ''
                    }
                    {
                      notification?.type === 'add_job' ?
                        <img src={rootConfig?.base_url + notification?.job_notification?.job_user?.profile} alt={rootConfig?.base_url + notification?.job_notification?.job_user?.name} />
                        : ''
                    }
                    {
                      notification?.type === 'job_bid' ?
                        <img src={rootConfig?.base_url + notification?.service_notification?.profile} alt={rootConfig?.base_url + notification?.service_notification?.name} />
                        : ''
                    }
                    {
                      notification?.type === 'bid_approve' ?
                        <img src={rootConfig?.base_url + notification?.job_notification?.job_user?.profile} alt={rootConfig?.base_url + notification?.job_notification?.job_user?.name} />
                        : ''
                    }
                    {
                      notification?.type === 'start_job' ?
                        <img src={rootConfig?.base_url + notification?.service_notification?.profile} alt={rootConfig?.base_url + notification?.service_notification?.name} />
                        : ''
                    }
                    {
                      notification?.type === 'action_job' ?
                        <img src={rootConfig?.base_url + notification?.service_notification?.profile} alt={rootConfig?.base_url + notification?.service_notification?.name} />
                        : ''
                    }
                    {
                      notification?.type === 'rating' ?
                        <img src={rootConfig?.base_url + notification?.job_notification?.job_user?.profile} alt={rootConfig?.base_url + notification?.job_notification?.job_user?.name} />
                        : ''
                    }
                    {
                      notification?.type === 'alert' ?
                        <img src={notification?.service_id} alt={notification?.key} />
                        : ''
                    }
                    </div>
                    <div className="notification-list_detail">
                    {
                    notification?.type === 'add_job' ?
                      <p><b><Link to={`${`/business/${notification?.job_notification?.job_user?.user_name}`}`} style={{ color: '#03b2cb' }}>{userData?.userData?.id === notification?.job_notification?.job_user?.id ? 'you' : notification?.job_notification?.job_user?.name} </Link></b> {notification?.key} </p>
                      : notification?.type === 'job_bid' ?
                        <p> {notification?.key} <b><Link to={`${`/business/${userData?.userData?.id === notification?.job_notification?.job_user?.id ? notification?.service_notification?.user_name : notification?.job_notification?.job_user?.user_name}`}`} style={{ color: '#03b2cb' }}>{userData?.userData?.id === notification?.job_notification?.job_user?.id ? notification?.service_notification?.name : notification?.job_notification?.job_user?.name} </Link></b></p>
                        : notification?.type === 'bid_approve' ?
                          <p> {notification?.key} <b><Link to={`${`/business/${userData?.userData?.id === notification?.job_notification?.job_user?.id ? notification?.service_notification?.user_name : notification?.job_notification?.job_user?.user_name}`}`} style={{ color: '#03b2cb' }}>{userData?.userData?.id === notification?.job_notification?.job_user?.id ? notification?.service_notification?.name : notification?.job_notification?.job_user?.name} </Link></b></p>
                          : notification?.type === 'start_job' ?
                            <p> {notification?.key} <b><Link to={`${`/business/${userData?.userData?.id === notification?.job_notification?.job_user?.id ? notification?.service_notification?.user_name : notification?.service_notification?.user_name}`}`} style={{ color: '#03b2cb' }}> </Link></b></p>
                            : notification?.type === 'action_job' ?
                              <p> {notification?.key} <b><Link to={`${`/business/${userData?.userData?.id === notification?.job_notification?.job_user?.id ? notification?.service_notification?.user_name : notification?.service_notification?.user_name}`}`} style={{ color: '#03b2cb' }}>{userData?.userData?.id === notification?.job_notification?.job_user?.id ? 'you' : notification?.service_notification?.name} </Link></b></p>
                              : notification?.type === 'rating' ?
                                <p> {notification?.key} <b><Link to={`${`/business/${userData?.userData?.id === notification?.job_notification?.job_user?.id ? notification?.service_notification?.user_name : notification?.service_notification?.user_name}`}`} style={{ color: '#03b2cb' }}>{userData?.userData?.id === notification?.job_notification?.job_user?.id ? notification?.service_notification?.name : notification?.service_notification?.name} </Link></b></p>
                                : notification?.type === 'start' ?
                                  <p><b><Link to={notification?.url}>{notification?.key?.substring(0, 32)}</Link></b></p>
                                  : notification?.type === 'alert' ?
                                    <p><b><Link to={notification?.url} style={{ color: '#009DA6' }}>{notification?.key}</Link></b></p>
                                    : ''
                  }
                      <p className="text-muted">{notification?.value.substring(0, 32)}</p>
                      <p className="text-muted"><small>{moment(notification?.created_at).fromNow()}</small></p>
                    </div>
                  </div>
                </div>
                )
                })
              }
              </div>

            <div className="text-center">
              <Link to='/user/notification' className="btn btn-sm dark-link">View more activity</Link>
            </div>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

