import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../../../Common/Loader/Loader";



export const GlobalLocation = ({ globallocationcount, gloading, handleGlobalLocation, handleService }) => {
  return (
    <div className="banner-details">

      <div className="location-banner"  data-aos="zoom-in" data-aos-delay="100" >
        <div className="banner-card d-flex align-items-center">
          <div className="banner-count">
            <h2>
              {gloading && <Loader width="1.4rem" />}
              {globallocationcount?.global_location}</h2>
          </div>
          <div className="banner-contents">
            <h2>Job Locations</h2>
            <Link to="#" onClick={handleGlobalLocation}>
              See all Locations <i className="fas fa-caret-right right-nav-white"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="service-banner"  data-aos="zoom-in" data-aos-delay="100" >
        <div className="banner-card d-flex align-items-center">
          <div className="banner-count">
            <h2>
              {gloading && <Loader width="1.4rem" />}
              {globallocationcount?.total_services}</h2>
          </div>
          <div className="banner-contents">
            <h2>Completed Jobs</h2>
            <Link to="#" onClick={handleService}>
              See all Services <i className="fas fa-caret-right right-nav-white"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="customer-banner"  data-aos="zoom-in" data-aos-delay="100" >
        <div className="banner-card d-flex align-items-center">
          <div className="banner-count">
            <h2>
              {gloading && <Loader width="1.4rem" />}
              {globallocationcount?.total_customer}</h2>
          </div>
          <div className="banner-contents">
            <h2>Client's & Service Provider</h2>
            <Link to="/contact-us">
              Contact us <i className="fas fa-caret-right right-nav-white"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}