import { createReducer } from "reduxsauce";
import { Types } from "./action";

const INITIAL_STATE = {
  updatedProfile: [],
  loading: false,
  profileLoading: false,
  nationLoading: false,
  nationList: [],
  error: null,
  updatemsg: "",
};

const updateProfileStart = (state = INITIAL_STATE) => ({
  ...state,
  profileLoading: true,
});

const updateProfileSuccess = (state = INITIAL_STATE, action) => {
  // console.log(action.payload, "payload in update");
  const { msg } = action.payload;
  return {
    ...state,
    profileLoading: false,
    updatemsg: msg,
  };
};

const updateProfileFailure = (state = INITIAL_STATE) => ({
  ...state,
  profileLoading: false,
});

const getNationDataStart = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    nationLoading: true,
  };
};

const getNationDataSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    nationLoading: false,
    nationList: [...data],
  };
};

const getNationDataFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    nationLoading: false,
    // error
  };
};

const HANDLERS = {
  [Types.UPDATE_PROFILE_START]: updateProfileStart,
  [Types.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
  [Types.UPDATE_PROFILE_FAILURE]: updateProfileFailure,
  [Types.GET_NATION_DATA_START]: getNationDataStart,
  [Types.GET_NATION_DATA_SUCCESS]: getNationDataSuccess,
  [Types.GET_NATION_DATA_FAILURE]: getNationDataFailure,
};

export const updateProfileReducer = createReducer(INITIAL_STATE, HANDLERS);
