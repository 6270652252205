import { createReducer } from "reduxsauce";
import { CityTypes } from "./action";


const INITIAL_STATE = {
    loading: false,
    CityList: [],
    error: ''
}


const getCityListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true
    }
}

const getCityListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        loading: false,
        CityList: data
    }
}

const getCityListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        loading: false
     }
}


const HANDLERS = {
    [CityTypes.GET_CITY_LIST_START]: getCityListStart,
    [CityTypes.GET_CITY_LIST_SUCCESS]: getCityListSuccess,
    [CityTypes.GET_CITY_LIST_FAILURE]: getCityListFailure
}

export const CityReducer = createReducer(INITIAL_STATE, HANDLERS)