import { takeLatest, takeEvery } from "redux-saga/effects";
import { Types } from "./actions";
import { loginSaga, loginOTPSaga, onLogoutSaga, onLogoutAllDeviceSaga } from "../view/auth/store/saga";
import { getProfileSaga } from "../view/Pages/Dashboard/store";
import { updateProfileSaga } from "../view/Pages/Dashboard/Profile/store";
import {
  getCategoriesSaga,
  getDashCategoriesSaga,
  getJobListSaga, getApprovedJobListSaga
} from "../view/Pages/Dashboard/store/saga";
import { getNationDataSaga } from "../view/Pages/Dashboard/Profile/store/saga";
import { getFavJobSaga, getRecentJobSaga } from "../view/Pages/Landing/store";
import { getMyJobListSaga } from "../view/Pages/Dashboard/MyJob/store/saga"
import { getNationalListSaga } from "../view/Pages/Dashboard/National/store/saga"
import { getNationListSaga } from "../view/Pages/Landing/Nation/store/saga"
import { getCategoriesListSaga } from "../view/Pages/Landing/Categories/store/saga"
import { getJobInvoiceListSaga } from "../view/Pages/Dashboard/JobInvoice/store/saga"
import { getSubcategoryListSaga } from "../view/Pages/Landing/Subcategory/store/saga";
import { getTicketRaiseListSaga } from "../view/Pages/Dashboard/TicketRaise/store/saga";
import { getTestimonialsListSaga, getAboutListSaga } from "../view/Pages/Landing/Testimonial/store/saga";
import { getSocialListSaga } from "../view/Pages/Landing/Social/store/saga";
import { getUserNotificationListSaga } from "../view/Pages/Dashboard/UserNotification/store/saga";
import { getCoinsListSaga } from "../view/Pages/Dashboard/Coins/store/saga";
import { getServiceProviderListSaga } from "../view/Pages/Landing/FindServiceProviderList/store/saga";
import { getEnquiryListSaga } from "../view/Pages/Dashboard/Enquiry/store/saga";
import { getEarnmoneyListSaga } from "../view/Pages/Dashboard/Earnmoney/store/saga";
import { getCityListSaga } from "../view/Pages/Landing/City/store/saga";


export function* watchAuth() {
  yield takeLatest(Types.LOGIN, loginSaga);
  yield takeLatest(Types.LOGIN_OTP, loginOTPSaga)
  yield takeLatest(Types.ON_LOGOUT, onLogoutSaga);
  yield takeLatest(Types.ON_LOGOUT_ALL_DEVICE, onLogoutAllDeviceSaga)
  yield takeLatest(Types.GET_PROFILE, getProfileSaga);
  yield takeLatest(Types.UPDATE_PROFILE, updateProfileSaga);
  yield takeLatest(Types.GET_CATEGORIES, getCategoriesSaga);
  yield takeLatest(Types.GET_DASH_CATEGORIES, getDashCategoriesSaga);
  yield takeLatest(Types.GET_JOB_LIST, getJobListSaga);
  yield takeLatest(Types.GET_NATION_DATA, getNationDataSaga);
  yield takeLatest(Types.GET_RECENT_JOB, getRecentJobSaga);
  yield takeLatest(Types.GET_FAV_JOB, getFavJobSaga);
  yield takeLatest(Types.GET_APPROVED_JOB_LIST, getApprovedJobListSaga);
  yield takeLatest(Types.GET_MY_JOB_LIST, getMyJobListSaga);
  yield takeLatest(Types.GET_NATIONAL_LIST, getNationalListSaga);
  yield takeLatest(Types.GET_NATION_LIST, getNationListSaga);
  yield takeLatest(Types.GET_CATEGORIES_LIST, getCategoriesListSaga);
  yield takeLatest(Types.GET_JOB_INVOICE_LIST, getJobInvoiceListSaga);
  yield takeLatest(Types.GET_SUBCATEGORY_LIST, getSubcategoryListSaga);
  yield takeLatest(Types.GET_TICKET_RAISE_LIST, getTicketRaiseListSaga);
  yield takeLatest(Types.GET_TESTIMONIALS_LIST, getTestimonialsListSaga);
  yield takeLatest(Types.GET_ABOUT_LIST, getAboutListSaga);
  yield takeLatest(Types.GET_SOCIAL_LIST, getSocialListSaga);
  yield takeLatest(Types.GET_USER_NOTIFICATION_LIST, getUserNotificationListSaga);
  yield takeLatest(Types.GET_COINS_LIST, getCoinsListSaga);
  yield takeLatest(Types.GET_SERVICE_PROVIDER_LIST, getServiceProviderListSaga);
  yield takeLatest(Types.GET_ENQUIRY_LIST, getEnquiryListSaga);
  yield takeLatest(Types.GET_EARNMONEY_LIST, getEarnmoneyListSaga);
  yield takeLatest(Types.GET_CITY_LIST, getCityListSaga);
}
