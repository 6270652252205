import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getProfile: ['payload'],
  getProfileSuccess: ['payload'],
  getProfileStart: ['payload'],
  getProfileFailure: ['payload'],
  getCategories: ['payload'],
  getCategoriesStart: ['payload'],
  getCategoriesSuccess: ['payload'],
  getCategoriesFailure: ['payload'],
  getDashCategories: ['payload'],
  getDashCategoriesStart: ['payload'],
  getDashCategoriesSuccess: ['payload'],
  getDashCategoriesFailure: ['payload'],
  getJobList: ['payload'],
  getJobListStart: ['payload'],
  getJobListSuccess: ['payload'],
  getJobListFailure: ['payload'],
  //ApprovedJobList
  getApprovedJobList: ['payload'],
  getApprovedJobListSuccess: ['payload'],
  getApprovedJobListStart: ['payload'],
  getApprovedJobListFailure: ['payload'],
});

export { Types, Creators };
