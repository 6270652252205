import React, { useEffect, useState } from 'react';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
// import './Home.css';
import { Toast } from "@capacitor/toast";
import { Capacitor } from '@capacitor/core';
/*** Web Firebase */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { firebase, rootConfig } from '../services/axios/endPoints';
import sound from '../assets/audio/tone.wav'
import { useIonRouter } from "@ionic/react";
import {useNavigate} from 'react-router-dom'
import { App, URLOpenListenerEvent } from '@capacitor/app';



const firebaseConfig = {
    apiKey: firebase?.apiKey,
    authDomain: firebase?.authDomain,
    projectId: firebase?.projectId,
    storageBucket: firebase?.storageBucket,
    messagingSenderId: firebase?.messagingSenderId,
    appId: firebase?.appId,
    measurementId: firebase?.measurementId
  };
  
export default function PushNotificationsContainer() {
    
    const nullEntry = []
    const [notifications, setnotifications] = useState('');
    const router = useIonRouter();
    const navigate = useNavigate();
    



    useEffect(()=>{
        if (Capacitor.isNative) {
            console.log('native')
        }
        if (Capacitor.getPlatform() === 'ios') {
            console.log('iOS!');
        } else if (Capacitor.getPlatform() === 'android') {
            
            PushNotifications.checkPermissions().then((res) => {
                if (res.receive !== 'granted') {
                  PushNotifications.requestPermissions().then((res) => {
                    if (res.receive === 'denied') {
                      showToast('Push notification permission denied');
                    //   console.log('Push Notification permission denied');
                    }
                    else {
                      showToast('Push notification permission granted');
                    //   console.log('Push notification permission granted');
                      register();
                      
                    }
                  });
                }
                else {
                    
                    register();
                  
                }
                console.log('Android!');
              });
        } else {
            console.log('Web!123');
            
        }
    },[])
    
    const register = () => {
        // console.log('Initializing HomePage');

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token) => {
                localStorage.setItem('android_device_token', token?.value);
                showToast('Push notification registration success');
                // console.log('Push registration success', token?.value);
            }
        );

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                alert('Error on registration: ' + JSON.stringify(error));
                // console.log('Error on registration: ' + JSON.stringify(error))
            }
        );

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification) => {
                console.log('pushNotificationReceived', notification)
                setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, image: notification.image, url: notification.url, type: 'foreground' }])
            }
        );

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification) => {
                console.log('pushNotificationActionPerformed', notification)
                setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, image: notification.notification.data.image, url:notification.notification.data.url, type: 'action' }])
                // let app_url = 'https://serviceora.com/privacy&policy';
                // navigate(app_url);
                // const data = notification.notification.data;
                App.openUrl({url:'https://serviceora.com/privacy&policy'})
            }
        );
    }

    const showToast = async (msg) => {
        await Toast.show({
            text: msg
        })
    }

}