import { createActions } from 'reduxsauce';

export const { Types: TestimonialsTypes, Creators: TestimonialsCreators } = createActions({
    getTestimonialsList: ['payload'],
    getTestimonialsListStart: ['payload'],
    getTestimonialsListSuccess: ['payload'],
    getTestimonialsListFailure: ['payload'],
    getAboutList: ["payload"],
    getAboutListStart: ["payload"],
    getAboutListSuccess: ["payload"],
    getAboutListFailure: ["payload"],
})