import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../Footer"
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { LandingPageCreators } from "../store";
import { ProfileCreators } from "../../Dashboard/store";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { WebService } from "../../../../services/axios/webServices";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { Creators } from "../../../auth/store";
import BusinessProfileJob from "./BusinessProfileJob";
import moment from "moment";
import { Rating } from 'react-simple-star-rating'
import BusinessProfileRating from "./BusinessProfileRating";
import Loader from "../../../../Common/Loader/Loader";
import Stack from 'react-bootstrap/Stack';
import Badge from 'react-bootstrap/Badge';
import { ShieldFillCheck, Whatsapp, PatchCheckFill } from 'react-bootstrap-icons';
import { Tooltip as ReactTooltip } from "react-tooltip";


const useAuth = () => {
    const user = localStorage.getItem("user");
    if (user) {
        return true;
    } else {
        return false;
    }
};
const BusinessProfile = () => {
    const { user_name } = useParams();
    const auth = useAuth();
    const userData = getUserDataFromLocal();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [business, setBusiness] = useState({})
    const [businessjob, setBusinessJob] = useState([])
    const [userRating, setUserRating] = useState(0)
    const [serviceRating, setServiceRating] = useState([])
    const [skilledTags, setSkilledTags] = useState([])

    const { profileData, dashboardLoading, jobList } = useSelector(
        (state) => state.profileData
    );
    const { loginmsg } = useSelector((state) => state.loginData);
    const showBusiness = async () => {

        try {
            setLoading(true);
            const response = await WebService.get(apiEndPoints.businessProfile, {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },

                params: {
                    user_name: user_name,
                },
            });
            const { data } = response;
            if (response.status === 200) {
                setServiceRating(data.service_rating)
                setUserRating(response?.data?.rating)
                setBusiness(data.data)
                setBusinessJob(data.data.jobs)
                setLoading(false);
            } else {
                throw response;
                setLoading(false);
            }
        } catch (e) {
            const error = sagaCatchBlockHandling(e);
            setLoading(false);
        }
    };

    useEffect(() => {
        handleTagSkilled(business?.user_meta)
        showBusiness();
    }, []);


    useEffect(() => {

        dispatch(ProfileCreators.getProfile());

    }, []);


    const handleLogout = () => {
        dispatch(Creators.onLogout());
    };

    const handleTagSkilled = (skilled) => {
        const tags = skilled?.filter((item, index) => item.key === 'skilled')
        if (tags?.length === 1) {
            setSkilledTags(JSON.parse(tags[0]?.value))
        }
    }


    return (
        <>

            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-3 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active capitalize" aria-current="page">
                                        {business?.name}
                                    </li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                </div>
            </div>

            <div className="main-wrapper">
                <div className="content">
                    <div className="container">
                        <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body">
                                <div className="mentor-widget">
                                    <div className="user-info-left align-items-center">
                                        <div className="mentor-img d-flex flex-wrap justify-content-center">
                                            <div className="pro-avatar">
                                                <img className="avatar-img rounded-circle" alt={business?.name} src={rootConfig?.base_url + business?.profile} />
                                            </div>
                                            <div className="rating">
                                                <Rating readonly initialValue={userRating ? userRating : 3} size={18} allowFraction />
                                            </div>
                                            <div className="mentor-details m-0">
                                                <p className="user-location m-0">{business?.city ? <i className="fas fa-map-marker-alt"></i> : ''} {business?.city?.name}, {business?.country?.name}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="user-info-cont">
                                            <h4 className="usr-name capitalize">
                                            {business?.business_name ?
                                                <>
                                                    <Link to={`${auth ? `/business/${business?.user_name}` : "/login"}`} className="text-dark capitalize">{business?.business_name} </Link> <ShieldFillCheck data-tooltip-id="shield-verify" style={{ color:'#999' }}  /> 
                                                    {business?.user_meta?.filter((item, index) => item.key === 'shram_verified')?.length === 1 ? 
                                                    <PatchCheckFill data-tooltip-id="patch-verify" style={{ color:'green' }}  />
                                                    : ''}
                                                    
                                                    <ReactTooltip id="shield-verify" place="bottom"  content="The Business information is verified." style={{fontSize:'10px', color:'white', background:'gray', border:'1px groove lightgray'}}/>  
                                                    <ReactTooltip id="patch-verify" place="bottom"  content="The Business information is verified by Serviceora." style={{fontSize:'10px', color:'white', background:'green', border:'1px groove lightgray'}}/>  
                                                </> : ''
                                            }
                                            </h4>
                                            
                                            <div className="mentor-action">
                                            <p className="mentor-type capitalize" style={{color: 'rgb(255, 156, 39)'}}><span style={{color:'black'}}></span> {business?.name} ({business?.type !== 'Customer' ? 'Service Provider' : 'Customer'})</p>
                                                <p className="mentor-type social-title"><span style={{ color: 'black' }}>Service:</span> {business?.user_role}</p>
                                                <ul className="tags">
                                                    Skilled:
                                                    {
                                                        business?.user_meta?.filter((item, index) => item.key === 'skilled')?.length === 1 ?

                                                            JSON.parse(business?.user_meta?.filter((item, index) => item.key === 'skilled')[0]?.value).map((tag, index) => {
                                                                return (
                                                                    <Badge className="capitalize" key={index} bg="light" text="dark" style={{ margin: "3px" }}>{tag?.label}</Badge>
                                                                )
                                                            })
                                                            : ''
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {loading && <Loader width="1.4rem" />}

                        <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body custom-border-card pb-0">

                                <div className="tab-content pt-0">

                                    <div role="tabpanel" id="biography" className="tab-pane fade show active">
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="widget about-widget custom-about mb-0">
                                                    <h4 className="widget-title">About Me</h4>
                                                    <hr />
                                                    <p style={{width:'100%'}}>{business?.business_description}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body custom-border-card pb-0">

                                <div className="widget education-widget mb-0">
                                    <h4 className="widget-title">Personal Details</h4>
                                    <hr />
                                    <div className="experience-box">
                                        <ul className="experience-list profile-custom-list">
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>Contact Number</span>
                                                        <div className="row-result">*****</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>Email Address</span>
                                                        <div className="row-result">*****</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body custom-border-card pb-0">

                                <div className="widget awards-widget m-0">
                                    <h4 className="widget-title">Location</h4>
                                    <hr />
                                    <div className="experience-box">
                                        <ul className="experience-list profile-custom-list">
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>Address 1</span>
                                                        <div className="row-result">*****</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>Address 2</span>
                                                        <div className="row-result">*****</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>Country</span>
                                                        <div className="row-result">{business?.country?.name}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>City</span>
                                                        <div className="row-result">{business?.city?.name}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="experience-content">
                                                    <div className="timeline-content">
                                                        <span>State</span>
                                                        <div className="row-result">{business?.state?.name}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body custom-border-card pb-0">

                                <div className="widget awards-widget m-0">
                                    <h4 className="widget-title">Recent Jobs</h4>
                                    <hr />
                                    <div className="experience-box">
                                        {loading && <Loader width="1.4rem" />}
                                        <BusinessProfileJob jobs={businessjob} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card col-10 me-auto ms-auto p-0">
                            <div className="card-body custom-border-card pb-0">

                                <div className="widget education-widget mb-0">
                                    <h4 className="widget-title">Review</h4>
                                    <hr />
                                    <div className="experience-box">
                                        {loading && <Loader width="1.4rem" />}
                                        <BusinessProfileRating serviceRating={serviceRating} />


                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card col-10 me-auto ms-auto p-0">



                        </div>


                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}


export default BusinessProfile