import { put } from "redux-saga/effects";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints } from "../../../../services/axios/endPoints";
import { LandingPageCreators } from "./index";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";

let userData = getUserDataFromLocal();

export function* getRecentJobSaga() {
  try {
    yield put(LandingPageCreators.getRecentJobStart());
    const response = yield WebService.get(apiEndPoints.recentJob 
    //   {
    //   headers: { Authorization: "Bearer " + userData.token },
    // }
    );
    if (response.status === 200) {
      const { data } = response.data;
      
      yield put(LandingPageCreators.getRecentJobSuccess({ data }));
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          yield put(
            LandingPageCreators.getRecentJobFailure({
              error: error?.response?.data?.message,
            })
          );
          break;
        case 500:
          yield put(
            LandingPageCreators.getRecentJobFailure({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            LandingPageCreators.getRecentJobFailure({
              error: "Unable to update. Try again later",
            })
          );
      }
    } else {
      yield put(
        LandingPageCreators.getRecentJobFailure({
          error: "No connection try again Later.",
        })
      );
    }
  }
}

export function* getFavJobSaga() {
  try {
    yield put(LandingPageCreators.getFavJobStart());
    const response = yield WebService.get(apiEndPoints.favJob, {
      headers: { Authorization: "Bearer " + userData.token },
    });

    if (response.status === 200) {
      const { data } = response.data;
      // console.log(data, "response");
      yield put(LandingPageCreators.getFavJobSuccess({ data }));
    } else {
      throw response;
    }
  } catch (error) {
    if (error?.response?.status) {
      switch (error.response.status) {
        case 400:
          yield put(
            LandingPageCreators.getFavJobFailure({
              error: error?.response?.data?.message,
            })
          );
          break;
        case 500:
          yield put(
            LandingPageCreators.getFavJobFailure({
              error: "Somthing went wrong try later",
            })
          );
          break;
        default:
          yield put(
            LandingPageCreators.getFavJobFailure({
              error: "Unable to update. Try again later",
            })
          );
      }
    } else {
      yield put(
        LandingPageCreators.getFavJobFailure({
          error: "No connection try again Later.",
        })
      );
    }
  }
}
