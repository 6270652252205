import { createReducer } from 'reduxsauce';
import { Types } from './action';

const INITIAL_STATE = {
  list: [],
  loading: false,
  publishedList: [],
};

const getPublishedJobStart = (state = INITIAL_STATE) => ({
  ...state,
  profileLoading: true,
});

const getPublishedJobSuccess = (state = INITIAL_STATE, action) => {
  // console.log(action.payload, 'payload in update');
  return {
    ...state,
    // updatedProfile:[]
    profileLoading: false,
  };
};

const getPublishedJobFailure = (state = INITIAL_STATE) => ({
  ...state,
  profileLoading: false,
});

const getCreatedJobStart = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const getCreatedJobSuccess = (state = INITIAL_STATE, action) => {
  // console.log('payload', action.payload);
  return {
    ...state,
    publishedList: [action.payload.data],
    loading: false,
  };
};

const getCreatedJobFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
});

const HANDLERS = {
  [Types.GET_PUBLISHED_JOB_START]: getPublishedJobStart,
  [Types.GET_PUBLISHED_JOB_SUCCESS]: getPublishedJobSuccess,
  [Types.GET_PUBLISHED_JOB_FAILURE]: getPublishedJobFailure,
  [Types.GET_CREATED_JOB_START]: getCreatedJobStart,
  [Types.GET_CREATED_JOB_SUCCESS]: getCreatedJobSuccess,
  [Types.GET_CREATED_JOB_FAILURE]: getCreatedJobFailure,
};

export const publishReducer = createReducer(INITIAL_STATE, HANDLERS);
