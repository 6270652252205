import React from "react";
// import { ReactComponent as LoaderSVG } from '../../assets/icons/loader.svg';
// import { Container } from "./Loader.style";
import { FlexContainer } from "../../globalStyles";

const Loader = ({ containerHeight, width }) => (
  <FlexContainer
    className="jc_center ai_center"
    style={{ height: containerHeight + "vh" }}
  >
    <div
      className="spinner-border"
      role="status"
      style={{ width, height: width  }}
    ></div>
  </FlexContainer>
);
{
  /* <Container><LoaderSVG /></Container> */
}
export default Loader;
