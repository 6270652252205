import { createReducer } from "reduxsauce";
import { Types } from "./action";

const INITIAL_STATE = {
  recentjobloading: false,
  recentJob: [],
  favjobloading: false,
  favJob: [],
  myjobloading: false,
  myJob: [],
};

const getRecentJobStart = (state = INITIAL_STATE) => ({
  ...state,
  recentjobloading: true,
});

const getRecentJobSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    recentJob: [...data],
    recentjobloading: false,
  };
};

const getRecentJobFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  recentjobloading: false,
});

const getFavJobStart = (state = INITIAL_STATE) => ({
  ...state,
  favjobloading: true,
});

const getFavJobSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.payload;
  return {
    ...state,
    favJob: [...data],
    favjobloading: false,
  };
};

const getFavJobFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  favjobloading: false,
});

const getMyJobStart = (state = INITIAL_STATE) => ({
  ...state,
  myjobloading: true,
});

const getMyJobSuccess = (state = INITIAL_STATE, action) => {
  // console.log(action, "actions");
  return {
    ...state,
    recentJob: [],
    myjobloading: false,
  };
};

const getMyJobFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  myjobloading: false,
});
const removeFavJob = (state = INITIAL_STATE, action) => {
  const newList = state.favJob.filter((item, index) => item.job_id !== action.payload.ele)
  // items: state.items.filter((item, index) => index !== action.payload)
  // console.log('action', newList)
  return {
    ...state,
    myjobloading: false,
  }
};

const HANDLERS = {
  [Types.GET_RECENT_JOB_START]: getRecentJobStart,
  [Types.GET_RECENT_JOB_SUCCESS]: getRecentJobSuccess,
  [Types.GET_RECENT_JOB_FAILURE]: getRecentJobFailure,
  [Types.GET_FAV_JOB_START]: getFavJobStart,
  [Types.GET_FAV_JOB_SUCCESS]: getFavJobSuccess,
  [Types.GET_FAV_JOB_FAILURE]: getFavJobFailure,
  [Types.GET_MY_JOB_START]: getMyJobStart,
  [Types.GET_MY_JOB_SUCCESS]: getMyJobSuccess,
  [Types.GET_MY_JOB_FAILURE]: getMyJobFailure,
  [Types.REMOVE_FAV_JOB]: removeFavJob
};

export const landingPageReducer = createReducer(INITIAL_STATE, HANDLERS);
