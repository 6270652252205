import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { EarnmoneyCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { ProfileCreators } from "../Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import Loader from "../../../../Common/Loader/Loader";
import { SideBar } from "../SideBar";
import moment from "moment";
import './style.css'
import AppHelmet from "../../Helmet/Helmet";
import { ArrowRight, CCircle, ArrowDownLeft, ArrowUpRight } from 'react-bootstrap-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import

export const Earnmoney = () => {
    const userData = getUserDataFromLocal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { profileData } = useSelector((state) => state.profileData);
    const { EarnmoneyList, Earnmoneyloading } = useSelector((state) => state.earndata);

    useEffect(() => {
        dispatch(EarnmoneyCreators.getEarnmoneyList());
    }, []);
    // console.log('EarnmoneyList', EarnmoneyList)

    const formatToRupees = (amount) => {
        const number = parseFloat(amount);
        if (isNaN(number)) {
          return ""; // Return empty string if the input is not a valid number
        }
        return new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 2,
        }).format(number);
      };

    const redeemMoney = (e) => {
        e.preventDefault();
        confirmAlert({
        title: 'Confirm to submit',
        message: <ul>
                        <strong style={{color:'green'}}>Reach 100.00+ rupees Rupees & Redeem Instantly!</strong>
                        <li>You're at {formatToRupees(EarnmoneyList?.earn_count)} ! Reach 100.00+ to redeem your rewards!</li>
                        <li style={{color:'gray'}}><strong>Note: </strong>You can’t redeem until your referral completes their profile!</li>
                        
                </ul>,
        buttons: [
            {
            label: 'Yes',
            onClick: () => alert('Please try over 100+!')
            },
            {
            label: 'No',
            onClick: () => alert('Please try again.')
            }
        ]
        });
    }

    return (
        <>
            <Toaster />
            <AppHelmet title="Earn Money by Referring Friends to Serviceora - Simple Registration Process" description="Boost your income by referring friends to Serviceora! Share your referral code, help others sign up, and earn cash rewards effortlessly. Sign up now to start earning." keywords="Serviceora referral program, earn money referral, refer friends Serviceora, referral code rewards, sign up and earn, referral bonus program, easy registration" />
            {/* <ModalComponent /> */}
            {Earnmoneyloading ? (
                <Loader containerHeight={70} width="4rem" />
            ) : (
                <div className="main-wrapper">
                    <div className="breadcrumb-bar">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-12">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                <Link to="/user/earns">Earning</Link>
                                            </li>
                                        </ol>
                                    </nav>
                                    <h2 className="breadcrumb-title">Earning</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <SideBar profileData={profileData} earn={EarnmoneyList?.earn_count} />
                                <div className="col-md-7 col-lg-8 col-xl-9">
                                   <section className="section-50">
                                        <div className="container">
                                            <h3 className="m-b-50 heading-line">Earning  <strong style={{color:'green'}}>{formatToRupees(EarnmoneyList?.earn_count)}</strong> <button class="btn btn-xs btn-primary" style={{padding:'5px', background:'black', fontSize:'13px'}} onClick={redeemMoney}>Redeem</button></h3> 

                                            <div className="coin-ui_dd-content">
                                                {Earnmoneyloading && <Loader width="1.4rem" />}
                                                {EarnmoneyList?.data?.length === 0 ? 'No money found!' : EarnmoneyList?.data?.map((earn, index) => {
                                                    return (
                                                        <div className={`coin-list text-muted'}`} key={index}>
                                                        <div className="coin-list_content">
                                                            <div className="coin-list_img">
                                                                {
                                                                    earn?.type === 'debit' ? 
                                                                        <ArrowDownLeft style={{color:'red', fontSize:'18px', fontWeight:'700'}} /> : <ArrowUpRight style={{color:'green', fontSize:'18px'}} />
                                                                    
                                                                }
                                                                
                                                                
                                                            </div>
                                                            <div className="notification-list_detail">
                                                                
                                                                <p className="text-primary" style={{fontSize:'15px'}}> &nbsp;&nbsp;{formatToRupees(earn?.coin)} </p>
                                                                <p className="text-secondary capitalize" style={{color:'#000000'}}>{earn?.detail}</p>
                                                            </div>
                                                        </div>
                                                        <div className="notification-list_feature-img">
                                                            <p className="text-muted"><small>{moment(earn?.created_at).fromNow()}</small></p>
                                                        </div>
                                                    </div>
                                                    )
                                                    
                                                })
                                                }
                                            </div>

                                            {/* <div className="text-center">
                                                <a href="#!" className="dark-link">Load more activity</a>
                                            </div> */}

                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
} 