import { put } from 'redux-saga/effects';
import { NationCreators } from './action';
import { WebService } from '../../../../../services/axios/webServices';
import { apiEndPoints } from '../../../../../services/axios/endPoints';
import { getUserDataFromLocal } from '../../../../../shared/utility/helper';

const userData = getUserDataFromLocal();
// console.log(userData.token, 'userData.token');

export function* getNationListSaga() {
  // console.log('dasda,==');
  try {
    yield put(NationCreators.getNationListStart());
    const response = yield WebService.get(apiEndPoints.nationLocation
    );

    // console.log(response, 'response')

    if (response.status === 200) {
      const { data, msg } = response.data;
      yield put(NationCreators.getNationListSuccess({ data, message: msg }));
    } else {
      throw response;
    }
  } catch (error) {
    console.log(error?.code, 'error');

    let payload;
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          payload = {
            error: error.response.data.message || error.response.data.Message
          };

          break;
        case 401:
          payload = {
            error: 'unAuthorized'
          };

          break;
        case 500:
          payload = {
            error: 'Somthing went wrong try later'
          };

          break;
        default:
          payload = {
            error: 'Unable to update. Try again later'
          };
      }
    } else {
      // for custom error
      payload = {
        error: 'No connection try again Later.'
        // error: 'custom error'
      };
    }
    yield put(NationCreators.getNationListFailure(payload));
  }
}
