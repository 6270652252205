import React, { useEffect, useState, useMemo } from "react";
import {
  Link,
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  NavLink,
  useNavigate,
  createSearchParams,
  Redirect
} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Footer } from "./Footer";
import { Creators } from "../../auth/store";
import RecentJob from "./RecentJob";
import { CategoryListView } from "./CategoryListView";
import { Toaster, toast } from "react-hot-toast";
import { apiEndPoints, rootConfig } from "../../../services/axios/endPoints";
import { LandingPageCreators } from "./store";
import Favjob from "./Favjob";
import { ProfileCreators } from "../Dashboard/store";
import { sagaCatchBlockHandling } from "../../../store/utility/sagaErrorHandling";
import { WebService } from "../../../services/axios/webServices";
import { NationCreators } from "./Nation/store";
import { CategoriesCreators } from "./Categories/store";
import { SubcategoryCreators } from "./Subcategory/store";
import { Testimonial } from "./Testimonial/Testimonial";
import Header from "./Header/Header";
import Loader from "../../../Common/Loader/Loader";
import { GlobalLocation } from "./GlobalLocation/GlobalLocation";
import { Modal } from "react-bootstrap";
import Select from 'react-select';
import { SocialCreators } from "./Social/store";
import bannerImg from "../../../../src/assets/img/banner1.png"
import AppHelmet from "../Helmet/Helmet";
import Button from 'react-bootstrap-button-loader';
import Nav from 'react-bootstrap/Nav';
import { useTour } from "@reactour/tour";
import { CoinsCreators } from "../Dashboard/Coins/store";
import { RefrelCodeFriend } from "./RefrelCodeFriend/RefrelCodeFriend";
import { CityCreators } from "./City/store";


const useAuth = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
}



export const Landing = () => {

  const { setIsOpen } = useTour();

  const auth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [gloading, gsetLoading] = useState(false)
  const [globalModal, setIsGlobalModal] = useState(false)
  const [serviceModal, setIsServiceModal] = useState(false)
  const [categoryName, setCategoryName] = useState('');
  const [subcategoryListt, setSubcategoryListt] = useState([]);
  const [subcategoryName, setSubcategoryName] = useState('');
  const [countries, setCountries] = useState([])
  const [itemsToShow, setItemsToShow] = useState(6);
  const [cityState, setCityState] = useState({})
  const [geoLoading, setGeoLoading] = useState(false)
  const [input, setInput] = useState({})
  const [cityList, setCityList] = useState([])
  const [footerCityList, setFooterCityList] = useState([]);

  const [show, setShow] = useState(true);
  const handleClose1 = () => setShow(false);



  const [globallocationcount, setGloballocationcount] = useState({
    global_location: "",
    total_customer: "",
    total_services: ""
  })
  const { profileData, dashboardLoading } = useSelector(
    (state) => state.profileData
  );

  const { CityList } = useSelector((state) => state.cityData);

  const { categoryloading, categoriesList } = useSelector((state) => state.categoriesData);
  const { subcategoryList, subcategoryloading } = useSelector((state) => state.subcategoryData);
  const { loginmsg, userData } = useSelector((state) => state.loginData);
  const { recentjobloading, recentJob, favjobloading, favJob } = useSelector((state) => state.landingPageData);


  const { loading, nationList } = useSelector((state) => state.nationData);

  const { socialList, socialloading } = useSelector((state) => state.socialData);

  const { coinsList, coinsloading } = useSelector((state) => state.coinsData);


  useEffect(() => {
    if (loginmsg !== "") {
      navigate(0)
      toast.success(loginmsg, { position: "top-right" });
    }
  }, [loginmsg]);

  // console.log('setu', CityList)

  const handleGlobalLocation = () => {
    setIsGlobalModal(true)
  }
  const options = categoriesList.map((category, index) => {
    return (
      { value: `${category?.slug}`, label: `${category?.name}` }
    )
  })

  const subcategoryoptions = subcategoryListt.map(subcategory => ({
    "value": subcategory?.slug,
    "label": subcategory?.name
  }))


  const statesListArray = nationList[0]?.states.map((state, index) => {
    return (
      { value: `${state?.slug}`, label: `${state?.name}` }
    )
  })

  const cityListArray = cityList.map((city, index) => {
    return (
      { value: `${city?.slug}`, label: `${city?.name}` }
    )
  })





  useEffect(() => {
    if (activeTab !== 1) {
      dispatch(LandingPageCreators.getFavJob());
    }
  }, [activeTab]);


  const global_location_count = async () => {
    try {
      gsetLoading(true)
      const response = await WebService.get(apiEndPoints.globalLocation,
        JSON.stringify(),
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (response?.status === 200) {
        gsetLoading(false)
        setGloballocationcount(response?.data?.data)

      } else if (response?.response?.status === 404) {
        gsetLoading(false)
      }
    }
    catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      console.log('error500', error?.response?.status)
      gsetLoading(false)

    }
  }


  const setTabStatus = (ele) => {
    setActiveTab(ele)
    dispatch(LandingPageCreators.getFavJob());
    dispatch(LandingPageCreators.getRecentJob());
  }

  useEffect(() => {
    dispatch(ProfileCreators.getDashCategories());
    // dispatch(ProfileCreators.getJobList({ page: 1 }));
    dispatch(Creators.onLoginSuccess({ msg: "", authenticated: false }));

    dispatch(ProfileCreators.getProfile());
    dispatch(LandingPageCreators.getRecentJob());
    dispatch(LandingPageCreators.getFavJob())
    dispatch(NationCreators.getNationList());
    dispatch(CategoriesCreators.getCategoriesList());
    dispatch(SubcategoryCreators.getSubcategoryList());
    dispatch(SocialCreators.getSocialList());
    dispatch(CoinsCreators.getCoinsList());
    dispatch(CityCreators.getCityList());

    handleCurrentLocationCity()
    global_location_count()

  }, []);



  const showmore = () => {
    setItemsToShow(subcategoryList.length)
  }

  const showless = () => {
    setItemsToShow(6)
  }

  const handleGlobalSubmit = (e) => {
    e.preventDefault()
    if (auth) {

      navigate({
        pathname: '/jobs',
        // search: `?${createSearchParams(params)}`,
        search: `?${createSearchParams(input)}`,
      });

    }
    else {
      navigate('/login')
    }

    // navigate(0);
  }


  const handleService = (e) => {
    e.preventDefault()
    setIsServiceModal(true)
  }

  const handleCurrentLocationCity = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setGeoLoading(true)
        // console.log("Latitude is :", position.coords.latitude);
        // console.log("Longitude is :", position.coords.longitude);
        const url = `${apiEndPoints?.currentCityApi}&lat=${position.coords.latitude}&lon=${position.coords.longitude}`
        fetch(url).then(res => res.json()).then(data => {
          setGeoLoading(false)
          setCityState({ city: data?.address?.city, state: data?.address?.state })
          setInput({ city: data?.address?.city })
        }).catch(() => {
          setGeoLoading(false)
          console.log('Error fetching data')
        })
      });
    }
    // const position = await Geolocation.getCurrentPosition();
    // const latitude = position.coords.latitude;
    // const longitude = position.coords.longitude;
    // console.log('position', position)

  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  useEffect(() => {
    // document.body.classList.toggle('menu-opened');
    setIsOpen(true)
  }, [])

  return (
    <>
      <AppHelmet title="Welcome to Serviceora - Find the Best Services and Providers" description="Discover top-rated service providers on Serviceora. Whether you're looking to hire or offer services, connect with trusted professionals and get your tasks done efficiently. Start exploring now!" keywords="Serviceora home, find services, hire professionals, service providers, offer services, service marketplace, discover service providers, trusted professionals, serviceora platform" />
      <Header />
      <Toaster />
      {
        profileData[0]?.city === null || profileData[0]?.state === null || profileData[0]?.city === null
          ?
          <Modal show={show} onHide={handleClose1} centered aria-labelledby="example-modal-sizes-title-lg" size="lg">
        <Modal.Header closeButton>
          <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}></span><i className="fa-solid fa-user fa-bounce" style={{ color: '#ff9c27' }}></i> Profile </Modal.Title>
        </Modal.Header>
        <Modal.Body>Earn coins by updating your profile! When you update your profile, you'll not only improve your experience, but you'll also boost your rewards even further.<br/>
        So, by taking a moment to update your profile, you're not only enhancing your own experience but also contributing to the overall improvement of our platform while earning rewards along the way.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Link to="/user/profile" className="btn btn-sm btn-primary">
            Add Skill, Location 
          </Link>
        </Modal.Footer>
      </Modal>
          // <Modal
          //   show={true}
          //   size="sm"
          //   onHide={() => setIsGlobalModal(true)}
          //   backdrop="static"
          //   keyboard={false}
          //   aria-labelledby="example-modal-sizes-title-sm"
          //   centered
          // >
          //   <Modal.Header closeButton>
          //     <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}></span><i className="fa-solid fa-user fa-bounce" style={{ color: '#ff9c27' }}></i> Profile </Modal.Title>
          //   </Modal.Header>
          //   <Modal.Body>
          //     <form onSubmit={handleGlobalSubmit}>
          //       <div className="row">
          //         <p> Earn coins by updating your profile! When you update your profile, you'll not only improve your experience, but you'll also boost your rewards even further. <a href="/user/profile" className='text-primary'><strong>Update Profile...</strong></a></p>
          //       </div>
          //     </form>
          //   </Modal.Body>
          // </Modal>
          :
          ''
      }
      {globalModal && (
        <Modal
          show={true}
          size="lg"
          onHide={() => setIsGlobalModal(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}></span> Service Job Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleGlobalSubmit}>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <Select className="capitalize" options={statesListArray} onChange={(e) => {
                      setInput({ ...input, 'state': e?.value })
                      let a = nationList[0]?.states.filter((item, index) => item.slug === e?.value)
                      setCityList(a[0]?.cities)

                    }}
                      placeholder="Select State" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">

                    <Select className="capitalize" options={cityListArray} onChange={(e) => {
                      setInput({ ...input, 'city': e?.value })

                    }}
                      placeholder="Select City" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    {/* <button className="btn btn-primary login-btn " type="submit">
                      {loading ? <Loader /> : "Search Job"}
                    </button> */}
                    <Button className="btn btn-primary login-btn" type="submit" loading={loading ? true : false}>Search Job!</Button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
      {serviceModal && (
        <Modal
          show={true}
          size="lg"
          onHide={() => setIsServiceModal(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}></span> Service Job List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleGlobalSubmit}>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    {/* <label className="form-control-label">Choose Country</label> */}
                    <Select className="capitalize" options={options} onChange={(e) => {
                      setInput({ ...input, 'category': e?.value })
                      let a = categoriesList.filter((item, index) => item.slug === e?.value)
                      setSubcategoryListt(a[0]?.subcategories)

                    }}
                      placeholder="Select Category" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    {/* <label className="form-control-label">Choose Country</label> */}
                    <Select className="capitalize" options={subcategoryoptions} onChange={(e) => {
                      setInput({ ...input, 'subcategory': e?.value })
                    }}
                      placeholder="Select Sub Category" />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <button className="btn btn-primary" type="submit">
                      {loading ? <Loader /> : "Search Job"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}


      <div className="main-wrapper">
        <section className="banner-section" style={{ backgroundSize: 'cover !important', background: 'url(' + rootConfig?.base_url + socialList?.bannerImg + ')' }}>
          <div className="container">
            <div className="banner-content text-center">
              <div className="banner-heading">
                <h2>{socialList?.title}</h2>
                <p>{socialList?.subtitle}</p>
              </div>
              <div className="banner-forms">
                <form className="banner-form" onSubmit={handleGlobalSubmit}>
                  <div className="input-group-form form-style form-br col-md-3 col-12">
                    <i className="fas fa-map-marker-alt text-warning" onClick={handleCurrentLocationCity}></i> {geoLoading && <Loader width="1rem" />}
                    <input
                      className="input-style-form"
                      type="text"
                      placeholder="Search Location"
                      name="city"
                      defaultValue={cityState?.city}
                      onChange={handleChange}
                    />

                  </div>
                  <div className="input-group-form form-style col-md-6 col-12">
                    <input
                      className="input-style-form"
                      type="text"
                      placeholder="Search city and near by job, etc"
                      name="service" onChange={handleChange}
                    />
                  </div>
                  {/* <button
                    className="btn button-form col-md-3 col-12"
                    type="submit"
                  >
                    Search Now
                  </button> */}
                  <Button className="btn button-form col-md-3 col-12" type="submit" loading={loading ? true : false}>Search Now!</Button>
                </form>
              </div>
            </div>
            <div className="banner-footer">
              <GlobalLocation globallocationcount={globallocationcount} gloading={gloading} handleGlobalLocation={handleGlobalLocation} handleService={handleService} />
            </div>
          </div>
        </section>

        <section className="popular-course-section">
          <div className="container">
            <div className="section-heading d-flex align-items-center">
              <div className="heading-content">
                <h2>
                  <span className="text-weight">Explore Popular</span> Jobs
                  <span className="header-right">
                  </span>
                </h2>
              </div>
            </div>
            <div className="row">
              <section className="allcourse-section bg-grey">
                <div className="container">
                  <div className="row" data-tour="step-3">
                    <CategoryListView
                      categorylist={subcategoryList}
                      itemsToShow={itemsToShow}
                      auth={auth}
                    />
                    {categoryloading && <Loader width="1.4rem" />}
                  </div>
                </div>
                <div className="section-btn m-auto text-center">
                  {(itemsToShow === 6) ?
                    <button className="btn btn-course" onClick={showmore}>View all Jobs <i className="fas fa-caret-right right-nav-white"></i></button> : <button className="btn btn-course" onClick={showless}>View Less Services <i className="fas fa-caret-right right-nav-white"></i></button>}
                </div>
              </section>
            </div>
          </div>
        </section>
        <section className="popular-course-section">
          <div className="container">
            <div className="section-heading d-flex align-items-center gap-2">
              <div className="section-btn">
                <>
                  <button
                    className={`btn btn-jobs ${activeTab === 1 && "active-jobs"
                      }`}
                    onClick={() => setTabStatus(1)}
                  >
                    Recent Jobs
                  </button>
                </>
              </div>
              <div className="section-btn">
                <>
                  <button
                    className={`btn btn-jobs ${activeTab === 2 && "active-jobs"
                      }`}
                    onClick={() => setTabStatus(2)}
                  >
                    Favourite Jobs
                  </button>
                </>
              </div>
            </div>
            <>
              {activeTab === 1 ? (
                <>
                  <RecentJob data={recentJob} recentjobloading={recentjobloading} auth={auth} coins={coinsList?.coin_count} today_bids={coinsList?.today_bids} />
                  {recentjobloading && <Loader width="1.4rem" />}

                  <div className="load-more text-center">
                    <Link
                      to={`${auth ? "/jobs" : "/login"}`}
                      className="btn btn-primary btn-sm">
                      View All <i className="fas fa-caret-right right-nav-white"></i>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <Favjob data={favJob} coins={coinsList?.coin_count} today_bids={coinsList?.today_bids} />
                  {favjobloading && <Loader width="1.4rem" />}
                </>
              )}
            </>
          </div>
        </section>

        <section className="instructor-section bg-grey">
          <div className="container">
            <div className="section-heading d-flex align-items-center">
              <div className="heading-content">
                <h2>
                  <span className="text-weight">Featured </span> Category
                  <span className="header-right"></span>
                </h2>
                <p>They are highly qualified and trained in their areas</p>
              </div>
            </div>
            <div className="row">
              {
                categoriesList.map((category, index) => {
                  return (
                    <div key={index} className="col-lg-6 col-md-12 col-sm-12">
                      <div className="instructor-card">
                        <div className="instructor-profile align-items-center">
                          <div className="instructor-img">
                            <img src={rootConfig?.base_url + category?.image} alt={category?.name} height="100px" width="100px" />
                          </div>
                          <div className="instructor-desc">
                            <h3 className="mb-0 text-warning capitalize">{category?.name}</h3>
                            <p>
                              {category?.description.substring(0, 130)} ...
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }

              {/* <div className="section-btn m-auto text-center">
                <button className="btn btn-course">
                  Become a Service Provider
                  <i className="fas fa-caret-right right-nav-white"></i>
                </button>
              </div> */}
            </div>
          </div>
        </section>
        <section className="testimonial-section bg-grey">
              <RefrelCodeFriend profileData={profileData} />
        </section>

        <section className="testimonial-section bg-grey">
          <div className="container">
            <div className="section-heading d-flex align-items-center">
              <div className="heading-content">
                <h2>
                  <span className="text-weight">What People Says</span>
                  <span className="header-right"></span>
                </h2>
              </div>
            </div>
          </div>
          <div className="section-header text-center">
            {/* <h3>An incredible experience that exceeded all expectations!</h3> */}
            <p className="sub-title">Are you looking to join online services? Now it's very simple, Sign up with
              Serviceora</p>
          </div>
          <Testimonial />
        </section>
        <Footer />
      </div>
    </>
  );
};

