import { ArrowRight, CCircle, ArrowDownLeft, ArrowUpRight, Bell } from 'react-bootstrap-icons';
const storageLocal = localStorage.getItem("user");



const steps = [
    {
      selector: '[data-tour="step-1"]',
      content: <div>
        <p>
        <i className="fa-solid fa-bell fa-shake fa-0.7x" style={{color:'#ff9c27'}}></i> Explore your notifications list for updates. View details and stay informed about important messages and events. <a href="/user/notification" className='text-primary'><strong>Click here...</strong></a></p>
        <hr/>
        <p>  <CCircle className='fa-shake fa-6x' style={{backgroundColor:'#ff9c27',borderRadius:'50%', color:'white', fontSize:'18px'}}/> Review your coins list to see your balance.  <a href="/user/coins" className='text-primary'><strong>Click here...</strong></a></p>
        <hr/>
        {storageLocal ? 
        <>
            <strong style={{color:'#009da6'}}> Post your requirements:</strong> "Need a service? Share your requirements and unlock the best deals! Your job, your terms – find the perfect match through our bidding process." <hr/> <strong style={{color:'#009da6'}}>Be a Service Provider:</strong> "Ready to work? Showcase your skills and bid on job offers. It's your opportunity to land the job and deliver excellence. Join the bidding process now!"
        </>
        : 
        <>
            <strong style={{color:'#009da6'}}> Log In Button:</strong> Look for a login button that says "Log In" or "Sign In". <hr/> <strong style={{color:'#009da6'}}>Create Account Button:</strong> Look for a create account button that says "Sign Up" or "Create Account." <hr/><ul className="nav header-navbar-rht" data-tour="step-1"><li className="nav-item"><a className="nav-link header-login" href="/login">Log In</a></li><li className="nav-item"><a className="nav-link header-register" href="/register">Create Account</a></li></ul>
        </>
        }
        <hr/>
                
      </div>
    },
    {
      selector: '[data-tour="step-2"]',
      content: <div>
        <strong>Overview:</strong> Think of it as your home base. The dashboard gives you a quick look at key information, so you don't have to hunt around.<br/>
        <strong>Updates and Notifications: </strong> Imagine your dashboard as a message board. It tells you what's new and if there's anything you need to pay attention to.
      </div>
    },
    {
      selector: '[data-tour="step-3"]',
      content: "Discover jobs that match your skills and are suitable for your specific location. Find the perfect fit for you!"
    }
  ];
  
  export default steps;
  