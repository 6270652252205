import React from "react";
import ProtectedRoutes from "./ProtectedRoutes";
import { Navigate, Route, Routes } from "react-router-dom";
import { Landing } from "../view/Pages/Landing/Landing";
import { Login } from "../view/auth/Login";
import PublicRoutes, { InnerContent, OuterContent } from "./PublicRoutes";
import { Dashboard } from "../view/Pages/Dashboard/Dashboard";
import { Register } from "../view/auth/Register";
import { Profile } from "../view/Pages/Dashboard/Profile/Profile";
import { ProfileSettings } from "../view/Pages/Dashboard/Profile/ProfileSettings";
import { JobForm } from "../view/Pages/Dashboard/Job/JobForm";
import { PublishedJob } from "../view/Published/PublishedJob";
// import { Job } from "../view/Job/Job.view";
import { Job } from "../view/Pages/Dashboard/Job/Job.view";
import { getUserDataFromLocal } from "../shared/utility/helper";
import { ShowJob } from "../view/Pages/Dashboard/Job/ShowJob";
import { MyJob } from "../view/Pages/Dashboard/MyJob/MyJob"
import { JobMessage } from "../view/Pages/Dashboard/JobMessage/JobMessage"
import Verifyemail from "../view/Pages/Landing/Verifyemail/Verifyemail"
import ForgetPassword from "../view/Pages/Landing/ForgetPassword/ForgetPassword"
import ResetPassword from "../view/Pages/Landing/ForgetPassword/ResetPassword"
import ChangePassword from "../view/Pages/Dashboard/ChangePassword/ChangePassword"
import { National } from "../view/Pages/Dashboard/National/National"
import { Nation } from "../view/Pages/Landing/Nation/Nation"
import { JobInvoice } from "../view/Pages/Dashboard/JobInvoice/JobInvoice"
import { ShowJobInvoice } from "../view/Pages/Dashboard/JobInvoice/ShowJobInvoice"
import Contact from "../view/Pages/Landing/Contact/Contact"
import Privacy from "../view/Pages/Landing/Privacy/Privacy"
import Term from "../view/Pages/Landing/Term/Term"
import BusinessProfile from "../view/Pages/Landing/BusinessProfile/BusinessProfile"
import { NotFound } from "../view/NotFound/NotFound";
import { Subcategory } from "../view/Pages/Landing/Subcategory/Subcategory";
import { TicketRaise } from "../view/Pages/Dashboard/TicketRaise/TicketRaise";
import { Testimonial } from "../view/Pages/Landing/Testimonial/Testimonial";
import About from "../view/Pages/Landing/About/About";
import { UserNotification } from "../view/Pages/Dashboard/UserNotification/UserNotification";
import Setting from "../view/Pages/Dashboard/Setting/Setting";
import JobUpdate from "../view/Pages/Dashboard/Job/JobUpdate";
import { Otpverify } from "../view/auth/Otpverify";
import {LoginWithMobileNumber} from "../view/auth/LoginWithMobileNumber"
import UpdateVerifyemail from "../view/Pages/Landing/UpdateVerifyemail/UpdateVerifyemail";
import { Coins } from "../view/Pages/Dashboard/Coins/Coins";
import { Earnmoney } from "../view/Pages/Dashboard/Earnmoney/Earnmoney";
import FindServiceProviderList from "../view/Pages/Landing/FindServiceProviderList/FindServiceProviderList";
import { Enquiry } from "../view/Pages/Dashboard/Enquiry/Enquiry";
import { City } from "../view/Pages/Landing/City/City";


export const MainRoutes = () => {
  const userData = getUserDataFromLocal();

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/" element={<InnerContent />}>
          <Route index element={<Landing />} />
          {/* <Route path="/" element={<Navigate replace to="mainpage" />} /> */}
          <Route path="user/dashboard" element={<Dashboard />} />
          <Route path="user/profile" element={<Profile />} />
          <Route path="user/profile-settings" element={<ProfileSettings />} />
          <Route path="user/change-password" element={<ChangePassword />} />
          <Route path="published-jobs" element={<PublishedJob />} />
          <Route path="jobs" element={<Job />} />
          <Route path="jobs/:job_code" element={<ShowJob />} />
          <Route path="user/job/create" element={<JobForm />} />
          <Route path="user/jobs/myjobs" element={<MyJob />} />
          <Route path="user/job/message/:job_id" element={<JobMessage />} />
          <Route path="user/national" element={<National />} />
          <Route path="user/jobs/invoices" element={<JobInvoice />} />
          <Route path="user/job/order/:order_no/show" element={<ShowJobInvoice />} />
          <Route path="business/:user_name" element={<BusinessProfile />} />
          <Route path="user/ticket_raise" element={<TicketRaise />} />
          <Route path="user/notification" element={<UserNotification />} />
          <Route path="user/setting" element={<Setting />} />
          <Route path="user/job/update/:job_code" element={<JobUpdate />} />
          <Route path="user/coins" element={<Coins />} />
          <Route path="user/earns" element={<Earnmoney />} />
          <Route path="user/enquiry" element={<Enquiry />} />
          <Route path="user/service/list" element={<FindServiceProviderList />} />
        </Route>
      </Route>
      {/* <Route path="/" element={<PublicRoutes />}> */}
      <Route path="/" element={<OuterContent />}>
        {/* <Route path="/" element={<Navigate replace to="dashboard" />} /> */}
        <Route path="home" element={<Landing />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="otp-verify/:token" element={<Otpverify />} />
        <Route path="login-with-mobile" element={<LoginWithMobileNumber />} />
        <Route path="verify-email/:token" element={<Verifyemail />} />
        <Route path="update-verify-email/:token" element={<UpdateVerifyemail />} />
        <Route path="forgot-password" element={<ForgetPassword />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
        <Route path="nation" element={<Nation />} />
        <Route path="cities" element={<City />} />
        <Route path="contact-us" element={<Contact />} />
        <Route path="privacy&policy" element={<Privacy />} />
        <Route path="term&conditions" element={<Term />} />
        <Route path="testimonial" element={<Testimonial />} />
        <Route path="about-us" element={<About />} />
      </Route>
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" />}></Route>
      {/* </Route> */}
    </Routes>
  );
};
