import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { NationCreators } from "./store";
import { CategoriesCreators } from "../Categories/store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import {
    GetCountries,
    GetState,
    GetCity,
    GetLanguages, //async functions
  } from "react-country-state-city";

export const Nation = () => {
    const userData = getUserDataFromLocal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, nationList } = useSelector((state) => state.nationData);
    // const { categoriesList } = useSelector((state) => state.categoriesData);
    const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);
  const [language, setLanguage] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [countryList, setCountries] = useState([])
    
    useEffect(() => {
        dispatch(NationCreators.getNationList());
        // dispatch(CategoriesCreators.getCategoriesList());
    }, []);


      // console.log('countryList', nationList)

    return (
      <p>Hello, I am here</p>
    )
} 