import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Creators } from "../../auth/store";
import { ArrowRight, CCircle, ArrowDownLeft, ArrowUpRight, CurrencyRupee } from 'react-bootstrap-icons';
import { UserNotificationCreators } from "./UserNotification/store";


export const SideBar = ({ handleAddJob, profileData, coins, earn }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleLogoutAllDevice = () => {
    dispatch(Creators.onLogoutAllDevice());
    navigate("/");
  };

  const { usernotificationList, usernotificationloading } = useSelector((state) => state.userNotificationData);




  return (
    <>
      <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
        <div className="profile-sidebar">
          <div className="user-widget">
            <div className="pro-avatar">
              {profileData?.[0]?.name?.match(/\b(\w)/g).join('').toLocaleUpperCase()}

            </div>

            <div className="user-info-cont">
              <h4 className="usr-name">{profileData[0]?.name?.toUpperCase()}</h4>
            </div>
          </div>

          <div className="custom-sidebar-nav">
            <ul>
              <li>
                <Link to="/user/dashboard">
                  <i className="fas fa-gauge"></i>DashBoard
                  <span><i className="fas fa-chevron-right"></i></span>
                </Link>
              </li>
              <li>
                <Link to="/user/enquiry">
                  <i className="fas fa-question-circle"></i>Enquiry
                  <span><i className="fas fa-chevron-right"></i></span>
                </Link>
              </li>
              <li>
                <Link to="/user/earns">
                <CurrencyRupee style={{backgroundColor:'green',borderRadius:'50%', color:'white', fontSize:'18px'}}/> &nbsp;Earning
                  <span> <i className="fas fa-chevron-right"></i></span>
                </Link>
              </li>
              <li>
                <Link to="/user/coins">
                <CCircle style={{backgroundColor:'#ff9c27',borderRadius:'50%', color:'white', fontSize:'18px'}}/> &nbsp;Coins
                  <span> <i className="fas fa-chevron-right"></i></span>
                </Link>
              </li>
              <li>
                <Link to="/user/notification">
                  <i className="fas fa-bell"></i>Notification
                  <span> {usernotificationList?.notifications?.filter(notify => {
                        return notify.read === 0;
                      })?.length} <i className="fas fa-chevron-right"></i></span>
                </Link>
              </li>
              <li>
                <Link to="/user/profile">
                  <i className="fas fa-user-md"></i>Profile
                  <span> <i className="fas fa-chevron-right"></i> </span>
                </Link>
              </li>
              <li>
                <Link to="/user/change-password">
                  <i className="fas fa-lock"></i>Change Password
                  <span> <i className="fas fa-chevron-right"></i> </span>
                </Link>
              </li>
                  {/* <li><Link to="/Jobs">
                    <i className="fas fa-user-cog"></i>Jobs
                    <span><i className="fas fa-chevron-right"></i></span>
                  </Link>
                  </li> */}
                  <li>
                    <Link to="/user/job/create">
                      <i className="fas fa-plus"></i>Add Job
                      <span><i className="fas fa-chevron-right"></i></span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user/jobs/myjobs">
                      <i className="fas fa-clipboard-list"></i>My Jobs
                      <span><i className="fas fa-chevron-right"></i></span>
                    </Link>
                  </li>
              <li>
                <Link to="/user/jobs/invoices">
                  <i className="fas fa-receipt"></i>Orders
                  <span><i className="fas fa-chevron-right"></i></span>
                </Link>
              </li>
              <li>
                <Link to="/user/ticket_raise">
                  <i className="fa fa-ticket"></i>Ticket Raise
                  <span><i className="fas fa-chevron-right"></i></span>
                </Link>
              </li>
              <li>
                <Link to="/user/setting">
                  <i className="fas fa-cog"></i>Setting
                  <span><i className="fas fa-chevron-right"></i></span>
                </Link>
              </li>

              {/* {location.pathname !== "/mainpage" ? null : (
                <>
                  <li onClick={handleAddJob}>
                    <Link className="edit-link">
                      <i className="fas fa-user-cog"></i> Add Job
                      <span>
                        <i className="fas fa-chevron-right"></i>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link className="edit-link" to="/published-jobs">
                      <i className="fas fa-user-cog"></i>Published Job
                      <span>
                        <i className="fas fa-chevron-right"></i>
                      </span>
                    </Link>
                  </li>
                </>
              )} */}
              {/* <li onClick={handleLogoutAllDevice}>
                <Link>
                  <i className="fas fa-sign-out-alt"></i>Logout All Device
                  <span>
                    <i className="fas fa-chevron-right"></i>
                  </span>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
