import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { NationalCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";

export const National = () => {
    const userData = getUserDataFromLocal();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, nationalList } = useSelector((state) => state.nationalData);
    
    useEffect(() => {
        dispatch(NationalCreators.getNationalList());
    }, []);

    console.log('national', useSelector((state) => state.nationalData))

    return (
        <h2>Hello National</h2>
    )
} 