import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FlexContainer } from "../../globalStyles";
// import { Loader } from "../../common/Loader";
import styled from "styled-components";
import Loader from "../Loader/Loader";
import { rootConfig } from "../../services/axios/endPoints";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Button from 'react-bootstrap-button-loader';

export const Table = ({ data, loading, columns, userData, approveBid }) => {
  const useAuth = () => {
    const user = localStorage.getItem("user");
    if (user) {
      return true;
    } else {
      return false;
    }
  };
  const auth = useAuth();
  // console.log('userData', userData)
  // console.log('yes, i am here', userData.userData.id)
  //   const [loading, setLoading] = useState(false);
  //   const [error, setError] = useState('');

  // const handleObserver = (entries) => {
  //   const target = entries[0];
  //   if (target.isIntersecting) {
  //     handleNext({
  //       sortBy: activeSortCol,
  //     });
  //   }
  // };

  const observer = useRef();
  const loader = useCallback(
    // (node) => {
    //   if (userLoading || error) return;
    //   const option = {
    //     root: null,
    //     rootMargin: '300px',
    //     threshold: 1.0
    //   };
    //   if (observer.current) observer.current.disconnect();
    //   observer.current = new IntersectionObserver(option);
    //   if (node) observer.current.observe(node);
    // },
    [loading]
  );

  const getValue = (val, key, item, column) => {

    if (column.label === "Amount") {
      return item["amount_sign"] + " " + val;
    } else if (column?.label === "Username") {
      return (
        <>
          <h2 className="table-avatar">
            <Link to={`${auth ? `/business/${item?.user?.user_name}` : "/login"}`} className="avatar avatar-sm me-2">
              <img className="avatar-img rounded-circle" src={rootConfig.base_url + item?.user?.profile} alt={item?.user?.name} />
            </Link>
            <Link className="text-warning" to={`${auth ? `/business/${item?.user?.user_name}` : "/login"}`}> {item?.user?.name} <span className="text">{item?.user?.type !== 'Customer' ? '(Service Provider)' : '(Customer)'}</span>

              <span>
                bid <b className="text-success" style={{ textTransform: "lowercase" }}>{moment(item?.created_at).fromNow()}</b>
              </span>
            </Link>

          </h2>
        </>
      )
    } else if (column?.label === "Duration") {
      return val + " " + item["duration_key"];
    }
    //  else if (column?.label === "Profile") {
    //   return item?.user?.profile;
    // }
    else {
      return val;
    }

  };

  // const approveBid = (ele) => {
  //   console.log(ele, 'Yes, I am approving the bid');

  // }

  // console.log(data, "0000");

  return (
    <div className="col-sm-12">
      <div className="card card-table">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover table-center m-0">
              <thead>
                <tr>
                  {columns.map((column, index) => {
                    const { align, key, label, id, isSortable } = column;
                    // console.log('column', column)
                    return (
                      <th className="bg" key={index}
                        id={id}
                        style={{
                          cursor: "pointer",
                          fontWeight: "700",
                          // background: "#ff9c27",
                          // color:"white"
                        }}
                        scope="col"
                      >
                        {label}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <FlexContainer
                    className="jc_center ai_center"
                    style={{ position: "relative", left: "250px" }}
                  >
                    <Loader width="1.4rem" />
                  </FlexContainer>
                ) : (
                  Object.values(data || {}).length > 0 &&
                  Object.values(data || {}).map((item, index) => {

                    return (
                      <tr key={index}>
                        {columns.map((column, index) => {
                          const { key, isTrue, isFalse, align } = column;
                          return (
                            <td
                              key={key}
                              id={key}
                              style={{ textTransform: "capitalize", whiteSpace: "normal" }}
                            >
                              {key === "profile" || key === "image" ? ''

                                : key === "action" ?
                                  <>
                                    {auth && userData?.userData?.id === item?.job?.user_id ? (
                                      <Button className="btn btn-sm btn-primary" type="submit" loading={loading ? true : false} onClick={() =>
                                        approveBid(item?.job_id, item?.id)}>
                                        <i className="fa-solid fa-thumbs-up"></i> Accept Bid!</Button>
                                    ) : userData?.userData?.id}
                                  </>
                                  : key === "status" ?
                                    <>
                                      {item?.status === 'Approved' ?
                                        <span className="badge bg-info-light">{item?.status}</span>
                                        : item?.status === 'Processing' ?
                                        <span className="badge bg-primary-light">{item?.status}</span>
                                        : item?.status === 'Active' ?
                                          <span className="badge bg-success-light">{item?.status}</span>
                                          : item?.status === 'Completed' ?
                                            <span className="badge bg-danger-light">{item?.status}</span>
                                            : item?.status === 'Cancel' ?
                                              <span className="badge bg-warning-light">{item?.status}</span>
                                              : item?.status === 'Deleted' ?
                                                <span className="badge rounded-pill badge-danger">{item?.status}</span>
                                                : ''}
                                    </>
                                    : (
                                      getValue(item?.[key], key, item, column)
                                    )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                )}
                {data?.length < 1 && (
                  <tr className="noHover">
                    <td colSpan={columns?.length}>
                      No bid found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  );
};

const Image = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
`;
