import React, { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Header from "../Header/Header"
import { useNavigate, Link } from "react-router-dom";
import { Footer } from "../Footer"
import AppHelmet from "../../Helmet/Helmet";



const Term = () => {

  // useEffect(() => {
  //   document.body.classList.toggle('menu-opened');
  // }, [])
  return (
    <>
      <Header />
      <AppHelmet title="Terms & Conditions - Serviceora Usage Guidelines" description="Read Serviceora’s terms and conditions to understand the rules and guidelines for using our platform. Learn about your rights, responsibilities, and our policies for a safe and effective experience." keywords="terms and conditions Serviceora, usage guidelines, platform rules, Serviceora policies, user responsibilities, legal terms, Serviceora terms, service agreement, platform use policy" />
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-3 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Terms & Conditions
                  </li>
                </ol>
              </nav>
            </div>

          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="section-heading d-flex align-items-center">
                    <div className="heading-content">
                      <h2>
                        <span className="text-weight">Privacy & Policy:</span> {" "}
                        <span className="header-right"></span>
                      </h2>
                      <p>
                      </p>
                      <p>
                        <span>
                          By using <span style={{ color: '#009da6' }}> Serviceora</span> website or services, you agree to the following terms and conditions. Please read them carefully before proceeding:
                        </span>
                      </p>
                      <section id="about" className="about">
                        <div className="container" data-aos="fade-up">

                          <div className="row">
                            <div className="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
                              <p className="fst-italic">
                              </p>
                              <p></p>
                              <ul>
                                <li><i className="fa-solid fa-check-double"></i><strong>User Responsibility: </strong> Serviceora provides a platform to connect customers with service providers. We do not endorse, control, or take responsibility for any transactions, communications, or interactions between users, including customers and service providers.</li>
                                <li><i className="fa-solid fa-check-double"></i><strong>Third-Party Services: </strong> Service providers listed on Serviceora platform are independent contractors and not employees or agents of Serviceora. We do not guarantee the quality, accuracy, legality, or suitability of the services provided by the service providers.</li>
                                <li><i className="fa-solid fa-check-double"></i><strong>User Conduct: </strong> Users are solely responsible for their actions and interactions on our platform. You agree not to use Serviceora for any unlawful, fraudulent, or malicious purposes. We reserve the right to suspend or terminate accounts that violate these terms.</li>
                                <li><i className="fa-solid fa-check-double"></i><strong>Payment Transactions: </strong> Any financial transactions, including payment for services, are solely between the customer and the service provider. Serviceora is not involved in payment processing, and we do not assume any liability for payment disputes, errors, or issues arising from these transactions.</li>
                                <li><i className="fa-solid fa-check-double"></i><strong>Communication and Agreements: </strong> Any communications, agreements, or contracts made between customers and service providers are their sole responsibility. Serviceora does not act as an intermediary or agent in these communications and will not be liable for any disputes or disagreements.</li>
                                <li><i className="fa-solid fa-check-double"></i><strong>User Verification: </strong> Serviceora may provide verification services for users to build trust within our community. However, we do not guarantee the accuracy or authenticity of user-provided information. Users should exercise caution and conduct their due diligence when engaging with others.</li>
                                <li><i className="fa-solid fa-check-double"></i><strong>Limitation of Liability: </strong> Serviceora, its officers, employees, agents, and affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of our platform, even if we have been advised of the possibility of such damages</li>
                                <li><i className="fa-solid fa-check-double"></i><strong>Indemnification: </strong> You agree to indemnify and hold Serviceora, its officers, employees, agents, and affiliates harmless from any claims, losses, damages, liabilities, costs, and expenses (including attorneys' fees) arising from your use of our platform or any violation of these terms.</li>
                                <li><i className="fa-solid fa-check-double"></i><strong>Modification of Terms: </strong> Serviceora reserves the right to modify or update these terms and conditions at any time without notice. Continued use of our platform after any changes constitute acceptance of the modified terms.</li>
                                <li><i className="fa-solid fa-check-double"></i><strong>Governing Law: </strong> These terms and conditions shall be governed by and construed in accordance with the laws of India.</li>
                              </ul>
                              <h4>Bidding Fee on Job</h4>
                              <p>
                                A small fee of 5 rupees is charged to customers for make a bid on job.
                              </p>
                              <p></p>
                              <ul>
                                <li><i className="fa-solid fa-check-double"></i><strong>Bidding Fee: </strong>The bid amount fee is only 5 rupees.</li>
                              </ul>

                              <h4>Contact Us</h4>
                              <p>
                                If you have any questions or concerns about these terms and conditions, please contact us at <span style={{ color: '#ff9c27' }}>info@ideativemind.com</span>.
                              </p>
                              <h4>Notes:</h4>
                              <p>
                                At Serviceora, we are committed to providing a secure and trustworthy platform for all our users. We take any complaints seriously and are dedicated to maintaining the highest standards of service and conduct. We want to inform you about our complaint investigation and blacklisting policy to ensure a fair and accountable environment for everyone.
                              </p>
                              <p>If we receive a complaint from either a customer or a service provider regarding any transaction, communication, or interaction on our platform, we will initiate a thorough investigation. Our team will carefully review the matter, gather relevant information, and assess the evidence provided.</p>
                              <p>
                                If, after the investigation, we find the accused party guilty of any misconduct, violation of our terms and conditions, or engaging in fraudulent activities, we will take appropriate actions. Such actions may include, but are not limited to, removing the offender's access to our platform, terminating their account, and adding them to our blacklist.
                              </p>
                              <p>
                                The blacklist is a measure we implement to protect the integrity of our community. Being placed on the blacklist means that the individual or entity will be prohibited from using our platform for future transactions and interactions.
                              </p>
                              <p>
                                We believe that this policy will foster a safe and reliable environment for all our users. However, please note that any false or malicious complaints will also be subject to investigation, and false claims may result in sanctions against the person making the baseless accusation.
                              </p>
                              <p>
                                We encourage all users to communicate openly and transparently, treating each other with respect and professionalism. If you encounter any issues or concerns, please report them to our support team through the appropriate channels.
                              </p>
                              <p>
                                Thank you for being a part of the (<span style={{ color: '#009da6' }}>Serviceora, </span>) community and helping us maintain a trustworthy and positive experience for all.
                              </p>
                              <p>
                                By using Serviceora platform, you acknowledge that you have read, understood, and agreed to these terms and conditions.
                              </p>
                              <p>
                                Thank you for choosing Serviceora as your service platform.
                              </p>


                            </div>
                          </div>

                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}


export default Term