import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { FlexContainer } from "../../../../globalStyles";
import SearchableDropdown from "../../../../Common/Input/SearchInput";
import Loader from "../../../../Common/Loader/Loader";
// import ModalComponent, { Modal } from "../../Common/Modal/Modal";
import ModalComponent from "../../../../Common/Modal/Modal";
import { SideBar } from "../SideBar";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { ProfileCreators } from "../../Dashboard/Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { styled } from "styled-components";
import { ErrorP } from "../../../../Common/Modal/Modal";
import Switch from "react-custom-checkbox/switch";

const Setting = () => {
    const naviagate = useNavigate();
    const dispatch = useDispatch();
    const userData = getUserDataFromLocal();
    const [loading, setLoading] = useState(false);
    const [input, setInput] = useState([]);
    // const [errorList, seterrorList] = useState([])

    const [switchOneCheck, setSwitchOneCheck] = useState(false);

    const checkedTrackStyle = {
        opacity: 1,
        transition: 'all 0.25s ease-in-out',
    }

    const checkedIndicatorStyle = {
        background: '#44aa44',
        transform: 'translateX(30px)',
    }

    const checkedIconStyle = {
        opacity: 1,
        transition: 'all 0.25s ease-in-out',
    }

    const indicatorStyle = {
        alignItems: 'center',
        background: '#f34334',
        borderRadius: 24,
        bottom: 2,
        display: 'flex',
        height: 24,
        justifyContent: 'center',
        left: 2,
        outline: 'solid 2px transparent',
        position: 'absolute',
        transition: '0.25s',
        width: 24,
    }

    const trackStyle = {
        background: '#e5efe9',
        border: '1px solid #e6e6e6',
        borderRadius: 15,
        cursor: 'pointer',
        display: 'flex',
        height: 28,
        marginRight: 12,
        position: 'relative',
        width: 60,
    }

    const { profileData } = useSelector((state) => state.profileData);

    // const handleOnChange = (e) => {
    //     const { name, value } = e.target;
    //     setInputData({ ...inputData, [name]: value });
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     handleChangePassword()
    // }
// console.log('useSelector((state) => state.profileData)', useSelector((state) => state.profileData.profileData))
    // useEffect(() => {
    //     dispatch(ProfileCreators.getProfile());
    //   }, []);
    // console.log('profile', profileData[0]?.push_notification_token)
    const updateNotificationStatus = async (status) => {
        try {
            // setLoading(true)
            const res = await WebService.post(
                apiEndPoints.deviceToken,
                JSON.stringify({status:status, device_token: localStorage.getItem('device_token'), android_device_token: localStorage.getItem('android_device_token')}),
                {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },
                }
            );

            // console.log('res', JSON.stringify({status:status}));
            if (res.status === 200) {
                toast.success(res.data.msg, {
                    ToastSuccess,
                    position: "top-center",
                    duration: 6000
                });
                naviagate('/user/setting')
                setInput({web:res.data.data.web, push_notification_token: res.data.data.push_notification_token, device:res.data.data.device, device_notification_token: res.data.data.device_notification_token})
                // setLoading(false)
                // seterrorList([]);
                // setTimeout(() => {
                //     naviagate("/login");
                // }, 3000)
            } else if (res.response.status === 422) {
                // console.log(res.response.data.data)
                toast.error(res.response.data.msg, {
                    ToastWarning,
                    position: "top-center",
                });
                // setLoading(false)
                // seterrorList(res.response.data.data);
            } else if (res.response.status === 404) {
                toast.error(res.response.data.msg, {
                    ToastWarning,
                    position: "top-center",
                });
                naviagate('/user/setting')
                // setLoading(false)
            } else {
                toast.error("something went wrong.", {
                    ToastWarning,
                    position: "top-right",
                });
                // setLoading(false)
            }
        }
        catch (e) {
            const { error } = sagaCatchBlockHandling(e);
            // setLoading(false)
            toast.error("Something Went Wrong!", {
                position: "top-right",
            });
        }
    }
    return (
        <>
            <Toaster />

            <div className="main-wrapper">
                <div className="breadcrumb-bar">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-12">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li
                                            className="breadcrumb-item active"
                                            aria-current="page"
                                        >
                                            <Link to="/user/setting">Setting</Link>
                                        </li>
                                    </ol>
                                </nav>
                                <h2 className="breadcrumb-title">Setting</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <SideBar profileData={profileData} />
                            <div className="col-md-7 col-lg-8 col-xl-9">
                                <div className="row">
                                    <div className="card new-comment clearfix">
                                        <div className="card-header">
                                            <h4 className="card-title">Setting</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="row justify-content-center">
                                                <div className="col-12 mx-auto">
                                                    {/* <h2 className="h3 mb-4 page-title">Settings</h2> */}
                                                    <div className="my-4">
                                                        <h5 className="mb-0 mt-5">Notifications Settings</h5>
                                                        <p className="text-muted">Select notification you want to receive</p>
                                                        <hr className="my-4" />
                                                        <strong className="mb-0">Security</strong>
                                                        <p>Control security alert you will be notified.</p>
                                                        <div className="list-group mb-5 shadow">
                                                            <div className="list-group-item">
                                                                <div className="row align-items-center">
                                                                    <div className="col">
                                                                        <strong className="mb-0">Web push Notification</strong>
                                                                        <p className="text-muted mb-0">A web push notification is a short message sent from a website to a user's web browser to updates or alerts.</p>
                                                                        <p className="mb-0" style={{color:'#ff9c27'}}>Note: Web notification you will have to update status from web browser.</p>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="custom-control custom-switch">
                                                                            <Switch
                                                                                checked={ profileData[0]?.web ? true : false}
                                                                                // onChange={setSwitchOneCheck}
                                                                                indicatorStyle={indicatorStyle}
                                                                                // trackStyle={trackStyle}
                                                                                // checkedIconStyle={checkedIconStyle}
                                                                                checkedIndicatorStyle={checkedIndicatorStyle}
                                                                                checkedTrackStyle={checkedTrackStyle}
                                                                                onChange={() => updateNotificationStatus('web')}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="list-group-item">
                                                                <div className="row align-items-center">
                                                                    <div className="col">
                                                                        <strong className="mb-0">Mobile Push Notification</strong>
                                                                        <p className="text-muted mb-0">A mobile push notification is a short message or alert sent from a mobile app to a user's device to provide updates or information.</p>
                                                                        <p className="mb-0" style={{color:'#ff9c27'}}>Note: Mobile notification you will have to update status from android app.</p>
                                                                    </div>
                                                                    <div className="col-auto">
                                                                        <div className="custom-control custom-switch">
                                                                            <Switch
                                                                                checked={ profileData[0]?.device ? true : false}
                                                                                // onChange={setSwitchOneCheck}
                                                                                indicatorStyle={indicatorStyle}
                                                                                // trackStyle={trackStyle}
                                                                                // checkedIconStyle={checkedIconStyle}
                                                                                checkedIndicatorStyle={checkedIndicatorStyle}
                                                                                checkedTrackStyle={checkedTrackStyle}
                                                                                onChange={() => updateNotificationStatus('device')}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-4" />
                                                        {/* <strong className="mb-0">System</strong>
                                                            <p>Please enable system alert you will get.</p>
                                                            <div className="list-group mb-5 shadow">
                                                                <div className="list-group-item">
                                                                    <div className="row align-items-center">
                                                                        <div className="col">
                                                                            <strong className="mb-0">Notify me about new features and updates</strong>
                                                                            <p className="text-muted mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="custom-control custom-switch">
                                                                                <input type="checkbox" className="custom-control-input" id="alert3" checked="" />
                                                                                <span className="custom-control-label"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="list-group-item">
                                                                    <div className="row align-items-center">
                                                                        <div className="col">
                                                                            <strong className="mb-0">Notify me by email for latest news</strong>
                                                                            <p className="text-muted mb-0">Nulla et tincidunt sapien. Sed eleifend volutpat elementum.</p>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="custom-control custom-switch">
                                                                                <input type="checkbox" className="custom-control-input" id="alert4" checked="" />
                                                                                <span className="custom-control-label"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="list-group-item">
                                                                    <div className="row align-items-center">
                                                                        <div className="col">
                                                                            <strong className="mb-0">Notify me about tips on using account</strong>
                                                                            <p className="text-muted mb-0">Donec in quam sed urna bibendum tincidunt quis mollis mauris.</p>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <div className="custom-control custom-switch">
                                                                                <input type="checkbox" className="custom-control-input" id="alert5" />
                                                                                <span className="custom-control-label"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Setting;

export const Required = styled.span`
  color: red;
`;
