import React, { useEffect, useState } from "react";
import { FlexContainer } from "../../../../globalStyles";
import SearchableDropdown from "../../../../Common/Input/SearchInput";
import SearchableDropdownNation from "../../../../Common/SearchDropDown/SearchableDropdownNation";
import { ProfileCreators } from "../../Dashboard/store";
import { useDispatch, useSelector } from "react-redux";
import { negate, result, update } from "lodash";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import axios from "axios";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { publishedCreators } from "../../../Published/store";
import { Loader } from "../../../../Common/Input/Loader/Loader";
import { useNavigate, Link } from "react-router-dom";
import Button from 'react-bootstrap-button-loader';
import Modal from "react-bootstrap/Modal";
import { styled } from "styled-components";
import { SideBar } from "../SideBar";
import { ReactComponent as Xmark } from "../../../../assets/icons/Xmark.svg";
import { Required } from "../Profile/ProfileSettings";
import { ErrorP } from "../../../../Common/Modal/Modal";
import { getBase64 } from "../../../../shared/utility/getBase64";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { updateProfileCreators } from "../Profile/store";
import { NationCreators } from "../../Landing/Nation/store";
import Select from 'react-select';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import {
  GetCountries
} from "react-country-state-city";
import AppHelmet from "../../Helmet/Helmet";

export const JobForm = ({ setIsModalVisible }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [categoryId, setcategoryId] = useState(null);
  const [subcategorysearch, setSubCategorysearch] = useState("");
  const [subcategoryId, setsubcategoryId] = useState("");
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const userData = getUserDataFromLocal();
  const [formload, setformload] = useState(false)
  const { categoryLoading, catagories } = useSelector(
    (state) => state.profileData
  );
  const { publishedList, loading } = useSelector((state) => state.publishData);
  const { profileData } = useSelector((state) => state.profileData);
  const useAuth = () => {
    const user = localStorage.getItem("user");
    if (user) {
      return true;
    } else {
      // navigate('/')
      return false;
    }
  };
  const auth = useAuth();
  // if(profileData[0]?.type === 'Customer')
  // {
  //   navigate('/')
  // }



  const [input, setInput] = useState({
    category_id: "",
    sub_category_id: "",
    title: "",
    description: "",
    end_date: "",
    address: "",
    image: [],
    country: "",
    state: "",
    city: ""
  });
  const [errorList, seterrorList] = useState([]);
  const [stateList, setStateList] = useState([])
  const [cityList, setCityList] = useState([])

  const setCurrentLocation = () => {
    if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          // console.log('position', position)
            // this.setState({
            //     center: [position.coords.latitude, position.coords.longitude],
            //     lat: position.coords.latitude,
            //     lng: position.coords.longitude
            // });
        });
    }
}


  const { nationList } = useSelector((state) => state.nationData);
  useEffect(() => {
    dispatch(NationCreators.getNationList());
    setCurrentLocation()

  }, []);


  const countryListArray = nationList?.map((country, index) => {
    return (
      { value: `${country?.id}`, label: `${country?.emoji} ${country?.name}` }
    )
  })
  const statesListArray = stateList.map((state, index) => {
    return (
      { value: `${state?.id}`, label: `${state?.name}` }
    )
  })
  const cityListArray = cityList.map((city, index) => {
    return (
      { value: `${city?.id}`, label: `${city?.name}` }
    )
  })


  const handelJobSubmit = async () => {
    try {
      setformload(true)
      dispatch(publishedCreators.getCreatedJobStart());
      const res = await WebService.post(
        apiEndPoints.userJobCreate,
        JSON.stringify(input),
        {
          headers: { Authorization: "Bearer " + userData.token },
        }
      );
      if (res.status === 200) {
        toast.success(res.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        dispatch(
          publishedCreators.getCreatedJobSuccess({ data: res.data.data })
        );
        setformload(false)
        navigate("/user/jobs/myjobs");
      } else if (res.response.status === 422) {
        toast.error(res.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        seterrorList(res.response.data.data);
        setformload(false)
      } else if (res.response.status === 404) {
        toast.error(res.response.data.msg, {
          ToastWarning,
          position: "top-right",
        });
        setformload(false)
      } else {
        toast.error("something went wrong.", {
          ToastWarning,
          position: "top-right",
        });
        setformload(false)
      }
    } catch (err) {
      // console.log(err, "err");
      dispatch(publishedCreators.getCreatedJobFailure());
      toast?.error(err, { position: "top-right" });
      setformload(false)
    }
    // } else {
    //   setIsEmpty(true);
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  function handleImageChange(e) {
    const { name, value } = e.target;
    getBase64(e.target.files[0]).then((result) => {
      setInput({
        ...input, [name]: [...input[name], result],
        // ...input, [name]: result,
      });
    });

    e.currentTarget.value = null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handelJobSubmit();
  };

  useEffect(() => {
    dispatch(ProfileCreators.getCategories());
  }, []);

  useEffect(() => {
    if (categoryId) setInput({ ...input, category_id: categoryId });
    if (subcategoryId) setInput({ ...input, sub_category_id: subcategoryId });
  }, [categoryId, subcategoryId]);


  // const handleClose = () => {
  //   setIsModalVisible(null);
  //   setInput({
  //     category_id: "",
  //     title: "",
  //     description: "",
  //     end_date: "",
  //     address: "",
  //     image: [],
  //   });
  // };

  const removeImage = (item, index) => {
    // console.log(item, "item", index);
    let newImages = input?.image.filter((ele, i) => index !== i);
    setInput({ ...input, image: newImages });
  };

  // console.log(input, 'input')

  return (
    <>
      <Toaster />
      <AppHelmet title="Post a Job on Serviceora and Get the Best Service Quotations" description="Add your job post on Serviceora to receive competitive quotations from top service providers. Compare bids, choose the best offer, and get your job done quickly and efficiently." keywords="post a job Serviceora, get service quotations, best job quotes, compare bids, service provider quotes, add job post, job service bids, customer job posting, Serviceora job quotes" />
      <div className="main-wrapper">
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <Link to="/jobs">Jobs</Link>
                    </li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Add Job</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row ">
              <SideBar profileData={profileData} />
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row card">
                  <div className="card-header">
                    <h4 className="card-title">Add Job</h4>
                  </div>
                  <div className="card-body">
                    <div className="mentor-widget">
                      <form noValidate onSubmit={handleSubmit}>
                        <div className="row form-row">
                          <div className="">
                            <div className="form-group">
                              <div className="change-avatar">
                                <div className="upload-img"></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label>Category</label> <Required>*</Required>
                              <SearchableDropdown
                                label="name"
                                id="id"
                                options={catagories}
                                selectedVal={search}
                                handleChange={(val) => setSearch(val)}
                                setcategoryId={setcategoryId}
                                setSubCategoryList={setSubCategoryList}
                                aria-describedby="validationTooltipUsernamePrepend"
                                name="category"
                                catagories={catagories}
                                required
                              />
                              <ErrorP>{errorList.category_id}</ErrorP>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 ">
                            <div className="form-group">
                              <label>Sub Category</label> <Required>*</Required>
                              <SearchableDropdown
                                label="name"
                                id="id"
                                options={subCategoryList}
                                selectedVal={subcategorysearch}
                                handleChange={(val) => setSubCategorysearch(val)}
                                setsubcategoryId={setsubcategoryId}
                                subcategoryId={subcategoryId}
                                aria-describedby="validationTooltipUsernamePrepend"
                                name="subcategoryId"
                                required
                              />
                              <ErrorP>{errorList.sub_category_id}</ErrorP>
                            </div>
                          </div>
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <label>
                                Title <Required>*</Required>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Title"
                                value={input.title}
                                onChange={handleChange}
                                name="title"
                              />
                              <ErrorP>{errorList.title}</ErrorP>
                              <div className="invalid-tooltip">
                                Please choose a username.
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label>Country</label> <Required>*</Required>
                              <Select className="capitalize" options={countryListArray} onChange={(e) => {
                                setInput({ ...input, 'country': e?.value })
                                let a = nationList?.filter((item, index) => item?.id == e?.value)
                                // console.log('a', a)
                                setStateList(a[0]?.states)

                              }}
                                placeholder="Select Country" />
                              <ErrorP>{errorList.country}</ErrorP>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label>State</label> <Required>*</Required>
                              <Select className="capitalize" name="state" options={statesListArray} onChange={(e) => {
                                setInput({ ...input, 'state': e?.value })
                                let a = stateList.filter((item, index) => item.id == e?.value)
                                setCityList(a[0]?.cities)


                              }}
                                placeholder="Select State" />
                              <ErrorP>{errorList.state}</ErrorP>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label>City</label> <Required>*</Required>
                              <Select className="capitalize" name="state" options={cityListArray} onChange={(e) => {
                                setInput({ ...input, 'city': e?.value })
                                // let a = statesListArray.filter((item, index) => item.slug === e?.value)
                                // setCityList(a[0]?.cities)


                              }}
                                placeholder="Select City" />
                              <ErrorP>{errorList.city}</ErrorP>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group datepicker">
                              <label>
                               Bid Till Date <Required>*</Required>
                              </label>
                              <div>
                                <input
                                  type="date"
                                  className="form-control"
                                  placeholder="Select date"
                                  value={update.end_date}
                                  onChange={handleChange}
                                  name="end_date"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <label>
                                Address <Required>*</Required>
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Your Address "
                                className="form-control"
                                value={input.address}
                                onChange={handleChange}
                                name="address"
                              />
                              <ErrorP>{errorList.address}</ErrorP>
                            </div>
                          </div>
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <label htmlFor="description">
                                Description <small className="text-secondary"><b>(Optional)</b></small>
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                rows="5"
                                name="description"
                                value={input.description}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-10">
                            <div className="form-group">
                              <label
                                htmlFor="formFileLg"
                                className="form-label"
                              >
                                Upload Image <small className="text-secondary"><b>(Optional)</b></small>
                              </label>
                              <input
                                className="form-control form-control-lg"
                                id="formFileLg"
                                type="file"
                                onChange={handleImageChange}
                                name="image"
                              />
                            </div>
                          </div>

                          <FlexContainer className=" shadow-lg bg-white rounded mb-2 flex-wrap">
                            {input.image.map((ele, index) => (
                              <div style={{ position: "relative" }}>
                                <XmarkSpan
                                  className="crossIcon"
                                  onClick={() => removeImage(ele, index)}
                                >
                                  <Xmark width="25px" height="25px" />
                                </XmarkSpan>
                                <div className="bg-secondary bg-gradient rounded p-1 m-2">
                                  {/* <i className="fa-solid fa-xmark"></i> */}
                                  {/* <img src={Xmark} /> */}
                                  <img src={ele} width="100px" height="100px" />
                                </div>
                              </div>
                            ))}
                          </FlexContainer>
                        </div>

                        <div className="submit-section">
                          <Button className="btn btn-primary submit-btn" type="submit" loading={formload ? true : false}>Post Your Requirements!</Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const XmarkSpan = styled.span`
  &.crossIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 102px;
    ${"" /* top: -5px; */}
    transition: all ease 0.5s;
    cursor: pointer;

    :hover {
      background: red;
    }
  }
`;
