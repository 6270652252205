import { createReducer } from "reduxsauce";
import { NationalTypes } from "./action";


const INITIAL_STATE = {
    loading: false,
    nationalList: [],
    error: ''
}


const getNationalListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true
    }
}

const getNationalListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        loading: false,
        nationalList: data
    }
}

const getNationalListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        loading: false
     }
}


const HANDLERS = {
    [NationalTypes.GET_NATIONAL_LIST_START]: getNationalListStart,
    [NationalTypes.GET_NATIONAL_LIST_SUCCESS]: getNationalListSuccess,
    [NationalTypes.GET_NATIONAL_LIST_FAILURE]: getNationalListFailure
}

export const nationalReducer = createReducer(INITIAL_STATE, HANDLERS)