import React, { useEffect, useStaten, Component } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { SubcategoryCreators } from "../Subcategory/store";
import { TestimonialsCreators } from "./store";
import { rootConfig } from "../../../../services/axios/endPoints";
import Loader from "../../../../Common/Loader/Loader";


// export default class Testimonials extends Component {
export const Testimonial = () => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subcategoryList } = useSelector((state) => state.subcategoryData);
  const { testimonialsList, testimonialloading, aboutloading, aboutList } = useSelector((state) => state.testimonialsData)
  useEffect(() => {
    dispatch(TestimonialsCreators.getTestimonialsList());
    dispatch(TestimonialsCreators.getAboutList())
  }, []);

  // console.log('about', aboutList)

  return (
    <Carousel
      showArrows={false}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={true}
      interval={6100}
    >
      {testimonialloading ? <Loader width="1rem" /> : testimonialsList.map((testimonial, index) => {
          return (
            <div key={index} className="card">
              <div className="card-body py-4 mt-2">
                <div className="d-flex justify-content-center mb-4">
                  <img src={rootConfig?.base_url+ testimonial?.image}
                    className="rounded-circle shadow-1-strong" width="100" height="100" />
                </div>
                <h5 className="font-weight-bold capitalize">{testimonial?.name}</h5>
                <h6 className="font-weight-bold my-3 capitalize">{testimonial?.designation}</h6>
                <ul className="list-unstyled d-flex justify-content-center">
                </ul>
                <p className="mb-2">
                  <i className="fas fa-quote-left pe-2"></i>{testimonial?.message}
                </p>
              </div>
            </div>
          )
        })
      }

    </Carousel>
  );
}