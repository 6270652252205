import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { MyJobCreators } from "./store";
import { FlexContainer } from "../../../../globalStyles";
import SearchableDropdown from "../../../../Common/Input/SearchInput";
import Loader from "../../../../Common/Loader/Loader";
// import ModalComponent, { Modal } from "../../Common/Modal/Modal";
import ModalComponent from "../../../../Common/Modal/Modal";
import { SideBar } from "../SideBar";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { ProfileCreators } from "../../Dashboard/Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import './style.css'
import Switch from "react-custom-checkbox/switch";

import moment from "moment";
export const MyJob = () => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { loading, myJobList } = useSelector((state) => state.myJobData);

  useEffect(() => {
    dispatch(MyJobCreators.getMyJobList());
  }, []);
  const { profileData } = useSelector((state) => state.profileData);
  // if(profileData[0]?.type === 'Customer')
  // {
  //   navigate('/')
  // }

  const [switchOneCheck, setSwitchOneCheck] = useState(false);

  const checkedTrackStyle = {
    opacity: 1,
    transition: 'all 0.25s ease-in-out',
  }

  const checkedIndicatorStyle = {
    background: '#44aa44',
    transform: 'translateX(30px)',
  }

  const checkedIconStyle = {
    opacity: 1,
    transition: 'all 0.25s ease-in-out',
  }

  const indicatorStyle = {
    alignItems: 'center',
    background: '#f34334',
    borderRadius: 24,
    bottom: 2,
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    left: 2,
    outline: 'solid 2px transparent',
    position: 'absolute',
    transition: '0.25s',
    width: 24,
  }

  const trackStyle = {
    background: '#e5efe9',
    border: '1px solid #e6e6e6',
    borderRadius: 15,
    cursor: 'pointer',
    display: 'flex',
    height: 28,
    marginRight: 12,
    position: 'relative',
    width: 60,
  }

  const updateStatus = async (ele) => {

    try {
      const response = await WebService.post(
        apiEndPoints.publishing,
        JSON.stringify({ slug: ele }),
        {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          ToastSuccess,
          position: "top-center",
        });
        // dispatch(MyJobCreators.getMyJobList());
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          ToastWarning,
          position: "top-center",
        });
        dispatch(MyJobCreators.getMyJobList());
      }
      else {
        toast.error('something went wrong!', {
          ToastWarning,
          position: "top-center",
        });
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Toaster />
      {/* <ModalComponent /> */}
      {loading ? (
        <Loader containerHeight={70} width="4rem" />
      ) : (
        <div className="main-wrapper">
          <div className="breadcrumb-bar">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-md-12 col-12">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <Link to="/user/jobs/myjobs">My Jobs</Link>
                      </li>
                    </ol>
                  </nav>
                  <h2 className="breadcrumb-title">My Jobs</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <SideBar profileData={profileData} />
                <div className="col-md-7 col-lg-8 col-xl-9">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="mb-4">Jobs</h4>
                      <div className="card card-table">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Sr. no</th>
                                  <th>Title</th>
                                  <th style={{ width: '300px' }}>Location</th>
                                  <th>Images</th>
                                  <th>Description</th>
                                  <th>CREATED DATE</th>
                                  <th>Status</th>
                                  <th>Publish</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {myJobList?.length < 1 ? <>
                                  <tr>
                                    <td>No job Found!</td>
                                  </tr>
                                </> : myJobList?.map(
                                  (myJob, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        {
                                          <td>
                                            <h2 className="table-avatar">
                                              <Link
                                                to="#"
                                                className="capitalize"
                                              >
                                                {myJob?.title} {myJob?.job_country?.emoji}
                                                <span>
                                                  <span
                                                    style={{
                                                      textTransform:
                                                        "lowercase",
                                                    }}
                                                  >

                                                  </span>
                                                </span>

                                                <ul className="tags" style={{ fontSize: '10px !important' }}>
                                                  <li className="capitalize"><Link to="#" className="tag"><img src={rootConfig.base_url + myJob?.job_sub_category?.image} height="20px" width="20px" /> {myJob?.job_sub_category?.name}</Link></li>
                                                  <li className="capitalize"><Link to="#" className="tag" ><img src={rootConfig.base_url + myJob?.job_category?.image} height="20px" width="20px" /> {myJob?.job_category?.name}</Link></li>
                                                </ul>
                                              </Link>
                                            </h2>
                                          </td>
                                        }
                                        <td>
                                          <span style={{ textWrap: "balance" }}> {myJob?.address}, {myJob?.job_city?.name}, {myJob?.job_state?.name}, {myJob?.job_city?.description}, {myJob?.job_country?.name}</span>
                                        </td>
                                        <td>
                                          {
                                            myJob?.job_image?.map((imgitem, index) => {
                                              return (
                                                <div className="avatar" key={index}>
                                                  <img className="avatar-img rounded-circle border border-white" alt={imgitem?.image} src={rootConfig.base_url + imgitem?.image} />
                                                </div>
                                              )
                                            })
                                          }
                                        </td>
                                        <td>
                                          <span style={{ textWrap: "balance" }}>{myJob?.description?.substring(0, 130)} ...</span>
                                        </td>
                                        <td>
                                          {moment(
                                            myJob?.created_at
                                          ).format(" Do MMMM, YYYY")}
                                        </td>
                                        <td className="text-center">
                                          {myJob?.status === 'Approved' ?
                                            <span className="badge badge-info">{myJob?.status}</span>
                                            : myJob?.status === 'Active' ?
                                              <span className="badge rounded-pill bg-secondary">{myJob?.status}</span>
                                              : myJob?.status === 'Processing' ?
                                                <span className="badge rounded-pill badge-primary">{myJob?.status}</span>
                                                : myJob?.status === 'Completed' ?
                                                  <span className="badge rounded-pill badge-success">{myJob?.status}</span>
                                                  : myJob?.status === 'Cancel' ?
                                                    <span className="badge rounded-pill badge-warning">{myJob?.status}</span>
                                                    : myJob?.status === 'Deleted' ?
                                                      <span className="badge rounded-pill badge-danger">{myJob?.status}</span>
                                                      : ''}
                                        </td>
                                        <td className="text-center">
                                          <Switch
                                            checked={myJob?.publish ? true : false}
                                            // onChange={setSwitchOneCheck}
                                            indicatorStyle={indicatorStyle}
                                            // trackStyle={trackStyle}
                                            // checkedIconStyle={checkedIconStyle}
                                            checkedIndicatorStyle={checkedIndicatorStyle}
                                            checkedTrackStyle={checkedTrackStyle}
                                            onChange={() => updateStatus(myJob?.slug)}
                                          />
                                        </td>
                                        <td>
                                              {/* <Link style={{textDecoration:'underline'}} to={`${`/user/job/update/${myJob?.job_code}`}`}>Update Job</Link> */}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
