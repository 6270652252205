import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import "./assets/css/style.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store";
import { TourProvider } from "@reactour/tour";
import steps from "./step";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Provider store={store}>
    <TourProvider steps={steps}>
      <App />
      </TourProvider>
    </Provider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
