import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../Common/Loader/Loader";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess, ToastWarning } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { ErrorP } from "../../../../Common/Modal/Modal";
import serviceorasvg from "../../../../assets/img/logo/serviceora.png";
import { SocialCreators } from "../Social/store";
import Button from 'react-bootstrap-button-loader';

const ResetPassword = () => {
  const naviagate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useParams();
  const [emailParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [tokenReset, setTokenReset] = useState("");
  const [loading, setLoading] = useState(false)
  const [errorList, seterrorList] = useState([])


  const { socialList, socialloading } = useSelector((state) => state.socialData);

  useEffect(() => {
    dispatch(SocialCreators.getSocialList());
  }, []);


  useEffect(() => {
    console.log('token', token)
    setTokenReset(token)
    setEmail(emailParams.get('email'))

  }, [])
  const [resetInput, setResetInput] = useState({
    token: token,
    email: emailParams.get('email'),
    password: "",
    password_confirmation: ""
  });


  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setResetInput({ ...resetInput, [name]: value, token: resetInput.token, email: resetInput.email });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleResetPassword()
  }

  const handleResetPassword = async () => {
    try {
      // console.log('input', resetInput)
      setLoading(true)
      const res = await WebService.post(
        apiEndPoints.ResetPassword,
        JSON.stringify(resetInput)
      );
      // console.log('res', res);
      if (res.status === 200) {
        toast.success(res.data.msg, {
          ToastSuccess,
          position: "top-center",
          duration: 6000
        });
        setLoading(false)
        seterrorList([]);
        setTimeout(() => {
          naviagate("/login");
        }, 3000)
      } else if (res.response.status === 422) {
        toast.error(res.response.data.msg, {
          position: "top-center",
        });
        setLoading(false)
        seterrorList(res.response.data.data);
      } else if (res.response.status === 404) {
        toast.error(res.response.data.msg, {
          position: "top-center",
        });
        setLoading(false)
      } else {
        toast.error("something went wrong.", {
          position: "top-right",
        });
        setLoading(false)
      }
    }
    catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      setLoading(false)
    }
  }



  return (
    <>
      <Toaster />
      <div className="bg-pattern-style"  style={{background: 'url('+rootConfig?.base_url+socialList?.resetImg+') no-repeat'}}>
        <div className="content">
          <div className="account-content">
            <div className="account-box">
              <div className="login-right">
                <div className="login-header">

                {/* <Link to='/'><img src={serviceorasvg} className="img-fluid" alt="Serviceora" style={{ maxHeight: "30px" }} /></Link> */}
                  <h3>
                    Reset Password <span>Serviceora</span>
                  </h3>
                  <p className="text-muted">Enter your credential to set a password </p>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="form-control-label">Email Address</label>
                    <div className="pass-group">
                      <input
                        type="text"
                        name="email"
                        value={email}
                        className="form-control pass-input"
                        placeholder="Email Address"
                        autoComplete="off"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">Password</label>
                    <div className="pass-group">
                      <input
                        type="password"
                        name="password"
                        value={resetInput.password}
                        className="form-control pass-input"
                        onChange={handleOnChange}
                        placeholder="Password"
                        autoComplete="off"
                      />
                      <span className="fas fa-eye toggle-password"></span>
                    </div>
                    <ErrorP>{errorList.password}</ErrorP>
                  </div>
                  <div className="form-group">
                    <label className="form-control-label">Password Confirmation</label>
                    <div className="pass-group">
                      <input
                        type="password"
                        name="password_confirmation"
                        value={resetInput.password_confirmation}
                        className="form-control pass-input"
                        onChange={handleOnChange}
                        placeholder="Password Confirmation"
                        autoComplete="off"
                      />
                      <span className="fas fa-eye toggle-password"></span>
                    </div>
                    <ErrorP>{errorList.password_confirmation}</ErrorP>
                  </div>
                  <div className="text-end">
                    <Link className="forgot-link" to="/login">Remember your password?</Link>
                  </div>
                  <Button className="btn btn-primary login-btn" type="submit" loading={loading ? true : false}>Reset Password!</Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword