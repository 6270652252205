import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  updateProfile: ["payload"],
  updateProfileStart: ["payload"],
  updateProfileSuccess: ["payload"],
  updateProfileFailure: ["payload"],
  getNationData: ["payload"],
  getNationDataStart: ["payload"],
  getNationDataSuccess: ["payload"],
  getNationDataFailure: ["payload"],
});

export { Types, Creators };
