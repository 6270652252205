import { createReducer } from "reduxsauce";
import { JobInvoiceTypes } from "./action";


const INITIAL_STATE = {
    loading: false,
    jobInvoiceList: [],
    error: ''
}


const getJobInvoiceListStart = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        loading: true
    }
}

const getJobInvoiceListSuccess = (state = INITIAL_STATE, action) => {
    const { data, message } = action.payload
    return {
        ...state,
        loading: false,
        jobInvoiceList: data
    }
}

const getJobInvoiceListFailure = (state = INITIAL_STATE, action) => {
    return { 
        ...state,
        loading: false
     }
}


const HANDLERS = {
    [JobInvoiceTypes.GET_JOB_INVOICE_LIST_START]: getJobInvoiceListStart,
    [JobInvoiceTypes.GET_JOB_INVOICE_LIST_SUCCESS]: getJobInvoiceListSuccess,
    [JobInvoiceTypes.GET_JOB_INVOICE_LIST_FAILURE]: getJobInvoiceListFailure
}

export const jobInvoiceReducer = createReducer(INITIAL_STATE, HANDLERS)